import React from 'react';
import BannerContent from 'src/shared/components/BannerContent/BannerContent';
import { ReactComponent as ThemesIcon } from 'src/static/icons/paint-brush.svg';

export default function ThemesBanner() {
  return (
    <BannerContent icon={<ThemesIcon></ThemesIcon>}>
      Create a branded analytics experience for your application with custom themes and white labeling options.
    </BannerContent>
  );
}
