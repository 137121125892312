import React, { useEffect, useRef, useState } from 'react';
import styles from './InfoTooltip.module.scss';

export default function InfoTooltip({ children }) {
  const [tooltipCoordinates, settooltipCoordinates] = useState({ x: 0, y: 0 });
  const rootRef = useRef(null);
  const tooltipRef = useRef(null);

  const resetTooltipPosition = () => {
    let { x, y } = rootRef.current.getBoundingClientRect();
    const { width: tooltipWidth } = tooltipRef.current.getBoundingClientRect();
    if (tooltipWidth + x > window.innerWidth + 10) {
      x -= tooltipWidth;
    }
    settooltipCoordinates({ x, y });
  };

  useEffect(() => {
    resetTooltipPosition();
  }, []);

  return (
    <div ref={rootRef} className={styles.root} onMouseOver={resetTooltipPosition}>
      ⓘ
      <div
        ref={tooltipRef}
        className={styles.tooltiptext}
        style={{ top: tooltipCoordinates.y + 10, left: tooltipCoordinates.x }}
      >
        {children}
      </div>
    </div>
  );
}
