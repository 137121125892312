export const PUB_SUB_COMMON_CODE = `
        // START: PUB/SUB COMMON SECTION
        // An embedding application should listen for the 'composer-dashboard-ready' event 
        // BEFORE attempting to publish or subscribe to cross-visual filters. The code below
        // should be specified at the beginning of both publish and subscribe Javascript code.
        const DASHBOARD_READY_EVENT = 'composer-dashboard-ready';
        const pubSubReady = new Promise((resolve) => {
            const resolvePubSubReady = (message, publisherId) => {
                resolve();
                document.removeEventListener(DASHBOARD_READY_EVENT, resolvePubSubReady);
            }
            document.addEventListener(DASHBOARD_READY_EVENT, resolvePubSubReady);
        });
        /* END: Pub/Sub common section */
`;

export const SUBSCRIBE_JS_CODE = `

        // START: Subscribe to Cross-Visual Filters
        // The following example shows how to subscribe to a link name
        // defined in the dashboard and receive the latest value published.
        // The link name in this example is "Country". When a user selects
        // one or more countries from the list widget, a message publishes the values.
        const subscriptionHandler = (message, publisherId) => {
            const value = message === null ? null : message.ranges[0].value;
            const selectedLabel = document.createElement('label');
            selectedLabel.innerText = value === null ? 'Selected Country(s): NONE' : 'Selected Country(s):';
            const countryDiv = document.getElementById('country-container');
            countryDiv.innerText = '';
            countryDiv.appendChild(selectedLabel);
            if (value) {
                value.forEach((country) => {
                  const img = document.createElement('img');
                  const imgSrc = \`images/flags/\${country}.svg\`;
                  let errorHandled = false; 
                  img.onerror = () => {
                    if (!errorHandled) {
                      img.src = 'images/flags/default.svg';
                      errorHandled = true;
                    }
                  };
                  img.src = imgSrc;
                  const countryName = document.createElement('h2');
                  countryName.innerText = \`\${country}\`;
                  countryDiv.appendChild(img);
                  countryDiv.appendChild(countryName);
                });
            }              
        }

        let unsubscribe;
        pubSubReady.then(() => embedManagerPromise).then((embedManager) => {
            unsubscribe = embedManager.subscribe('Country', subscriptionHandler);
        });
        /* END: Subscribe to Cross-Visual Filters */`;

export const PUBLISH_JS_CODE = `

        /* START: Publish to Cross-Visual Filters */
            
        // The following code represents the From and To date pickers on the
        // embedding application's page that allow users to filter the data by
        // a date range.  The BETWEEN operator is used for the link name 
        // defined in the dashboard called 'LaunchedDate'. Note that the first part of 
        // this code converts the date-time data to the format expected by Composer.
        const dateFromInput = document.getElementById('date-from');
      	const dateToInput = document.getElementById('date-to');
      
        const formatDate = (date) => {
            const d = new Date(date);
			const year = \`\${d.getFullYear()}\`;
            let month = \`\${d.getMonth() + 1}\`;
			let day = \`\${d.getDate()}\`;

            if (month.length < 2) {
                month = \`0\${month}\`;
            }
            if (day.length < 2) {
                day = \`0\${day}\`;
            }

            return \`\${year}-\${month}-\${day}\`;
        }
        
        const getEndOfDayString = dateString => \`\${dateString} 23:59:59.999\`;
        const getStartOfDayString = dateString => \`\${dateString} 00:00:00.000\`;
      
      	const dateInputChangeHandler = (event) => {
          	const dateFromInputValue = dateFromInput.value;
          	const dateToInputValue = dateToInput.value;
          
            const dateFrom = dateFromInputValue ? new Date(dateFromInput.value) : null;
            const dateTo = dateToInputValue ? new Date(dateToInput.value) : null;
          
          	const dateFromString = dateFrom ? formatDate(dateFrom) : null;
          	const dateToString = dateTo ? formatDate(dateTo) : null;
          
          	const dateTimeFromString = dateFromString ? getEndOfDayString(dateFromString) : null;
            const dateTimeToString = dateToString ? getStartOfDayString(dateToString) : null;
          
          	let operation = 'BETWEEN';
			let value = [dateTimeFromString, dateTimeToString];
          
          	if (!dateTimeFromString) {
              operation = 'LT';
              value = dateTimeToString;
            }
          
          	if (!dateTimeToString) {
              operation = 'GT';
              value = dateTimeFromString;
            }
          
          	let publishObject = {
                type: 'selection',
                valueType: 'TIME',
                ranges: [
                    {
                        operation: operation, 
                        value: value
                    }
                ]
            }
            
          	if (!dateTimeFromString && !dateTimeToString) {
            	publishObject = null;
			}
            
            pubSubReady.then(() =>  embedManager.publish('LaunchedDate', publishObject));
        }
      
        dateFromInput.addEventListener('change', dateInputChangeHandler);
        dateToInput.addEventListener('change', dateInputChangeHandler);

        // This represents a drop-down on the embedding application's page that
		// lists a set of countries the user can filter by. After selecting a value,
		// the embedding application calls its 'publishValue' function, defined
		// below with the link name "Country" and the selected country value.
        // The "Country" link name is defined in the dashboard. To clear the value 
        // filter, make sure that you pass a null value as shown below
        const fieldSelect = document.getElementById('field-select');
        fieldSelect.addEventListener('change', (event) => {
            const value = event.target.value;
            const publishObject = value !== 'null' ? {
                type: 'selection',
                valueType: 'ATTRIBUTE',
                ranges: [
                    {
                        operation: 'IN', 
                        value: [value]
                    }
                ]
            } : null;
            pubSubReady.then(() =>  embedManager.publish('Country', publishObject));
        });
        /* END: Publish to Cross-Visual Filters */`;

export const PUBLISH_HTML_CODE = `

    <!-- START: Publish to Cross-Visual Filters -->
    <div class="publish-container">
        <label>Filter By Launched Date from:</label>
        <input type="date" id="date-from" placeholder="select from date">

        <label>To:</label>
        <input type="date" id="date-to" placeholder="select to date">

        <label>Filter by Country:</label>
        <select id="field-select">
            <option value="null">All Top 10 Countries</option>
            <option value="United States">United States</option>
            <option value="Canada">Canada</option>
            <option value="United Kingdom">United Kingdom</option>
            <option value="Germany">Germany</option>
            <option value="Italy">Italy</option>
            <option value="India">India</option>
            <option value="Australia">Australia</option>
            <option value="China">China</option>
            <option value="France">France</option>
            <option value="Mexico">Mexico</option>
        </select>
    </div>
    <!-- END: Publish to Cross-Visual Filters -->
    `;

export const SUBSCRIBE_HTML_CODE = `
    <!-- START: Subscribe to Cross-Visual Filters -->
    <div class="publish-container" id="country-container">
        <label>Selected Country(s): NONE</label>
    </div>
    <!-- END: Subscribe to Cross-Visual Filters -->
        `;

export const PUBLISH_STYLES = `
.publish-container {
    background: #1e2556;
    color:#ffffff;
    display: flex;
    align-items: center;
    gap: 4px;
    height: 26px;
    padding: 10px;
    font-family: 'Source Sans Pro';
}`;

export const SUBSCRIBE_STYLES = `
.publish-container {
    background: #1e2556;
    color:#ffffff;
    display: flex;
    align-items: center;
    gap: 4px;
    height: 50px;
    padding: 15px;
    font-family: 'Source Sans Pro';
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: clip;
}
img {
    clip-path: circle();
    height: 28px;
}
`;

export const PUB_SUB_TOGGLE_STYLE = `
#country-container {
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: clip;
    background-color: #46b359;
    height: 40px;
}
img {
    clip-path: circle();
    height: 28px;
}
`;
