import { DOCUMENTATION_ITEMS } from './documentationConstants';

export const EMBED_OPTIONS = {
  ViewType: {
    fieldKey: 'ViewType',
    ...DOCUMENTATION_ITEMS.ViewType,
    options: [
      { value: 'dashboard', label: 'Dashboard' },
      { value: 'report', label: 'Report' },
      { value: 'scorecard', label: 'Scorecard' },
    ],
  },
  Theme: {
    fieldKey: 'theme',
    ...DOCUMENTATION_ITEMS.Theme,
  },
  dashboard: {
    fieldKey: 'dashboardId',
    ...DOCUMENTATION_ITEMS.Dashboard,
  },
  report: {
    fieldKey: 'reportId',
    ...DOCUMENTATION_ITEMS.Report,
  },
  scorecard: {
    fieldKey: 'scorecardId',
    ...DOCUMENTATION_ITEMS.Scorecard,
  },
  MetricSet: {
    fieldKey: 'metricSetId',
    ...DOCUMENTATION_ITEMS.MetricSet,
  },
  toolbarHidden: {
    fieldKey: 'toolbarHidden',
    ...DOCUMENTATION_ITEMS.toolbarHidden,
  },
  toolbarAddMetricsSetHidden: {
    fieldKey: 'toolbarAddMetricsSetHidden',
    ...DOCUMENTATION_ITEMS.toolbarAddMetricsSetHidden,
  },
  toolbarAddDashboardHidden: {
    fieldKey: 'toolbarAddDashboardHidden',
    ...DOCUMENTATION_ITEMS.toolbarAddDashboardHidden,
  },
  toolbarAddReportHidden: {
    fieldKey: 'toolbarAddReportHidden',
    ...DOCUMENTATION_ITEMS.toolbarAddReportHidden,
  },
  dockablesHidden: {
    fieldKey: 'dockablesHidden',
    ...DOCUMENTATION_ITEMS.dockablesHidden,
  },
  openExistingHidden: {
    fieldKey: 'openExistingHidden',
    ...DOCUMENTATION_ITEMS.openExistingHidden,
  },
  themeComposer: {
    fieldKey: 'themeComposer',
    ...DOCUMENTATION_ITEMS.themes,
    options: [
      { value: '__platform__', label: 'Default' },
      { value: 'composer', label: 'Composer' },
      { value: 'dark', label: 'Dark' },
      { value: 'modern', label: 'Modern' },
    ],
  },
  inventoryItem: {
    fieldKey: 'inventoryItem',
    ...DOCUMENTATION_ITEMS.inventoryItem,
    options: [
      { value: 'dashboard', label: 'Dashboard' },
      { value: 'visual', label: 'Visual' },
      { value: 'source', label: 'Source' },
    ],
  },
  ip: {
    fieldKey: 'ip',
    ...DOCUMENTATION_ITEMS.ip,
    options: [
      { value: 'readonly', label: 'Read only' },
      { value: 'interactive', label: 'Interactive' },
    ],
  },
  showHeader: {
    fieldKey: 'showHeader',
    ...DOCUMENTATION_ITEMS.dashboardShowHeader,
  },
  visualShowHeader: {
    fieldKey: 'showHeader',
    ...DOCUMENTATION_ITEMS.visualShowHeader,
  },
  showActions: {
    fieldKey: 'showActions',
    ...DOCUMENTATION_ITEMS.dashboardShowActions,
  },
  visualShowActions: {
    fieldKey: 'showActions',
    ...DOCUMENTATION_ITEMS.visualShowActions,
  },
  dashboardShowTitle: {
    fieldKey: 'showTitle',
    ...DOCUMENTATION_ITEMS.dashboardShowTitle,
  },
  dashboardLite: {
    fieldKey: 'embedInLiteMode',
    ...DOCUMENTATION_ITEMS.dashboardLite,
  },
  doubleDashboards: {
    fieldKey: 'doubleDashboards',
    ...DOCUMENTATION_ITEMS.doubleDashboards,
  },
  visualShowTitle: {
    fieldKey: 'showTitle',
    ...DOCUMENTATION_ITEMS.visualShowTitle,
  },
  editorPlacement: {
    fieldKey: 'editorPlacement',
    ...DOCUMENTATION_ITEMS.dashboardEditorPlacement,
    options: [
      { value: 'modals', label: 'Modals' },
      { value: 'dockRight', label: 'Dock right' },
    ],
  },
  visualEditorPlacement: {
    fieldKey: 'editorPlacement',
    ...DOCUMENTATION_ITEMS.visualEditorPlacement,
    options: [
      { value: 'dockRight', label: 'Dock right' },
      { value: 'modals', label: 'Modals' },
    ],
  },
  crossVisualFiltersPublish: {
    fieldKey: 'crossVisualFiltersPublish',
    ...DOCUMENTATION_ITEMS.publish,
  },
  crossVisualFiltersSubscribe: {
    fieldKey: 'crossVisualFiltersSubscribe',
    ...DOCUMENTATION_ITEMS.subscribe,
  },
  dashboardEventListeners: {
    fieldKey: 'dashboardEventListeners',
    ...DOCUMENTATION_ITEMS.dashboardEventListeners,
  },
  dashboardReadyEventListener: {
    fieldKey: 'dashboardReadyEventListener',
    ...DOCUMENTATION_ITEMS.dashboardReadyEventListener,
  },
  dashboardChangedEventListener: {
    fieldKey: 'dashboardChangedEventListener',
    ...DOCUMENTATION_ITEMS.dashboardChangedEventListener,
  },
  dashboardDeleteEventListener: {
    fieldKey: 'dashboardDeleteEventListener',
    ...DOCUMENTATION_ITEMS.dashboardDeleteEventListener,
  },
  dashboardLoadFailEventListener: {
    fieldKey: 'dashboardLoadFailEventListener',
    ...DOCUMENTATION_ITEMS.dashboardLoadFailEventListener,
  },
  dashboardSaveEventListener: {
    fieldKey: 'dashboardSaveEventListener',
    ...DOCUMENTATION_ITEMS.dashboardSaveEventListener,
  },
  dashboardVisualAddEventListener: {
    fieldKey: 'dashboardVisualAddEventListener',
    ...DOCUMENTATION_ITEMS.dashboardVisualAddEventListener,
  },
  dashboardVisualRemoveEventListener: {
    fieldKey: 'dashboardVisualRemoveEventListener',
    ...DOCUMENTATION_ITEMS.dashboardVisualRemoveEventListener,
  },
  dashboardBuilderReadyEventListener: {
    fieldKey: 'dashboardBuilderReadyEventListener',
    ...DOCUMENTATION_ITEMS.dashboardBuilderReadyEventListener,
  },
  dashboardBuilderChangedEventListener: {
    fieldKey: 'dashboardBuilderChangedEventListener',
    ...DOCUMENTATION_ITEMS.dashboardBuilderChangedEventListener,
  },
  dashboardBuilderDeleteEventListener: {
    fieldKey: 'dashboardBuilderDeleteEventListener',
    ...DOCUMENTATION_ITEMS.dashboardBuilderDeleteEventListener,
  },
  dashboardBuilderLoadFailEventListener: {
    fieldKey: 'dashboardBuilderLoadFailEventListener',
    ...DOCUMENTATION_ITEMS.dashboardBuilderLoadFailEventListener,
  },
  dashboardBuilderSaveEventListener: {
    fieldKey: 'dashboardBuilderSaveEventListener',
    ...DOCUMENTATION_ITEMS.dashboardBuilderSaveEventListener,
  },
  dashboardBuilderVisualAddEventListener: {
    fieldKey: 'dashboardBuilderVisualAddEventListener',
    ...DOCUMENTATION_ITEMS.dashboardBuilderVisualAddEventListener,
  },
  dashboardBuilderVisualRemoveEventListener: {
    fieldKey: 'dashboardBuilderVisualRemoveEventListener',
    ...DOCUMENTATION_ITEMS.dashboardBuilderVisualRemoveEventListener,
  },
  visualLoadEventListener: {
    fieldKey: 'visualLoadEventListener',
    ...DOCUMENTATION_ITEMS.visualLoadEventListener,
  },
  visualLoadFailEventListener: {
    fieldKey: 'visualLoadFailEventListener',
    ...DOCUMENTATION_ITEMS.visualLoadFailEventListener,
  },
  visualRenderEventListener: {
    fieldKey: 'visualRenderEventListener',
    ...DOCUMENTATION_ITEMS.visualRenderEventListener,
  },
  visualSaveEventListener: {
    fieldKey: 'visualSaveEventListener',
    ...DOCUMENTATION_ITEMS.visualSaveEventListener,
  },
  dashboardBuilderVisualLoadEventListener: {
    fieldKey: 'dashboardBuilderVisualLoadEventListener',
    ...DOCUMENTATION_ITEMS.dashboardBuilderVisualLoadEventListener,
  },
  dashboardBuilderVisualLoadFailEventListener: {
    fieldKey: 'dashboardBuilderVisualLoadFailEventListener',
    ...DOCUMENTATION_ITEMS.dashboardBuilderVisualLoadFailEventListener,
  },
  dashboardBuilderVisualRenderEventListener: {
    fieldKey: 'dashboardBuilderVisualRenderEventListener',
    ...DOCUMENTATION_ITEMS.dashboardBuilderVisualRenderEventListener,
  },
  dashboardBuilderVisualSaveEventListener: {
    fieldKey: 'dashboardBuilderVisualSaveEventListener',
    ...DOCUMENTATION_ITEMS.dashboardBuilderVisualSaveEventListener,
  },
  documentFailed: {
    fieldKey: 'documentFailed',
    ...DOCUMENTATION_ITEMS.documentFailed,
  },
  dashboardBuilderDocumentFailed: {
    fieldKey: 'dashboardBuilderDocumentFailed',
    ...DOCUMENTATION_ITEMS.dashboardBuilderDocumentFailed,
  },
  visualBuilderChange: {
    fieldKey: 'visualBuilderChange',
    ...DOCUMENTATION_ITEMS.visualBuilderChange,
  },
  visualBuilderFail: {
    fieldKey: 'visualBuilderFail',
    ...DOCUMENTATION_ITEMS.visualBuilderFail,
  },
  visualBuilderLoad: {
    fieldKey: 'visualBuilderLoad',
    ...DOCUMENTATION_ITEMS.visualBuilderLoad,
  },
  visualBuilderReady: {
    fieldKey: 'visualBuilderReady',
    ...DOCUMENTATION_ITEMS.visualBuilderReady,
  },

  dbVisualBuilderChange: {
    fieldKey: 'dbVisualBuilderChange',
    ...DOCUMENTATION_ITEMS.dbVisualBuilderChange,
  },
  dbVisualBuilderFail: {
    fieldKey: 'dbVisualBuilderFail',
    ...DOCUMENTATION_ITEMS.dbVisualBuilderFail,
  },
  dbVisualBuilderLoad: {
    fieldKey: 'dbVisualBuilderLoad',
    ...DOCUMENTATION_ITEMS.dbVisualBuilderLoad,
  },
  dbVisualBuilderReady: {
    fieldKey: 'dbVisualBuilderReady',
    ...DOCUMENTATION_ITEMS.dbVisualBuilderReady,
  },

  unauthorized: {
    fieldKey: 'unauthorized',
    ...DOCUMENTATION_ITEMS.unauthorized,
  },
  dashboardBuilderUnauthorized: {
    fieldKey: 'dashboardBuilderUnauthorized',
    ...DOCUMENTATION_ITEMS.dashboardBuilderUnauthorized,
  },
  inventoryEmbedListeners: {
    fieldKey: 'eventListeners',
    ...DOCUMENTATION_ITEMS.inventoryEventListeners,
  },
  inventoryEventListeners: {
    fieldKey: 'inventoryEventListeners',
    ...DOCUMENTATION_ITEMS.inventoryEventListeners,
  },
  inventoryReadyEventListener: {
    fieldKey: 'inventoryReadyEventListener',
    ...DOCUMENTATION_ITEMS.inventoryReadyEventListener,
  },
  inventoryFailEventListener: {
    fieldKey: 'inventoryFailEventListener',
    ...DOCUMENTATION_ITEMS.inventoryFailEventListener,
  },
  inventoryDeleteEventListener: {
    fieldKey: 'inventoryDeleteEventListener',
    ...DOCUMENTATION_ITEMS.inventoryDeleteEventListener,
  },
  dashboardBuilderEventListeners: {
    fieldKey: 'eventListeners',
    ...DOCUMENTATION_ITEMS.dashboardBuilderEventListeners,
  },
  dbEventListener: {
    fieldKey: 'dbEventListener',
    ...DOCUMENTATION_ITEMS.dashboardEventListeners,
  },
  visualEventListener: {
    fieldKey: 'visualEventListener',
    ...DOCUMENTATION_ITEMS.visualEventListener,
  },
  vbVisualLoadEventListener: {
    fieldKey: 'vbVisualLoadEventListener',
    ...DOCUMENTATION_ITEMS.visualLoadEventListener,
  },
  vbVisualLoadFailEventListener: {
    fieldKey: 'vbVisualLoadFailEventListener',
    ...DOCUMENTATION_ITEMS.visualLoadFailEventListener,
  },
  vbVisualRenderEventListener: {
    fieldKey: 'vbVisualRenderEventListener',
    ...DOCUMENTATION_ITEMS.visualRenderEventListener,
  },
  vbVisualSaveEventListener: {
    fieldKey: 'vbVisualSaveEventListener',
    ...DOCUMENTATION_ITEMS.visualSaveEventListener,
  },
  vbVisualBuilderChange: {
    fieldKey: 'vbVisualBuilderChange',
    ...DOCUMENTATION_ITEMS.visualBuilderChange,
  },
  vbVisualBuilderFail: {
    fieldKey: 'vbVisualBuilderFail',
    ...DOCUMENTATION_ITEMS.visualBuilderFail,
  },
  vbVisualBuilderReady: {
    fieldKey: 'vbVisualBuilderReady',
    ...DOCUMENTATION_ITEMS.visualBuilderReady,
  },
  dashboardIpOverrides: {
    fieldKey: 'dashboardIpOverrides',
    ...DOCUMENTATION_ITEMS.dashboardIpOverrides,
  },
  dashboardBuilderIpOverrides: {
    fieldKey: 'dashboardBuilderIpOverrides',
    ...DOCUMENTATION_ITEMS.dashboardBuilderIpOverrides,
  },
  visualIpOverrides: {
    fieldKey: 'visualIpOverrides',
    ...DOCUMENTATION_ITEMS.visualIpOverrides,
  },
  inventoryIpOverrides: {
    fieldKey: 'inventoryIpOverrides',
    ...DOCUMENTATION_ITEMS.inventoryIpOverrides,
  },
  listVisuals: {
    fieldKey: 'listVisuals',
    ...DOCUMENTATION_ITEMS.listVisuals,
  },
  listDashboards: {
    fieldKey: 'listDashboards',
    ...DOCUMENTATION_ITEMS.listDashboards,
  },
  embedSource: {
    fieldKey: 'embedSource',
    ...DOCUMENTATION_ITEMS.embedSource,
  },
  breadcrumbs: {
    fieldKey: 'breadcrumbs',
    ...DOCUMENTATION_ITEMS.breadcrumbs,
  },

  refresh: {
    fieldKey: 'refresh',
    ...DOCUMENTATION_ITEMS.refresh,
  },
  changeLayout: {
    fieldKey: 'changeLayout',
    ...DOCUMENTATION_ITEMS.changeLayout,
  },
  rename: {
    fieldKey: 'rename',
    ...DOCUMENTATION_ITEMS.rename,
  },
  shareFilterSets: {
    fieldKey: 'shareFilterSets',
    ...DOCUMENTATION_ITEMS.shareFilterSets,
  },
  dashboardInteractions: {
    fieldKey: 'dashboardInteractions',
    ...DOCUMENTATION_ITEMS.dashboardInteractions,
  },
  addToFavourites: {
    fieldKey: 'addToFavourites',
    ...DOCUMENTATION_ITEMS.addToFavourites,
  },
  exportPngPdf: {
    fieldKey: 'exportPngPdf',
    ...DOCUMENTATION_ITEMS.exportPngPdf,
  },
  dashboardLinks: {
    fieldKey: 'dashboardLinks',
    ...DOCUMENTATION_ITEMS.dashboardLinks,
  },
  exportConfiguration: {
    fieldKey: 'exportConfiguration',
    ...DOCUMENTATION_ITEMS.exportConfiguration,
  },
  addVisuals: {
    fieldKey: 'addVisuals',
    ...DOCUMENTATION_ITEMS.addVisuals,
  },
  delete: {
    fieldKey: 'delete',
    ...DOCUMENTATION_ITEMS.delete,
  },
  save: {
    fieldKey: 'save',
    ...DOCUMENTATION_ITEMS.save,
  },
  filter: {
    fieldKey: 'filter',
    ...DOCUMENTATION_ITEMS.filter,
  },
  manageTags: {
    fieldKey: 'manageTags',
    name: 'Manage Tags',
  },
  manageAlerts: {
    fieldKey: 'manageAlerts',
    name: 'Set Up Dashboard Alerts',
  },
  shareDashboard: {
    fieldKey: 'shareDashboard',
    name: 'Share Dashboard',
  },
  scheduleReports: {
    fieldKey: 'scheduleReports',
    name: 'Schedule Reports',
  },
  textSnippet: {
    fieldKey: 'textSnippet',
    name: 'Add Text Snippets',
  },
  filterSnippet: {
    fieldKey: 'filterSnippet',
    name: 'Add Filter Snippets',
  },
  widgetSettings: {
    fieldKey: 'widgetSettings',
    name: 'Widget Settings',
  },
  comments: {
    fieldKey: 'comments',
    name: 'Comments',
  },
  actionsAction: {
    fieldKey: 'actionsAction',
    ...DOCUMENTATION_ITEMS.actionsAction,
  },
  rulers: {
    fieldKey: 'rulers',
    ...DOCUMENTATION_ITEMS.rulers,
  },
  zoomAction: {
    fieldKey: 'zoomAction',
    ...DOCUMENTATION_ITEMS.zoomAction,
  },
  filterAction: {
    fieldKey: 'filterAction',
    ...DOCUMENTATION_ITEMS.filterAction,
  },
  colors: {
    fieldKey: 'colors',
    name: 'Colors',
  },
  metrics: {
    fieldKey: 'metrics',
    name: 'Metrics',
  },
  actions: {
    fieldKey: 'actions',
    name: 'Actions',
  },
  trendAction: {
    fieldKey: 'trendAction',
    name: 'Trend Action',
  },
  visualStyle: {
    fieldKey: 'visualStyle',
    name: 'Visual Style',
  },
  keysetAction: {
    fieldKey: 'keysetAction',
    name: 'Keyset Action',
  },
  keyset: {
    fieldKey: 'keyset',
    name: 'Keyset',
  },
  copy: {
    fieldKey: 'copy',
    name: 'Copy',
  },
  settings: {
    fieldKey: 'settings',
    name: 'Settings',
  },
  export: {
    fieldKey: 'export',
    name: 'Export',
  },
  timebarPanel: {
    fieldKey: 'timebarPanel',
    name: 'Timebar Panel',
  },
  detailsAction: {
    fieldKey: 'detailsAction',
    name: 'Details Action',
  },
  maximize: {
    fieldKey: 'maximize',
    name: 'Maximize',
  },
  linkAction: {
    fieldKey: 'linkAction',
    name: 'Link Action',
  },
  remove: {
    fieldKey: 'remove',
    name: 'Remove',
  },
  grouping: {
    fieldKey: 'grouping',
    name: 'Grouping',
  },
  sort: {
    fieldKey: 'sort',
    name: 'Sort',
  },
  timebarField: {
    fieldKey: 'timebarField',
    name: 'Timebar Field',
  },
  saveAs: {
    fieldKey: 'saveAs',
    name: 'Save As',
  },
  conditionalFormatting: {
    fieldKey: 'conditionalFormatting',
    name: 'Conditional Formatting',
  },
  info: {
    fieldKey: 'info',
    name: 'Info',
  },
  addNew: {
    fieldKey: 'addNew',
    name: 'Add New',
  },
  import: {
    fieldKey: 'import',
    name: 'Import',
  },
  permissions: {
    fieldKey: 'permissions',
    name: 'Permissions',
  },
  share: {
    fieldKey: 'share',
    name: 'Share',
  },
  multiSelect: {
    fieldKey: 'multiSelect',
    name: 'Multi Selection',
  },
  description: {
    fieldKey: 'description',
    name: 'Description',
  },
  tags: {
    fieldKey: 'tags',
    name: 'Tags',
  },
  rowSecurity: {
    fieldKey: 'rowSecurity',
    name: 'Row Security',
  },
  columnSecurity: {
    fieldKey: 'columnSecurity',
    name: 'Column Security',
  },
  clearCache: {
    fieldKey: 'clearCache',
    name: 'Clear Cache',
  },
  availableVisualTypes: {
    fieldKey: 'availableVisualTypes',
    name: 'Available Visual Types',
  },
  schedule: {
    fieldKey: 'schedule',
    name: 'Schedule',
  },
  favorites: {
    fieldKey: 'favorites',
    name: 'Favorites',
  },
  customizeActionButtons: {
    fieldKey: 'customizeActionButtons',
    ...DOCUMENTATION_ITEMS.customizeActionButtons,
  },
  customizeSearchControllers: {
    fieldKey: 'customizeSearchControllers',
    ...DOCUMENTATION_ITEMS.customizeSearchControllers,
  },
  customizeFilterButtons: {
    fieldKey: 'customizeFilterButtons',
    ...DOCUMENTATION_ITEMS.customizeFilterButtons,
  },
  customizeInventoryTable: {
    fieldKey: 'customizeInventoryTable',
    ...DOCUMENTATION_ITEMS.customizeInventoryTable,
  },
  sourceEditorShowHeader: {
    fieldKey: 'showHeader',
    ...DOCUMENTATION_ITEMS.sourceEditorShowHeader,
  },
  sourceEditorShowTitle: {
    fieldKey: 'showTitle',
    ...DOCUMENTATION_ITEMS.sourceEditorShowTitle,
  },
  sourceEditorActiveTab: {
    fieldKey: 'sourceEditorActiveTab',
    ...DOCUMENTATION_ITEMS.sourceEditorActiveTab,
    options: [
      { value: 'create', label: 'Create' },
      { value: 'fields', label: 'Fields' },
      { value: 'caching', label: 'Caching' },
      { value: 'settings', label: 'Settings' },
    ],
  },
  showCreateTab: {
    fieldKey: 'showCreateTab',
    ...DOCUMENTATION_ITEMS.showCreateTab,
  },
  showFieldsTab: {
    fieldKey: 'showFieldsTab',
    ...DOCUMENTATION_ITEMS.showFieldsTab,
  },
  showCacheTab: {
    fieldKey: 'showCacheTab',
    ...DOCUMENTATION_ITEMS.showCacheTab,
  },
  showSettingsTab: {
    fieldKey: 'showSettingsTab',
    ...DOCUMENTATION_ITEMS.showSettingsTab,
  },
  sourceEditorEventListeners: {
    fieldKey: 'sourceEditorEventListeners',
    ...DOCUMENTATION_ITEMS.sourceEditorEventListeners,
  },
  sourceEditorIpOverrides: {
    fieldKey: 'sourceEditorIpOverrides',
    ...DOCUMENTATION_ITEMS.sourceEditorIpOverrides,
  },
  addFromConnection: {
    fieldKey: 'addFromConnection',
    ...DOCUMENTATION_ITEMS.addFromConnection,
  },
  addFromFileUpload: {
    fieldKey: 'addFromFileUpload',
    ...DOCUMENTATION_ITEMS.addFromFileUpload,
  },
  selectFileUpload: {
    fieldKey: 'selectFileUpload',
    ...DOCUMENTATION_ITEMS.selectFileUpload,
  },
  uploadnewFile: {
    fieldKey: 'uploadnewFile',
    ...DOCUMENTATION_ITEMS.uploadnewFile,
  },
  createJoins: {
    fieldKey: 'createJoins',
    ...DOCUMENTATION_ITEMS.createJoins,
  },
  filterValuesEntities: {
    fieldKey: 'filterValuesEntities',
    ...DOCUMENTATION_ITEMS.filterValuesEntities,
  },
  exportSource: {
    fieldKey: 'exportSource',
    ...DOCUMENTATION_ITEMS.exportSource,
  },
  copySource: {
    fieldKey: 'copySource',
    ...DOCUMENTATION_ITEMS.copySource,
  },
  addDerivedField: {
    fieldKey: 'addDerivedField',
    ...DOCUMENTATION_ITEMS.addDerivedField,
  },
  addHierarchyField: {
    fieldKey: 'addHierarchyField',
    ...DOCUMENTATION_ITEMS.addHierarchyField,
  },
  addCustomMetric: {
    fieldKey: 'addCustomMetric',
    ...DOCUMENTATION_ITEMS.addCustomMetric,
  },
  visibility: {
    fieldKey: 'visibility',
    ...DOCUMENTATION_ITEMS.visibility,
  },
  filterValueOverrides: {
    fieldKey: 'filterValueOverrides',
    ...DOCUMENTATION_ITEMS.filterValueOverrides,
  },
  fieldCapabilities: {
    fieldKey: 'fieldCapabilities',
    ...DOCUMENTATION_ITEMS.fieldCapabilities,
  },
  manageTranslations: {
    fieldKey: 'manageTranslations',
    ...DOCUMENTATION_ITEMS.manageTranslations,
  },
  setUniqueKey: {
    fieldKey: 'setUniqueKey',
    ...DOCUMENTATION_ITEMS.setUniqueKey,
  },
  dataCache: {
    fieldKey: 'dataCache',
    ...DOCUMENTATION_ITEMS.dataCache,
  },
  statisticsCache: {
    fieldKey: 'statisticsCache',
    ...DOCUMENTATION_ITEMS.statisticsCache,
  },
  scheduleRefresh: {
    fieldKey: 'scheduleRefresh',
    ...DOCUMENTATION_ITEMS.scheduleRefresh,
  },
  fieldSettings: {
    fieldKey: 'fieldSettings',
    ...DOCUMENTATION_ITEMS.fieldSettings,
  },
};
