import React, { useState } from 'react';
import styles from './DocumentationHelper.module.scss';
import { ReactComponent as DocumentIcon } from 'src/static/icons/document.svg';
import { ReactComponent as ArrowDownIcon } from 'src/static/icons/angle-small-down.svg';
import DocumentationHelperPopup from '../DocumentationHelperPopup/DocumentationHelperPopup';
import {
  DASHBOARD_AUTHORING_DOCUMENTATION_POPUP_ITEMS,
  EMBEDDED_DASHBOARD_DOCUMENTATION_POPUP_ITEMS,
  INVENTORY_DOCUMENTATION_POPUP_ITEMS,
  SOURCE_EDITOR_DOCUMENTATION_POPUP_ITEMS,
  VISUAL_AUTHORING_DOCUMENTATION_POPUP_ITEMS,
  EMBED_VIEWER_POPUP_ITEMS,
  METRIC_SET_POPUP_ITEMS,
  AD_HOC_EDITOR_POPUP_ITEMS,
} from 'src/shared/constants/documentationConstants';

export default function DocumentationHelper({ selectedGroup, productSelected }) {
  const [isPopupDisplayed, setIsPopupDisplayed] = useState(false);
  const composerDocumentationTopics = {
    dashboard: EMBEDDED_DASHBOARD_DOCUMENTATION_POPUP_ITEMS,
    'visual-builder': VISUAL_AUTHORING_DOCUMENTATION_POPUP_ITEMS,
    'dashboard-builder': DASHBOARD_AUTHORING_DOCUMENTATION_POPUP_ITEMS,
    inventory: INVENTORY_DOCUMENTATION_POPUP_ITEMS,
    'source-editor': SOURCE_EDITOR_DOCUMENTATION_POPUP_ITEMS,
  };

  const dundasDocumentationTopics = {
    'embed-viewer': EMBED_VIEWER_POPUP_ITEMS,
    'metric-set': METRIC_SET_POPUP_ITEMS,
    'adhoc-editor': AD_HOC_EDITOR_POPUP_ITEMS,
  };

  const documentationTopics = productSelected === 'Composer' ? composerDocumentationTopics : dundasDocumentationTopics;

  return (
    <div className={styles.root}>
      <div onClick={() => setIsPopupDisplayed((isPopupDisplayed) => !isPopupDisplayed)} className={styles.popupTrigger}>
        <DocumentIcon className={styles.documentIcon}></DocumentIcon>
        <span>Documentation Helper</span>
        <ArrowDownIcon className={styles.arrowDownIcon}></ArrowDownIcon>
      </div>
      {isPopupDisplayed && (
        <>
          <div className={styles.overlay} onClick={() => setIsPopupDisplayed(false)}></div>
          <div className={styles.popup}>
            <DocumentationHelperPopup
              topicGroups={documentationTopics}
              onClose={() => setIsPopupDisplayed(false)}
              selectedGroup={selectedGroup}
            ></DocumentationHelperPopup>
          </div>
        </>
      )}
    </div>
  );
}
