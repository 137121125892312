import { useState } from 'react';
import { createAccount } from 'src/api/user';
import InfoTooltip from 'src/shared/components/InfoTooltip/InfoTooltip';
import styles from './NewAccount.module.scss';

const EMPTY_ACCOUNT = { accountName: '', password: '', customerName: '', prospectName: '', notes: '' };
const PASSWORD_PATTERN =
  '^(?=(.*[0-9]){1,})(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[!@#$%\\^&*()\\-_=+,.:;<>]){1,}).{9,255}$';

const formControls = [
  {
    label: 'Account name',
    property: 'accountName',
  },
  {
    label: 'Admin user name',
    property: 'customerName',
  },
  {
    label: 'Password',
    property: 'password',
    type: 'password',
    helpText:
      'Password must contain at least 9 characters including 1 lowercase, 1 uppercase, 1 number and 1 special (!@#$%^&*()-_=+,.:;<>).',
  },
  {
    label: 'Prospect Name',
    property: 'prospectName',
  },
  {
    label: 'Notes',
    property: 'notes',
    type: 'textarea',
  },
];

export default function NewAccount() {
  const [account, setAccount] = useState(EMPTY_ACCOUNT);
  const [message, setMessage] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    createAccount(account)
      .then(() => {
        setAccount(EMPTY_ACCOUNT);
        setMessage({ type: 'success', text: 'New Account has been created' });
      })
      .catch((err) => setMessage({ type: 'error', text: err.message || err.details || 'Something went wrong' }));
  };

  const isPasswordInvalid = () => {
    return account.password && !account.password.match(PASSWORD_PATTERN);
  };

  return (
    <div className={styles.root}>
      <h1 className={styles.title}>New Account</h1>
      <form onSubmit={handleSubmit} className={styles.form}>
        {formControls.map((control) => (
          <div className={styles.row} key={control.property}>
            <label htmlFor={control.property}>{control.label}</label>
            <div className={styles.inputWrap}>
              {control.type === 'textarea' ? (
                <textarea
                  name={control.property}
                  id={control.property}
                  value={account[control.property]}
                  onChange={({ target }) => setAccount({ ...account, [control.property]: target.value })}
                />
              ) : (
                <input
                  className={control.property === 'password' && isPasswordInvalid() ? styles.input_error : ''}
                  type={control.type || 'text'}
                  name={control.property}
                  id={control.property}
                  value={account[control.property]}
                  onChange={({ target }) => setAccount({ ...account, [control.property]: target.value })}
                />
              )}
              {control.helpText && <InfoTooltip>{control.helpText}</InfoTooltip>}
            </div>
          </div>
        ))}
        <div className={styles.btns}>
          <button type="submit" className={styles.submit} disabled={!account.password || isPasswordInvalid()}>
            Create
          </button>
        </div>
      </form>
      {message && (
        <div className={`${styles.message} ${styles[message.type]}`}>
          <p>{message.text}</p>
        </div>
      )}
    </div>
  );
}
