import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modals: [],
};

export const modalsSlice = createSlice({
  name: 'embeddedDashboards',
  initialState,
  reducers: {
    showModal(state, { payload: { name, contentLink, id } }) {
      state.modals.push({ name, contentLink, id });
    },
    hideModal(state, { payload }) {
      state.modals = state.modals.filter((m) => m.id !== payload);
    },
  },
});

export const { showModal, hideModal } = modalsSlice.actions;

export default modalsSlice.reducer;
