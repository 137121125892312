import styles from './Landing.module.scss';
import bgImg from './images/flyover-video-bg.svg';
import PreviewImage1 from './images/playgroundimage.png';
import { VDDIcon, MDRIcon } from 'src/components/RemixIcons/generated/remixIcons';

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useState, useEffect } from 'react';

export default function Landing() {
  const history = useHistory();
  const [email, setEmail] = useState('');

  const [emailError, setEmailError] = useState('');

  // Function to handle error display and validation
  const validateAndSetError = () => {
    if (!email.trim()) {
      setEmailError('Email cannot be empty');
      return false;
    } else if (!validateEmail(email)) {
      setEmailError('Invalid email format');
      return false;
    }
    setEmailError('');
    return true;
  };

  const handleDemoButtonClick = () => {
    if (!email.trim()) {
      setEmailError('Email cannot be empty');
    } else {
      const isValid = validateAndSetError();
      if (isValid) {
        window.location.href = `https://insightsoftware.com/demo/logi-symphony/?email=${encodeURIComponent(email)}`;
      }
    }
  };

  const handlePricingButtonClick = () => {
    if (!email.trim()) {
      setEmailError('Email cannot be empty');
    } else {
      const isValid = validateAndSetError();
      if (isValid) {
        window.location.href = `https://insightsoftware.com/request-a-price/?email=${encodeURIComponent(email)}`;
      }
    }
  };

  const handleEmailChange = (event) => {
    const inputEmail = event.target.value;
    setEmail(inputEmail);
    if (!inputEmail.trim()) {
      setEmailError('Email cannot be empty');
    } else if (emailError) {
      setEmailError('');
    }
  };

  const validateEmail = (input) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(input);
  };

  function scrollToSection(sectionId) {
    const section = document.getElementById(sectionId);
    if (section) {
      const headerHeight = 40; // Replace this with the actual height of your fixed header
      const offset = section.offsetTop - headerHeight;

      window.scrollTo({
        top: offset,
        behavior: 'smooth',
      });
    }
  }


  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const emailParam = params.get('email');
    if (emailParam) {
      setEmail(decodeURIComponent(emailParam));
    }
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.mainContainer}>
        <div className={styles.leftContainer}>
          <div>
            <h1 className={styles.ContentTitle}>Logi Symphony Developer Samples and Playground</h1>
            <p className={styles.TextRegular}>
              Explore the Logi Symphony Developer Playground, a space dedicated to our low-code, rapid development tools
              crafted for software teams. Dive into white-labeling options, grab functional code samples, and discover
              straightforward embedding features. Start effortlessly creating interactive analytics embeddable content
              that suits your brand and business needs.
            </p>
            <div className={styles.buttonsContainer}>
              <button className={styles.buttonProduct} onClick={() => scrollToSection('ourModules')}>
                Get Started
              </button>
              <a
                href="https://insightsoftware.com/logi-symphony/"
                target="_blank"
                className={styles.learnMoreLink}
              >
                Learn More
              </a>
            </div>
          </div>
        </div>

        <div className={styles.rightContainer}>
          <img className={styles.bgImg} src={bgImg}></img>
          <img className={styles.previewImg1} src={PreviewImage1}></img>
        </div>
      </div>

      <div id="ourModules" className={styles.containerProductHeader}>
        <h1 className={styles.productHeaderTitle}>Our features</h1>
        <p className={styles.productHeaderContent}>
          The flexible, purpose-built BI and Analytics platform for embedding into other applications. Try it now!
        </p>
      </div>

      <div className={styles.containerProduct}>
        <div className={styles.leftProductContainer}>
          <div className={styles.productContent}>
            <div className={styles.innerProductContent}>
              <MDRIcon
                sx={{
                  height: 'max(85px,133.3%)',
                  width: 'max(72.5px,26.6%)',
                  fill: '#007ac8',
                }}
              />
            </div>

            <div>
              <h1 className={styles.productTitle}>Managed Dashboards & Reports</h1>
              <p className={styles.productTextRegular}>
                Take control of the user experience and provide users with perfectly executed prefabricated dashboards
                and reports. Take the work out of the hands of your users and give them the answers they need, delivered
                seamlessly through our platform.
              </p>
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <button className={styles.buttonProduct} onClick={() => history.push('/managed/embed-options')}>
              Explore embed options
            </button>
            <a
              href="/documentation/content/topics/sym-embed-playground/sym-pg-embed-javascript-embed-options.html"
              target="_blank"
              className={styles.learnMoreLink}
            >
              Learn More
            </a>
          </div>
        </div>

        <div className={styles.rightProductContainer}>
          <div className={styles.productContent}>
            <div className={styles.innerProductContent}>
              <VDDIcon
                sx={{
                  height: 'max(85px,133.3%)',
                  width: 'max(72.5px,26.6%)',
                  fill: '#007ac8',
                }}
              />
            </div>

            <div>
              <h1 className={styles.productTitle}>Visual Data Discovery</h1>
              <p className={styles.productTextRegular}>
                Liberate end users from limitations by enabling them to construct their own dashboards, metrics,
                formulas, and more seamlessly within other applications. Our Visual Data Discovery offers an embeddable
                interface, empowering users with flexibility and control.
              </p>
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <button className={styles.buttonProduct} onClick={() => history.push('/discovery/embed-options')}>
              Explore embed options
            </button>
            <a
              href="/documentation/content/topics/embed/pg-embed-javascript-embed-options.html"
              target="_blank"
              className={styles.learnMoreLink}
            >
              Learn More
            </a>
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        <h1 className={styles.productHeaderTitle}>Learn more about the product</h1>
        <div className={`${styles.inputBox} ${email ? styles.hasEmail : ''}`} id="reachOutToUs">
          <input
            type="email"
            name="email"
            value={email}
            onChange={handleEmailChange}
            autoComplete="email"
            className={styles.input}
          />
          <label className={styles.label}>Business Email*</label>
        </div>
        {emailError && <div className={styles.errorMessage}>{emailError}</div>}
        <div className={styles.buttonsContainer}>
          <button
            className={styles.buttonProduct}
            onClick={() => {
              const isValid = validateAndSetError();
              if (isValid) {
                handleDemoButtonClick();
              }
            }}
          >
            Get a Demo
          </button>
          <button
            className={styles.learnMoreLink}
            onClick={() => {
              const isValid = validateAndSetError();
              if (isValid) {
                handlePricingButtonClick();
              }
            }}
          >
            Get Pricing
          </button>
        </div>
      </div>
    </div>
  );
}
