import classNames from 'classnames';
import React from 'react';
import Button from 'src/components/Button/Button';
import styles from './EditModeWarningModalContent.module.scss';
import { ReactComponent as ExclamationIcon } from 'src/static/icons/exclamation.svg';

export default function EditModeWarningModalContent({ onConfirm, onCancel }) {
  return (
    <div className={styles.root}>
      <div className={styles.iconHolder}>
        <ExclamationIcon className={styles.exclamationIcon}></ExclamationIcon>
      </div>
      <div className={styles.content}>
        <h2 className={styles.heading}>Warning</h2>
        <p className={styles.text}>
          Your custom code will not be preserved, and your code will be lost. Are you sure, you want to move away from
          this screen?
        </p>
        <div className={styles.controlsContainer}>
          <Button onClick={onCancel} className={classNames(styles.button, styles.cancel)}>
            Cancel
          </Button>
          <Button onClick={onConfirm} className={classNames(styles.button, styles.confirm)}>
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
}
