import { fetchSample, putSample } from 'src/api/samples';

const { createAsyncThunk } = require('@reduxjs/toolkit');

export const getSample = createAsyncThunk('sample/fetchSample', async (id) => {
  const response = await fetchSample(id);
  return response.data;
});

export const updateSample = createAsyncThunk('sample/updateSample', async (id, { getState }) => {
  const {
    sample: { sample },
    embed: { editorCode },
  } = getState();
  const res = { ...sample, ...editorCode };
  await putSample(res);
});
