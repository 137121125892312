import React, { useCallback, useEffect, useState } from 'react';
import styles from './NavigationMenu.module.scss';
import { useHistory } from 'react-router-dom';
import {
  COMMON_NAV_MENU_ITEMS,
  VDD_NAV_MENU_ITEMS,
  MDR_NAV_MENU_ITEMS,
} from 'src/shared/constants/navigationConstants';
import { RouteButton } from '../NavigationItem/NavigationItem';
import { Stack } from '@mui/material';

export default function NavigationMenu({ expanded }) {
  const history = useHistory();
  const [currentPath, setCurrentPath] = useState(history.location.pathname);
  // const currentUser = useSelector((state) => state.playgroundUser.user);
  const onClick = useCallback(
    (path) => {
      history.push(path);
    },
    [history]
  );

  history.listen((location) => {
    setCurrentPath(location.pathname);
  });

  useEffect(() => {
    setCurrentPath(history.location.pathname);
  }, []);

  // const filterMenuItems = ({ path }) => {
  //   if (currentUser) {
  //     if (path === '/new-account') {
  //       return currentUser.roles.includes('SALES');
  //     }
  //     return path !== '/my-samples';
  //   } else {
  //     return path !== '/new-account';
  //   }
  // };

  const currentProduct = currentPath.slice(0, 8);
  const NAV_MENU_ITEMS =
    currentProduct === '/discove'
      ? VDD_NAV_MENU_ITEMS
      : currentProduct === '/managed'
      ? MDR_NAV_MENU_ITEMS
      : COMMON_NAV_MENU_ITEMS;

  const groupedMenuItems = NAV_MENU_ITEMS.reduce((acc, item) => {
    const { category } = item;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(item);
    return acc;
  }, {});

  return (
    <div className={styles.root}>
      {/* Render other items grouped by category */}
      {Object.keys(groupedMenuItems).map((category) => (
        <React.Fragment key={category}>
          {expanded && category != 'Home' && (
            <div className={styles.categoryTitle}>
              <span className={styles.categoryTitleText}>{category}</span>
            </div>
          )}
          {groupedMenuItems[category].map((item) => (
            <Stack>
              <RouteButton
                isActive={currentPath === item.path}
                onClick={onClick}
                path={item.path}
                title={item.name}
                startIcon={item.icon}
                expanded={expanded}
                params={item.params}
              >
                <span
                  style={{
                    fontSize: '13px',
                    fontWeight: '550',
                    fontFamily:
                      'InterVariable, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  {item.name}
                </span>
              </RouteButton>
            </Stack>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
}
