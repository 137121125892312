import React, { useState } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { showModal } from 'src/components/ModalContainer/modalsSlice';
import InfoTooltip from '../InfoTooltip/InfoTooltip';
import styles from './DocumentationHelperPopup.module.scss';
import { ReactComponent as ArrowDownIcon } from 'src/static/icons/angle-small-down.svg';
import classNames from 'classnames';

const componentOptions = {
  dashboard: 'Dashboard',
  'visual-builder': 'Visual Builder',
  'dashboard-builder': 'Dashboard Builder',
  inventory: 'Inventory',
  'source-editor': 'Source Editor',
  'embed-viewer': 'Embed Viewer',
  'metric-set': 'Metric Set',
  'adhoc-editor': 'Ad-Hoc Editor',
};

export default function DocumentationHelperPopup({ onClose, topicGroups, selectedGroup }) {
  const dispatch = useDispatch();
  const [groupExpanded, setGroupExpanded] = useState(
    Object.keys(topicGroups).reduce((res, groupName) => ({ ...res, [groupName]: groupName === selectedGroup }), {})
  );
  const docLinkClickHandler = (modalTitle, contentLink) => {
    onClose();
    dispatch(showModal({ name: modalTitle, id: nanoid(), contentLink }));
  };

  return (
    <div className={styles.root}>
      <h2 className={styles.heading}>Documentation Topics and Code Samples</h2>
      {Object.entries(topicGroups).map(([groupName, topicArticles]) => (
        <div key={groupName}>
          <div
            onClick={() => setGroupExpanded({ ...groupExpanded, [groupName]: !groupExpanded[groupName] })}
            className={styles.groupName}
          >
            <ArrowDownIcon
              className={classNames(styles.arrow, { [styles.inverted]: groupExpanded[groupName] })}
            ></ArrowDownIcon>
            <span className={classNames({ [styles.highlighted]: groupName === selectedGroup })}>
              {componentOptions[groupName]}
            </span>
          </div>
          {groupExpanded[groupName] && (
            <ul>
              {topicArticles.map(({ name, content, link }) => (
                <li key={name} className={styles.articleName}>
                  <a className={styles.articleNameText} onClick={() => docLinkClickHandler(name, link)}>
                    {name}
                  </a>
                  <InfoTooltip>
                    <div className={styles.tooltipText}>
                      <h2 className={styles.tooltipHeader}>{name}</h2>
                      {content}&nbsp;
                      {link && (
                        <span className={styles.documentationLink} onClick={() => docLinkClickHandler(name, link)}>
                          Learn&nbsp;more...
                        </span>
                      )}
                    </div>
                  </InfoTooltip>
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </div>
  );
}
