import { fetchMySamples, postSample, deleteSampleById, updateSamplePublicValue } from 'src/api/samples';
import { getDefaultSettings } from 'src/helpers/embedOptionHelpers';
import { getPublicSamples } from 'src/samplesRedux/thunks';
import { EMBED_COMPONENT_TYPES } from 'src/shared/constants/embedComponentTypes';
import {
  COMMON_EMBED_HTML_CODE,
  COMMON_EMBED_JS_CODE,
  COMMON_EMBED_CSS_CODE,
} from 'src/shared/constants/liveCodeScripts/commonCode';
import {
  applySettingsToHtmlCode,
  applySettingsToJsCode,
  applySettingsToCssCode,
} from 'src/shared/utils/codeBuilderHelpers';

const { createAsyncThunk } = require('@reduxjs/toolkit');

export const getMySamples = createAsyncThunk('samples/fethMySamples', async () => {
  const response = await fetchMySamples();
  return response.data.data;
});

export const deleteSample = createAsyncThunk('samples/deleteSample', async (id) => {
  await deleteSampleById(id);
});

export const setSamplePublicValue = createAsyncThunk(
  'samples/setSamplePublicValue',
  async ({ sampleId, isPublic }, { dispatch }) => {
    await updateSamplePublicValue({ sampleId, isPublic }).then(() => {
      dispatch(getPublicSamples());
    });
  }
);

export const createNewSample = createAsyncThunk('samples/createNewSample', async ({ html, css, js, title }) => {
  const response = await postSample({
    html:
      html ||
      applySettingsToHtmlCode(
        COMMON_EMBED_HTML_CODE,
        getDefaultSettings('interactive', EMBED_COMPONENT_TYPES.dashboard)
      ),
    js:
      js ||
      applySettingsToJsCode(
        COMMON_EMBED_JS_CODE,
        getDefaultSettings('interactive', EMBED_COMPONENT_TYPES.dashboard),
        EMBED_COMPONENT_TYPES.dashboard
      ),
    css:
      css ||
      applySettingsToCssCode(COMMON_EMBED_CSS_CODE, getDefaultSettings('interactive', EMBED_COMPONENT_TYPES.dashboard)),
    title,
  });

  return response.data;
});
