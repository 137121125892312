import { createSlice } from '@reduxjs/toolkit';
import { getSample, updateSample } from './thunks';

const initialState = {
  sample: null,
  isLoading: false,
  isUpdating: false,
};

export const slice = createSlice({
  name: 'sample',
  initialState,
  reducers: {
    setSample(state, { payload }) {
      state.sample = payload;
    },
    setIsLoading(state, { payload }) {
      state.isLoading = payload;
    },
    setIsUpdating(state, { payload }) {
      state.isUpdating = payload;
    },
    updateSampleTitle(state, { payload }) {
      state.sample.title = !payload || !payload.length ? state.sample.title : payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getSample.fulfilled, (state, { payload }) => {
        state.sample = payload;
        state.isLoading = false;
      })
      .addCase(updateSample.fulfilled, (state) => {
        state.isUpdating = false;
      }),
});

export const { setSample, setIsLoading, updateSampleTitle, setIsUpdating } = slice.actions;

export default slice.reducer;
