import httpClient from './httpClient';
import publicHttpClient from './publicHttpClient';

export const fetchMySamples = () => httpClient.get('/my-samples');
export const fetchSample = (id) => httpClient.get(`/samples/${id}`);
export const deleteSampleById = (id) => httpClient.delete(`/samples/${id}`);
export const putSample = (sample) => httpClient.put(`/samples/${sample.id}`, sample);

export const postSample = (sample) => httpClient.post('/samples', sample);
export const fetchPublicSamples = (sample) => publicHttpClient.get('/public-samples', sample);
export const updateSamplePublicValue = ({ sampleId, isPublic }) =>
  httpClient.put(`/samples/${sampleId}/public`, { isPublic });
