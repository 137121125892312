import { combineReducers, configureStore } from '@reduxjs/toolkit';
import embedReducer from 'src/shared/store/embedSlice';
import modalsReducer from './components/ModalContainer/modalsSlice';
import playgroundUser from './redux/playgroundUserSlice';
import samplesReducer from './pages/MySamples/redux/mySamplesSlice';
import sampleReducer from './pages/Sample/redux/samplesSlice';
import userReducer from './redux/userSlice';
import publicSamplesReducer from './samplesRedux/publicSamplesSlice';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

const rootReducer = combineReducers({
  embed: embedReducer,
  modals: modalsReducer,
  samples: samplesReducer,
  sample: sampleReducer,
  publicSamples: publicSamplesReducer,
  user: userReducer,
  playgroundUser: playgroundUser,
});

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: [
    'embedReducer',
    'modalsReducer',
    'playgroundUser',
    'samplesReducer',
    'sampleReducer',
    'publicSamplesReducer',
    'userReducer',
  ],
};

const pReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: pReducer,
  middleware: [thunk],
});
export const persistor = persistStore(store);
