import styles from './EmbedCode.module.scss';
import classNames from 'classnames';
import { micromark } from 'micromark';

import AceEditor from 'react-ace';
import ace from 'ace-builds';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/mode-markdown';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-min-noconflict/ext-searchbox';

import Panel from '../Panel/Panel';
import { TABS } from 'src/shared/constants/liveCodeTabs';

export default function EmbedCode({
  onRunClick,
  htmlCode,
  jsCode,
  cssCode,
  mdCode,
  onHtmlCodeChange,
  onJsCodeChange,
  onCssCodeChange,
  onMdCodeChange,
  selectedFile,
  setSelectedFile,
  showReadme = false,
  readmeEditable = false,
}) {
  const copyCode = () =>
    navigator.clipboard.writeText(
      selectedFile.name === 'index.html' ? htmlCode : selectedFile.name === 'embed.js' ? jsCode : cssCode
    );
  ace.config.set('basePath', 'path');

  const editorOptions = {
    theme: 'monokai',
    setOptions: {
      useWorker: false,
      enableBasicAutocompletion: true,
      enableLiveAutocompletion: true,
    },
    className: styles.editor,
    width: '100%',
    height: '100%',
  };

  return (
    <div className={styles.root}>
      <div className={styles.codeBlock}>
        <div className={styles.header}>
          {Object.values(TABS).map(
            (tab) =>
              (tab.name !== TABS.md.name || (tab.name === TABS.md.name && showReadme)) && (
                <button
                  key={tab.name}
                  onClick={() => setSelectedFile(tab)}
                  className={classNames(styles.tabButton, {
                    [styles.active]: selectedFile === tab,
                  })}
                >
                  {tab.name}
                </button>
              )
          )}

          <div className={styles.controls}>
            <button className={styles.panelButton} onClick={onRunClick}>
              Run
            </button>
            <button className={styles.panelButton} onClick={copyCode}>
              Copy code
            </button>
          </div>
        </div>
        <Panel className={styles.panel}>
          {selectedFile.name === 'index.html' && (
            <div className={styles.tabHolder}>
              <AceEditor
                value={htmlCode}
                mode="html"
                {...editorOptions}
                onChange={(value) => {
                  onHtmlCodeChange(value);
                }}
              />
            </div>
          )}
          {selectedFile.name === 'embed.js' && (
            <div className={styles.tabHolder}>
              <AceEditor
                value={jsCode}
                mode="javascript"
                {...editorOptions}
                onChange={(value) => {
                  onJsCodeChange(value);
                }}
              />
            </div>
          )}
          {selectedFile.name === 'styles.css' && (
            <div className={styles.tabHolder}>
              <AceEditor
                value={cssCode}
                mode="css"
                {...editorOptions}
                onChange={(value) => {
                  onCssCodeChange(value);
                }}
              />
            </div>
          )}
          {selectedFile.name === TABS.md.name && (
            <div className={styles.tabHolder}>
              {readmeEditable && (
                <div className={styles.mdHolder}>
                  <AceEditor
                    value={mdCode}
                    mode="mode-markdown"
                    {...editorOptions}
                    onChange={(value) => {
                      onMdCodeChange(value);
                    }}
                  />
                </div>
              )}
              <div className={styles.mdPreview}>
                <div
                  className={styles.mdContent}
                  dangerouslySetInnerHTML={{
                    __html: micromark(mdCode || 'No description').replace(/\n/g, '<div style="height: 4px"></div>'),
                  }}
                ></div>
              </div>
            </div>
          )}
        </Panel>
      </div>
    </div>
  );
}
