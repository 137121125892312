export const CSS_DUNDAS = `html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 10px;
    border-top: none;
    touch-action: none;
    overflow: hidden;
}

#container {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    background-color: white;
}`;

export const HTML_DUNDAS = `<script 
    src="${process.env.REACT_APP_DUNDAS_INSTANCE}/Scripts/Embed/dundas.embedded.min.js?key=v202302"
    type="text/javascript">
</script>

<div
    id="container">
</div>
`;

export const JS_DUNDAS = `let promise = window.setupDundasEmbedded('${process.env.REACT_APP_DUNDAS_INSTANCE}/');
%embedComponentType%`;
