export const DASHBOARD_EVENT_LISTENERS_CODE = `

        // START: Event Listeners
        // When the event is triggered in this example, a message pop-up
        // launches.  Please read the Documentation Helper for a description
        // of other available event listeners.
        component.addEventListener("composer-dashboard-loaded", (e) => {
            alert("Dashboard has been loaded !!!");
        });
        /* END: Event Listeners */`;

export const DASHBOARD_EVENT_LISTENER_READY_CODE = `

        // START: Event Listeners
        // When the event is triggered in this example, a message pop-up
        // launches.  Please read the Documentation Helper for a description
        // of other available event listeners.
        component.addEventListener("composer-dashboard-ready", (e) => {
            alert("Dashboard is ready !!!");
        });
        /* END: Event Listeners */`;

export const DASHBOARD_EVENT_LISTENER_CHANGED_CODE = `

        // START: Event Listeners
        // When the event is triggered in this example, a message pop-up
        // launches.  Please read the Documentation Helper for a description
        // of other available event listeners.
        component.addEventListener("composer-dashboard-changed", (e) => {
            alert("Dashboard is changed !!!");
        });
        /* END: Event Listeners */`;

export const DASHBOARD_EVENT_LISTENER_DELETED_CODE = `

        // START: Event Listeners
        // When the event is triggered in this example, a message pop-up
        // launches.  Please read the Documentation Helper for a description
        // of other available event listeners.
        component.addEventListener("composer-dashboard-deleted", (e) => {
            alert("Dashboard deleted !!!");
        });
        /* END: Event Listeners */`;

export const DASHBOARD_EVENT_LISTENER_FAILED_CODE = `

        // START: Event Listeners
        // When the event is triggered in this example, a message pop-up
        // launches.  Please read the Documentation Helper for a description
        // of other available event listeners.
        component.addEventListener("composer-dashboard-failed", (e) => {
            alert("Dashboard load failed !!!");
        });
        /* END: Event Listeners */`;

export const DASHBOARD_EVENT_LISTENER_SAVE_CODE = `

        // START: Event Listeners
        // When the event is triggered in this example, a message pop-up
        // launches.  Please read the Documentation Helper for a description
        // of other available event listeners.
        component.addEventListener("composer-dashboard-saved", (e) => {
            alert("Dashboard saved !!!");
        });
        /* END: Event Listeners */`;

export const DASHBOARD_EVENT_LISTENER_DOC_FAIL_CODE = `

        // START: Event Listeners
        // When the event is triggered in this example, a message pop-up
        // launches.  Please read the Documentation Helper for a description
        // of other available event listeners.
        component.addEventListener("composer-init-failed", (e) => {
            alert("Initialization failed !!!");
        });
        /* END: Event Listeners */`;

export const DASHBOARD_EVENT_LISTENER_DOC_UNAUTHORIZED_CODE = `

        // START: Event Listeners
        // When the event is triggered in this example, a message pop-up
        // launches.  Please read the Documentation Helper for a description
        // of other available event listeners.
        component.addEventListener("composer-unauthorized", (e) => {
            alert("composer-unauthorized !!!");
        });
        /* END: Event Listeners */`;
