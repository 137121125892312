export const EMBED_COMPONENT_TYPES = {
  embedViewer: 'embed-viewer',
  metricSet: 'metric-set',
  adHocEditor: 'adhoc-editor',
  dashboard: 'dashboard',
  visualBuilder: 'visual-builder',
  dashboardBuilder: 'dashboard-builder',
  inventory: 'inventory',
  sourceEditor: 'source-editor',
};

export const NAME_OF_DEFAULT_DASHBOARD = 'Kickstarter';
export const NAME_OF_DEFAULT_FILTER_DASHBOARD = 'Kickstarter By Country';
export const DEFAULT_SOURCE = '659803cb9eb0df6d67a9c1f0';
