import React from 'react';
import { Box, IconButton, alpha, buttonClasses } from '@mui/material';
import NavigationMenu from '../NavigationMenu/NavigationMenu';
import { ArrowLeftSLineIcon, ArrowRightSLineIcon } from '../RemixIcons/generated/remixIcons';
import { useDispatch, useSelector } from 'react-redux';
import { getSidebarExpanded } from 'src/shared/store/embedSelectors';
import { setSidebarExpanded } from 'src/shared/store/embedSlice';
export const CLOSED_WIDTH = '60px';
export const EXPANDED_WIDTH = '260px';

export const ExpandButton = ({ expanded, sx, ...rest }) => {
  return (
    <IconButton
      size="small"
      sx={[
        (theme) => ({
          backgroundColor: theme.palette.common.white,
          width: '24px',
          height: '24px',
          border: '1px solid #B8C5D5',
          boxShadow: `0px 0px 4px ${alpha(theme.palette.common.black, 0.25)}`,
          color: '#546176',
          '&:hover': {
            backgroundColor: '#E8F4FC',
            color: '#007AC9',
            border: '1px solid #007AC9',
          },
          '&:active': {
            backgroundColor: '#A1D1EF',
            color: '#001737',
            border: '1px solid #007AC9',
          },
          [`&.${buttonClasses.focusVisible}`]: {
            outline: 'none',
            boxShadow: `0px 0px 0px 3.5px ${alpha('#007AC9', 0.3)}`,
            border: '1px solid #001737',
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      {expanded ? <ArrowLeftSLineIcon /> : <ArrowRightSLineIcon />}
    </IconButton>
  );
};

export const PlatformSidebar = ({ sx }) => {
  const expanded = useSelector(getSidebarExpanded);
  const dispatch = useDispatch();
  return (
    <Box
      component="nav"
      sx={[
        {
          background: 'linear-gradient(184deg, rgb(2, 168, 75), rgb(1, 104, 65))',
          width: expanded ? EXPANDED_WIDTH : CLOSED_WIDTH,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          boxSizing: 'border-box',
          transition: 'width .25s',
          position: 'relative',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <NavigationMenu expanded={expanded} />
      <ExpandButton
        expanded={expanded}
        onClick={() => dispatch(setSidebarExpanded(!expanded))}
        sx={{
          position: 'absolute',
          right: '-12px',
          top: '48px',
        }}
      />
    </Box>
  );
};
