export const COMMON_EMBED_CSS_CODE = '%cssCode%';

export const COMMON_EMBED_HTML_CODE = '%htmlCode%';

export const COMMON_EMBED_JS_CODE = '%jsCode%';

export const THEMES_CODE = `
        let getThemePromise = window?.dundas.context.getService('ThemeService').getThemeById('%embedThemeId%');
        getThemePromise.done((theme) => {
            // Apply it.
            window?.dundas.context.activeEmbeddedViewService.currentView.applyTheme(theme);
        });
`;

export const CREATE_COMPONENT_JS_CODE = `
embedManagerPromise.then(%createSecondDashboardAsync%embedManager => {
    %createSecondDashboardAwait%createEmbedComponent(embedManager, componentConfig, 'widget-holder');%createSecondDashboard%
});`;

export const CREATE_SECOND_DASHBOARD_ASYNC = 'async ';
export const CREATE_SECOND_DASHBOARD_AWAIT = 'await ';

export const EMBED_COMPONENT_CLEANUP_JS_CODE_1 = `
let componentInstanceId;
`;

export const EMBED_COMPONENT_CLEANUP_JS_CODE_2 = `
        if (componentInstanceId) {
            embedManager.removeComponent(componentInstanceId);
        }
        componentInstanceId = component.componentInstanceId;
`;
