import React, { useEffect, useState } from 'react';
import ConnectedEmbedCode from 'src/shared/components/ConnectedEmbedCode/ConnectedEmbedCode';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  resetDefaults,
  setEditorCss,
  setEditorHtml,
  setEditorJs,
  setEmbedComponentType,
  setEmbedDashboardId,
  setEmbedSettings,
  setIsCodeRun,
  setIsCodeTouched,
  synchronizePreviewSettings,
  setIsEmbedReady,
  setSourceList,
  setEmbedSourceId,
  setDashboardListComposer,
} from 'src/shared/store/embedSlice';
import { getCssCode, getHtmlCode, getJsCode } from 'src/shared/store/embedSelectors';
import AdvancedEmbed from 'src/shared/components/AdvancedEmbed/AdvancedEmbed';
import useQueryParamEmbedSettings from 'src/shared/hooks/useQueryParamEmbedSettings';
import { TABS } from 'src/shared/constants/liveCodeTabs';
import EmbedOptions from 'src/shared/components/EmbedOptions/EmbedOptions';
import EmbedPreview from 'src/shared/components/EmbedPreview/EmbedPreview';
import { getDefaultSettings } from 'src/helpers/embedOptionHelpers';
import { DEFAULT_INTERACTIVE_SETTINGS, DEFAULT_READONLY_SETTINGS } from 'src/shared/constants/defaultEmbedSettings';
import { NAME_OF_DEFAULT_DASHBOARD, DEFAULT_SOURCE } from 'src/shared/constants/embedComponentTypes';

export default function EmbedPage({ options, productSelected, defaultDashboardName }) {
  const [prevComponentType, setPrevComponentType] = useState(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedFile, setSelectedFile] = useState(TABS.html);
  const [isWarningModalShown, setIsWarningModalShown] = useState(false);
  const [navigationUrl, setNavigationUrl] = useState(null);
  const isCodeTouched = useSelector((state) => state.embed.isCodeTouched);
  const settings = useSelector((state) => state.embed.settings);
  const embedComponentType = useSelector((state) => state.embed.embedComponentType);
  const dashboardId = useSelector((state) => state.embed.dashboardId);
  const jsCode = useSelector(getJsCode);
  const htmlCode = useSelector(getHtmlCode);
  const cssCode = useSelector(getCssCode);
  const dispatch = useDispatch();
  const history = useHistory();
  const refreshSettingsAndCodeValues = (parsedSettings, embedComponentType, id) => {
    dispatch(setEmbedComponentType(embedComponentType));
    if (id) {
      dispatch(setEmbedDashboardId(id));
    }
    dispatch(setEmbedSettings(parsedSettings));
    dispatch(synchronizePreviewSettings());
  };

  const componentTypes = Object.keys(options);
  const embedOptionsProps = options[embedComponentType];
  const defaultSettings = getDefaultSettings(settings.ip, embedComponentType);
  const defaultOptions = options[embedComponentType]
    ? Object.values(options[embedComponentType])
        .reduce((res, opt) => (opt.length ? [...res, ...opt] : res), [])
        .reduce((res, { key, defaultValue }) => ({ ...res, [key]: defaultValue }), {})
    : {};

  useEffect(() => {
    const settings = { ...defaultSettings, ...defaultOptions };
    if (prevComponentType && prevComponentType !== embedComponentType) {
      refreshSettingsAndCodeValues(settings, embedComponentType);
    }
    setPrevComponentType(embedComponentType);
  }, [embedComponentType, defaultSettings, defaultOptions]);

  useEffect(() => {
    const advancedIpSettings = settings.ip === 'interactive' ? DEFAULT_INTERACTIVE_SETTINGS : DEFAULT_READONLY_SETTINGS;
    dispatch(setEmbedSettings(advancedIpSettings));
  }, [settings.ip]);

  useEffect(() => {
    dispatch(resetDefaults());
  }, []);

  useEffect(() => {
    if (productSelected === 'Composer') {
      fetch(process.env.REACT_APP_API_DASHBOARDS_URL)
        .then((res) => res.json())
        .then((data) => {
          // find dashboard for specific tab
          let defaultDashboard = data.find((d) => d.name === defaultDashboardName);
          if (!defaultDashboard) {
            // use default dashboard by name or first one
            defaultDashboard = data.find((d) => d.name === NAME_OF_DEFAULT_DASHBOARD) || (data.length ? data[0] : {});
          }
          dispatch(setDashboardListComposer(data));
          // in case of empty dashboard list pass empty string
          dispatch(setEmbedDashboardId(defaultDashboard.id || ''));
        });
    }
    dispatch(setIsEmbedReady(true));
  }, []);

  useEffect(() => {
    if (productSelected === 'Composer') {
      fetch(process.env.REACT_APP_API_SOURCES_URL)
        .then((res) => res.json())
        .then((sources) => {
          const defaultSource = sources.find((s) => s.sourceId === DEFAULT_SOURCE);
          dispatch(setSourceList(sources));
          dispatch(setEmbedSourceId(defaultSource ? defaultSource.sourceId : sources[0] ? sources[0].sourceId : ''));
        });
    }
  }, []);

  useQueryParamEmbedSettings({
    settings,
    selectedTabIndex,
    selectedFile,
    embedComponentType,
    dashboardId,
    setSelectedTabIndex,
    setSelectedFile,
    refreshSettingsAndCodeValues,
    defaultSettings,
    embedComponentTypeOptions: Object.keys(options),
  });

  return embedOptionsProps ? (
    <AdvancedEmbed
      onModalConfirm={() => {
        dispatch(setIsCodeTouched(false));
        dispatch(setIsCodeRun(false));
        setIsWarningModalShown(false);
        if (navigationUrl) {
          history.push(navigationUrl);
        } else {
          setSelectedTabIndex(0);
        }
      }}
      onModalCancel={() => setIsWarningModalShown(false)}
      onTabSelect={(i) => {
        if (i === 0 && isCodeTouched) {
          setIsWarningModalShown(true);
        } else {
          dispatch(setEditorHtml(htmlCode));
          dispatch(setEditorJs(jsCode));
          dispatch(setEditorCss(cssCode));
          setSelectedTabIndex(i);
        }
      }}
      isWarningModalShown={isWarningModalShown}
      selectedTabIndex={selectedTabIndex}
      advancedEmbedOptions={
        <EmbedOptions
          {...embedOptionsProps}
          defaultOptions={defaultOptions}
          componentTypes={componentTypes}
          productSelected={productSelected}
        ></EmbedOptions>
      }
      advancedEmbedCode={
        <ConnectedEmbedCode setSelectedFile={setSelectedFile} selectedFile={selectedFile}></ConnectedEmbedCode>
      }
      advancedEmbedDashboardPreview={<EmbedPreview></EmbedPreview>}
      showWarningModal={() => setIsWarningModalShown(true)}
      isCodeTouched={isCodeTouched}
      resetDefaults={() => dispatch(resetDefaults())}
      setNavigationUrl={setNavigationUrl}
      navigationUrl={navigationUrl}
      componentType={embedComponentType}
      productSelected={productSelected}
    ></AdvancedEmbed>
  ) : (
    <></>
  );
}
