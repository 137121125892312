import React from 'react';
import styles from './SocialShare.module.scss';
import ShareImage from './images/share.svg';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
} from 'react-share';

export default function SocialShare() {
  const url = 'https://insightsoftware.com/logi-symphony/';

  return (
    <div className={styles.root}>
      <h2 className={styles.title}>Know someone who would love Logi Symphony?</h2>
      <div className={styles.contentHolder}>
        <div className={styles.contentLeft}>
          <p className={styles.text}>Tell them or share on social media!</p>
          <div className={styles.shareButtonsContainer}>
            <button className={styles.copyLinkBtton} onClick={() => navigator.clipboard.writeText(url)}>
              Copy link
            </button>
            <EmailShareButton url={url}>
              <EmailIcon size={32} round />
            </EmailShareButton>
            <FacebookShareButton url={url}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <LinkedinShareButton url={url}>
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            <TwitterShareButton url={url}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
          </div>
        </div>
        <div className={styles.contentRight}>
          <img src={ShareImage} className={styles.shareImg}></img>
        </div>
      </div>
    </div>
  );
}
