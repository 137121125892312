import { createSlice } from '@reduxjs/toolkit';
import { setAccessToken, setRefreshToken } from 'src/helpers/auth';
import { getUser, signIn } from './thunks';

const initialState = {
  user: null,
  isLoading: false,
  isFailed: false,
};

export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsLoading(state, { payload }) {
      state.isLoading = payload;
    },
    setIsFailed(state, { payload }) {
      state.isFailed = payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(signIn.fulfilled, (state, { payload }) => {
        state.user = payload;
        setAccessToken(payload.accessToken);
        setRefreshToken(payload.refreshToken);
        state.isLoading = false;
      })
      .addCase(signIn.rejected, (state) => {
        state.isLoading = false;
        state.isFailed = true;
      })
      .addCase(getUser.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.isLoading = false;
      })
      .addCase(getUser.rejected, (state) => {
        state.isLoading = false;
      }),
});

export const { setIsLoading, setIsFailed } = slice.actions;

export default slice.reducer;
