import {
  DEFAULT_INTERACTIVE_SETTINGS,
  DEFAULT_READONLY_SETTINGS,
  DEFAULT_SETTINGS,
} from 'src/shared/constants/defaultEmbedSettings';

export const getDefaultSettings = (interactivityProfileName, embedComponentType) =>
  interactivityProfileName !== 'interactive' && embedComponentType === 'dashboard'
    ? { ...DEFAULT_SETTINGS, ...DEFAULT_READONLY_SETTINGS }
    : { ...DEFAULT_SETTINGS, ...DEFAULT_INTERACTIVE_SETTINGS };
