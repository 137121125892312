import React from 'react';
import EmbedPage from 'src/shared/components/EmbedPage/EmbedPage';
import { OPTIONS } from './constants/uxInteractivityOptions';
import { useSelector } from 'react-redux';

export default function UxInteractivity({ productSelected }) {
  const settings = useSelector((state) => state.embed.settings);
  const selectedInteractivityOptions =
    settings.inventoryItem === 'dashboard'
      ? { 'Dashboard Library': OPTIONS.inventory.interactivityOptions?.DashboardLibrary }
      : settings.inventoryItem === 'visual'
        ? { 'Visual Library': OPTIONS.inventory.interactivityOptions?.VisualLibrary }
        : settings.inventoryItem === 'source'
          ? { 'Source Library': OPTIONS.inventory.interactivityOptions?.SourceLibrary }
          : OPTIONS.inventory.interactivityOptions;

  return <EmbedPage options={{ ...OPTIONS, inventory: { ...OPTIONS.inventory, interactivityOptions: selectedInteractivityOptions } }} productSelected={productSelected}></EmbedPage>;
}
