const DOCUMENTATION_PATH = `${window.location.origin}/documentation/content`;

export const DOCUMENTATION_LINKS = {
  PLAYGROUND_PUBLISH: `${DOCUMENTATION_PATH}/topics/pubsub/playground-publish.html`,
  PLAYGROUND_SUBSCRIBE: `${DOCUMENTATION_PATH}/topics/pubsub/playground-subscribe.html`,
  EMBED_JS_EMBEDMANAGER_PROP_DASH: `${DOCUMENTATION_PATH}/topics/embed/embed-javascript-embedmanager-prop-dash.html`,
  EMBED_JS_EMBEDMANAGER_PROP_INVENTORY: `${DOCUMENTATION_PATH}/topics/embed/embed-javascript-embedmanager-prop-lib.html`,
  EMBED_JS_EXAMPLE_VB: `${DOCUMENTATION_PATH}/topics/embed/embed-javascript-embedmanager-prop-vb.html`,
  EMBED_JS_EVENTLISTENERS: `${DOCUMENTATION_PATH}/topics/embed/embed-javascript-eventlisteners.html`,
  EMBED_JS_EVENTLISTENERS_DASHBOARD_BUILDER: `${DOCUMENTATION_PATH}/topics/embed/embed-javascript-eventlisteners-dash.html`,
  EMBED_JS_EMBEDMANAGER_PROP_SOURCE_EDITOR: `${DOCUMENTATION_PATH}/topics/embed/embed-javascript-source-editor-prop.html`,
  EMBED_JS_EVENTLISTENERS_INVENTORY: `${DOCUMENTATION_PATH}/topics/embed/embed-javascript-eventlisteners-lib.html`,
  EMBED_JS_EVENTLISTENERS_VISUALS: `${DOCUMENTATION_PATH}/topics/embed/embed-javascript-eventlisteners-vis.html`,
  EMBED_JS_MODES: `${DOCUMENTATION_PATH}/topics/embed/embed-javascript-modes.html`,
  EMBED_JS_EMBEDMANAGER_INTERACTIVITY_PROP: `${DOCUMENTATION_PATH}/topics/embed/embed-javascript-embedmanager-interactivityprop.html`,
  EMBED_JS_EMBEDMANAGER_INTERACTIVITY_PROP_DASH: `${DOCUMENTATION_PATH}/topics/embed/embed-javascript-embedmanager-interactivityprop-dash.html`,
  EMBED_JS_EMBEDMANAGER_INTERACTIVITY_PROP_VIS: `${DOCUMENTATION_PATH}/topics/embed/embed-javascript-embedmanager-interactivityprop-vis.html`,
  EMBED_JS_EMBEDMANAGER_MULTIPLE_DASHBOARDS: `${DOCUMENTATION_PATH}/topics/embed/embed-multiple-dashboards.html`,
  THEMES: `${DOCUMENTATION_PATH}/topics/themes/themes-create.html`,
  BREADCRUMBS: `${DOCUMENTATION_PATH}/topics/embed/embed-javascript-embedmanager-prop-vb-breadcrumbs.html`,
  DEFAULT_SOURCE: `${DOCUMENTATION_PATH}/topics/embed/embed-default-source-vis.html`,
  LIST_VISUALS: `${DOCUMENTATION_PATH}/topics/embed/embed-list-visuals.html`,
  LIST_DASHBOARDS: `${DOCUMENTATION_PATH}/topics/embed/embed-list-dashboards.html`,
  // Dundas Content
  VIEW_TYPE: `${DOCUMENTATION_PATH}/topics/dundas-playground/sym-embed-select-view-type.htm`,
  THEMES_DUNDAS: `${DOCUMENTATION_PATH}/topics/themes/sym-themes-ov.htm`,
  AD_HOC_EDITOR: `${DOCUMENTATION_PATH}/topics/sym-embed-playground/sym-embed-javascript-prop-ad-hoc.htm`,
  EMBED_OPTIONS: `${DOCUMENTATION_PATH}/topics/sym-embed-playground/sym-pg-embed-javascript-embed-options.html`,
  EMBED_VIEWER: `${DOCUMENTATION_PATH}/topics/sym-embed-playground/sym-embed-javascript-embedmanager-prop-viewer.html`,
  METRIC_SET: `${DOCUMENTATION_PATH}/topics/sym-embed-playground/sym-embed-javascript-embedmanager-prop-metric-set.htm`,
};

export const DOCUMENTATION_ITEMS = {
  EmbedOptions: {
    name: 'Embed Options',
    content: (
      <>
        Use available methods to embed views such as dashboards and reports, a simplified dashboard creation experience,
        and move using the JavaScript API.
      </>
    ),
    link: DOCUMENTATION_LINKS.EMBED_OPTIONS,
  },
  embedViewerEmbedOptions: {
    name: 'Embed Options',
    content: (
      <>
        Use available methods to embed views such as dashboards and reports, a simplified dashboard creation experience,
        and move using the JavaScript API.
      </>
    ),
    link: DOCUMENTATION_LINKS.EMBED_VIEWER,
  },
  metricSetEmbedOptions: {
    name: 'Embed Options',
    content: (
      <>
        Use available methods to embed views such as dashboards and reports, a simplified dashboard creation experience,
        and move using the JavaScript API.
      </>
    ),
    link: DOCUMENTATION_LINKS.METRIC_SET,
  },
  adHocEditorEmbedOptions: {
    name: 'Embed Options',
    content: (
      <>
        Use available methods to embed views such as dashboards and reports, a simplified dashboard creation experience,
        and move using the JavaScript API.
      </>
    ),
    link: DOCUMENTATION_LINKS.AD_HOC_EDITOR,
  },
  ViewType: {
    name: 'View Type',
    content: <>Select a content type to embed, such as viewer, metric set, or ad hoc dashboard.</>,
    link: DOCUMENTATION_LINKS.VIEW_TYPE,
  },
  Theme: {
    name: 'Select Theme',
    content: (
      <>Themes are collections of existing styles you can apply all at once to a view such as a dashboard or report.</>
    ),
    link: DOCUMENTATION_LINKS.THEMES_DUNDAS,
  },
  Dashboard: {
    name: 'Select Dashboard',
    content: <>Select an available dashboard to use. Limited to the dashboards you can access.</>,
  },
  Report: {
    name: 'Select Report',
    content: <>Select an available report to use. Limited to the reports you can access.</>,
  },
  Scorecard: {
    name: 'Select Scorecard',
    content: <>Select an available scorecard to use. Limited to the scorecards you can access.</>,
  },
  MetricSet: {
    name: 'Select Metric Set',
    content: <>Select an available metric set to use. Limited to the metric sets you can access.</>,
  },
  toolbarHidden: {
    name: 'Hide Toolbar',
    content: <>Enable to hide the toolbar in the ad-hoc editor and all options available in the toolbar.</>,
    link: DOCUMENTATION_LINKS.AD_HOC_EDITOR,
  },
  toolbarAddMetricsSetHidden: {
    name: 'Hide Add MetricSet from Toolbar',
    content: <>Enable to hide Add Metric Set option in the ad-hoc editor toolbar.</>,
    link: DOCUMENTATION_LINKS.AD_HOC_EDITOR,
  },
  toolbarAddDashboardHidden: {
    name: 'Hide Add Dashboard from Toolbar',
    content: <>Enable to hide the Add Dashboards option in the ad-hoc editor toolbar.</>,
    link: DOCUMENTATION_LINKS.AD_HOC_EDITOR,
  },
  toolbarAddReportHidden: {
    name: 'Hide Add Report from Toolbar',
    content: <>Enable to hide the Add Report icon in the ad-hoc editor toolbar.</>,
    link: DOCUMENTATION_LINKS.AD_HOC_EDITOR,
  },
  dockablesHidden: {
    name: 'Hide Dockables',
    content: <>to be honest we do not know what it does 🙂</>,
    link: DOCUMENTATION_LINKS.AD_HOC_EDITOR,
  },
  openExistingHidden: {
    name: 'Hide OpenExisting',
    content: <>Enable to hide the Add Existing Dashboards option in the ad-hoc editor.</>,
    link: DOCUMENTATION_LINKS.AD_HOC_EDITOR,
  },
  dashboardEmbedOptions: {
    name: 'Embed Options',
    content: (
      <>
        Properties that allow you to control how the Inventory is embedded can be passed as parameters to the&nbsp;
        <code>createComponent</code>&nbsp;method during the dashboard embed processing.
      </>
    ),
    link: DOCUMENTATION_LINKS.EMBED_JS_EMBEDMANAGER_PROP_DASH,
  },
  inventoryEmbedOptions: {
    name: 'Embed Options',
    content: (
      <>
        Properties that allow you to control how the Inventory is embedded can be passed as parameters to the{' '}
        <code>createComponent</code> method during the dashboard embed processing.
      </>
    ),
    link: DOCUMENTATION_LINKS.EMBED_JS_EMBEDMANAGER_PROP_INVENTORY,
  },
  inventoryItem: {
    name: 'Inventory item',
    content: <>Select a type of Inventory item to manage.</>,
    link: DOCUMENTATION_LINKS.EMBED_JS_EMBEDMANAGER_PROP_INVENTORY,
  },
  customizeActionButtons: {
    name: 'Сustomize Action Buttons',
  },
  customizeSearchControllers: {
    name: 'Сustomize Search Controllers',
  },
  customizeFilterButtons: {
    name: 'Сustomize Filter Buttons',
  },
  customizeInventoryTable: {
    name: 'Сustomize Inventory Table',
  },
  visualEmbedOptions: {
    name: 'Embed Options',
    content: (
      <>
        Properties that allow you to control how a visual is embedded can be passed as parameters to the&nbsp;
        <code>createComponent</code>&nbsp;method during the visual embed processing.
      </>
    ),
    link: DOCUMENTATION_LINKS.EMBED_JS_EXAMPLE_VB,
  },
  dashboardIpOverrides: {
    name: 'Interactivity Profile Overrides',
    content: (
      <>
        You can add interactivity override settings to your embed script to provide granular control over what your
        users can do with an embedded Composer dashboard. The settings property with parameters can be passed in
        the&nbsp;
        <code>interactivityOverridesobject</code> of the <code>createComponent</code>&nbsp;method.
      </>
    ),
    link: DOCUMENTATION_LINKS.EMBED_JS_EMBEDMANAGER_INTERACTIVITY_PROP,
  },
  dashboardBuilderIpOverrides: {
    name: 'Interactivity Profile Overrides',
    content: (
      <>
        You can add interactivity override settings to your embed script to provide granular control over what your
        users can do with an embedded Composer dashboard. The settings property with parameters can be passed in
        the&nbsp;
        <code>interactivityOverridesobject</code> of the <code>createComponent</code>&nbsp;method.
      </>
    ),
    link: DOCUMENTATION_LINKS.EMBED_JS_EMBEDMANAGER_INTERACTIVITY_PROP_DASH,
  },
  visualIpOverrides: {
    name: 'Interactivity profile overrides',
    content: (
      <>
        You can add interactivity override settings to your embed script to provide granular control over what your
        users can do with an embedded Composer visual. The visualSettings property with parameters can be passed in
        the&nbsp;
        <code>interactivityOverridesobject</code> of the <code>createComponent</code>&nbsp;method.
      </>
    ),
    link: DOCUMENTATION_LINKS.EMBED_JS_EMBEDMANAGER_INTERACTIVITY_PROP_VIS,
  },
  inventoryIpOverrides: {
    name: 'Interactivity profile overrides',
    content: (
      <>
        You can add interactivity override settings to your embed script to provide granular control over what your
        users can do with an embedded Composer inventory.
        <code>interactivityOverridesobject</code> of the <code>createComponent</code> method.
      </>
    ),
  },
  ip: {
    name: 'Interactivity Profile',
    content: (
      <>
        Interactivity profile embed modes determine the way in which your users can work with an embedded dashboard.
        Valid values are <code>readOnly</code> or <code>interactive</code>. If you do not want the user of your
        application to change anything and only be able to view the dashboard, select <code>readOnly</code> (the
        default). If you want your users to be able to make changes to the dashboard, select <code>interactive</code>.
      </>
    ),
    link: DOCUMENTATION_LINKS.EMBED_JS_MODES,
  },
  dashboardEventListeners: {
    name: 'Event Listeners',
    content: (
      <>
        Events can be used in your JavaScript to control the behavior of an embedded Composer dashboard and its visuals
        when specific events occur. The ability for your end-users to perform some of the events is controlled by the
        permissions granted to them with their Composer credentials.
      </>
    ),
    link: DOCUMENTATION_LINKS.EMBED_JS_EVENTLISTENERS,
  },
  dashboardReadyEventListener: {
    name: 'Ready Event Listener',
  },
  dashboardChangedEventListener: {
    name: 'Change Event Listener',
  },
  dashboardDeleteEventListener: {
    name: 'Delete Event Listener',
  },
  dashboardLoadFailEventListener: {
    name: 'Load Fail Event Listener',
  },
  dashboardSaveEventListener: {
    name: 'Save Event Listener',
  },
  dashboardVisualAddEventListener: {
    name: 'Add Visual Event Listener',
  },
  dashboardVisualRemoveEventListener: {
    name: 'Remove Visual Event Listener',
  },
  visualLoadEventListener: {
    name: 'Load Event Listener',
  },
  visualLoadFailEventListener: {
    name: 'Load Fail Event Listener',
  },
  visualRenderEventListener: {
    name: 'Render Event Listener',
  },
  visualSaveEventListener: {
    name: 'Save Event Listener',
  },
  dashboardBuilderVisualLoadEventListener: {
    name: 'Load Event Listener',
  },
  dashboardBuilderVisualLoadFailEventListener: {
    name: 'Load Fail Event Listener',
  },
  dashboardBuilderVisualRenderEventListener: {
    name: 'Render Event Listener',
  },
  dashboardBuilderVisualSaveEventListener: {
    name: 'Save Event Listener',
  },
  documentFailed: {
    name: 'Initialization Fail Event Listener',
  },
  dashboardBuilderDocumentFailed: {
    name: 'Initialization Fail Event Listener',
  },
  visualBuilderChange: {
    name: 'Change Event Listener',
  },
  visualBuilderFail: {
    name: 'Fail Event Listener',
  },
  visualBuilderLoad: {
    name: 'Load Event Listener',
  },
  visualBuilderReady: {
    name: 'Ready Event Listener',
  },
  dbVisualBuilderChange: {
    name: 'Change Event Listener',
  },
  dbVisualBuilderFail: {
    name: 'Fail Event Listener',
  },
  dbVisualBuilderLoad: {
    name: 'Load Event Listener',
  },
  dbVisualBuilderReady: {
    name: 'Ready Event Listener',
  },
  unauthorized: {
    name: 'Unauthorized Event Listener',
  },
  dashboardBuilderUnauthorized: {
    name: 'Unauthorized Event Listener',
  },
  dashboardBuilderReadyEventListener: {
    name: 'Ready Event Listener',
  },
  dashboardBuilderChangedEventListener: {
    name: 'Change Event Listener',
  },
  dashboardBuilderDeleteEventListener: {
    name: 'Delete Event Listener',
  },
  dashboardBuilderLoadFailEventListener: {
    name: 'Load Fail Event Listener',
  },
  dashboardBuilderSaveEventListener: {
    name: 'Save Event Listener',
  },
  dashboardBuilderVisualAddEventListener: {
    name: 'Add Visual Event Listener',
  },
  dashboardBuilderVisualRemoveEventListener: {
    name: 'Remove Visual Event Listener',
  },
  inventoryEventListeners: {
    name: 'Event Listeners',
    content: (
      <>
        Events can be used in your JavaScript to control the behavior of an embedded Inventory and its visuals when
        specific events occur. The ability for your end-users to perform some of the events is controlled by the
        permissions granted to them with their Composer credentials.
      </>
    ),
    link: DOCUMENTATION_LINKS.EMBED_JS_EVENTLISTENERS_INVENTORY,
  },
  inventoryReadyEventListener: {
    name: 'Ready Event Listener',
  },
  inventoryFailEventListener: {
    name: 'Fail Event Listener',
  },
  inventoryDeleteEventListener: {
    name: 'Delete Event Listener',
  },
  dashboardBuilderEventListeners: {
    name: 'Event Listeners',
    content: (
      <>
        Events can be used in your JavaScript to control the behavior of an embedded Composer dashboard when specific
        events occur. The ability for your end-users to perform some of the events is controlled by the permissions
        granted to them with their Composer credentials.
      </>
    ),
    link: DOCUMENTATION_LINKS.EMBED_JS_EVENTLISTENERS_DASHBOARD_BUILDER,
  },
  visualEventListener: {
    name: 'Event Listener',
    content: (
      <>
        Events can be used in your JavaScript to control the behavior of an embedded Composer visual when specific
        events occur. The ability for your end-users to perform some of the events is controlled by the permissions
        granted to them with their Composer credentials.
      </>
    ),
    link: DOCUMENTATION_LINKS.EMBED_JS_EVENTLISTENERS_VISUALS,
  },
  publish: {
    name: 'Publish Cross-Visual Filters',
    content: (
      <>
        Events can be used in your JavaScript to control the behavior of an embedded Composer dashboard when specific
        events occur. The ability for your end-users to perform some of the events is controlled by the permissions
        granted to them with their Composer credentials.
      </>
    ),
    link: DOCUMENTATION_LINKS.PLAYGROUND_PUBLISH,
  },
  subscribe: {
    name: 'Subscribe to Cross-Visual Filters',
    content: (
      <>
        Use JavaScript methods and properties to subscribe a visual in an embedded dashboard to the cross-visual filters
        available for the dashboard.
      </>
    ),
    link: DOCUMENTATION_LINKS.PLAYGROUND_SUBSCRIBE,
  },
  dashboardEditorPlacement: {
    name: 'Editor Placement',
    content: (
      <>
        The enhanced visual editor can be positioned on your embedded visuals. Select whether the editor should appear
        on the right side of the dashboard (option <b>Dock right</b>) or as a pop-up on top of the dashboard (option{' '}
        <b>Modal</b>).
      </>
    ),
    link: DOCUMENTATION_LINKS.EMBED_JS_EMBEDMANAGER_PROP_DASH,
  },
  visualEditorPlacement: {
    name: 'Editor Placement',
    content: (
      <>
        The enhanced visual editor can be positioned on your embedded visuals. Select whether the editor should appear
        on the right side of the visual (option <b>Dock right</b>) or as a pop-up on top of the visual (option{' '}
        <b>Modal</b>).
      </>
    ),
    link: DOCUMENTATION_LINKS.EMBED_JS_EMBEDMANAGER_PROP_DASH,
  },
  themes: {
    name: 'Themes',
    content: (
      <>
        Composer supplies two themes: modern (default, light colors) and dark. You can create and activate a different
        theme as needed. At this time, only colors can be changed for Composer themes.
      </>
    ),
    link: DOCUMENTATION_LINKS.THEMES,
  },
  dashboardShowHeader: {
    name: 'Show Header',
    content: (
      <>
        Indicate whether the dashboard header should be shown when the dashboard is embedded. The dashboard header
        includes the title and actions bar for the dashboard.
      </>
    ),
  },
  visualShowHeader: {
    name: 'Show Header',
    content: (
      <>
        Indicate whether the visual header should be shown when the visual is embedded. The visual header includes the
        title and actions bar for the visual.
      </>
    ),
  },
  dashboardShowActions: {
    name: 'Show Actions',
    content: (
      <>
        Indicate whether the dashboard action bar should be available for use when the dashboard is embedded. The
        dashboard action bar allows users, based on their dashboard permissions, to alter or export the dashboard.
      </>
    ),
  },
  visualShowActions: {
    name: 'Show Actions',
    content: (
      <>
        Indicate whether the visual action bar should be available for use when the visual is embedded. The visual
        action bar allows users, based on their visual permissions, to alter or export the visual.
      </>
    ),
  },
  dashboardShowTitle: {
    name: 'Show Title',
    content: <>Indicate whether the dashboard title should be visible when the dashboard is embedded.</>,
  },
  dashboardLite: {
    name: 'Embed In Lite Mode',
    content: <>Switch to Lite dashboard Mode.</>,
  },
  doubleDashboards: {
    name: 'Embed Multiple Dashboards',
    content: <>Composer enables you to add multiple dashboards to better represent your data.</>,
    link: DOCUMENTATION_LINKS.EMBED_JS_EMBEDMANAGER_MULTIPLE_DASHBOARDS,
  },
  visualShowTitle: {
    name: 'Show Title',
    content: <>Indicate whether the visual title should be visible when the visual is embedded.</>,
  },
  listVisuals: {
    name: 'List Visuals',
    content: (
      <>
        You can use the Composer API to list the available visuals in the Composer environment. After rendering the
        visual list, you can select one and pass its visual ID to Composer to open the selected visual. You can then
        alter the selected visual as necessary for your custom solution.
      </>
    ),
    link: DOCUMENTATION_LINKS.LIST_VISUALS,
  },
  listDashboards: {
    name: 'List Dashboards',
    content: (
      <>
        You can use the Composer API to list the available dashboards in the Composer environment. After rendering the
        dashboard list, you can select one and pass its dashboard ID to Composer to open the selected dashboard. You can
        then alter the selected dashboard as necessary for your custom solution.
      </>
    ),
    link: DOCUMENTATION_LINKS.LIST_DASHBOARDS,
  },
  embedSource: {
    name: 'Embed Default Data Source and Visual Style',
    content: (
      <>
        Indicate whether a visual should be embedded using the defaults for a supported visual style in the data source.
        You must specify the ID of the default settings for a visual style.
      </>
    ),
    link: DOCUMENTATION_LINKS.DEFAULT_SOURCE,
  },
  breadcrumbs: {
    name: 'Show Breadcrumbs',
    content: (
      <>
        Optionally, you can pass breadcrumb properties as parameters to the <code>createComponent</code>&nbsp;method
        when embedding visuals.
      </>
    ),
    link: DOCUMENTATION_LINKS.BREADCRUMBS,
  },

  refresh: {
    name: 'Refresh',
  },
  changeLayout: {
    name: 'Change Layout',
  },
  rename: {
    name: 'Rename',
  },
  shareFilterSets: {
    name: 'Share Filter Sets',
  },
  dashboardInteractions: {
    name: 'Dashboard Interactions',
  },
  addToFavourites: {
    name: 'Add to Favorites',
  },
  exportPngPdf: {
    name: 'Export PNG/PDF',
  },
  exportConfiguration: {
    name: 'Export Configuration',
  },
  dashboardLinks: {
    name: 'Dashboard Links',
  },
  addVisuals: {
    name: 'Add Visuals',
  },
  delete: {
    name: 'Delete',
  },
  save: {
    name: 'Save',
  },
  filter: {
    name: 'Filter',
  },
  actionsAction: {
    name: 'Actions Action',
  },
  rulers: {
    name: 'Rulers',
  },
  zoomAction: {
    name: 'Zoom Action',
  },
  filterAction: {
    name: 'Filter Action',
  },
  sourceEditorEmbedOptions: {
    name: 'Embed Options',
    content: (
      <>
        Properties that allow you to control how the source editor is embedded can be passed as parameters to the{' '}
        <code>createComponent</code> method during the source editor embed processing.
      </>
    ),
    link: DOCUMENTATION_LINKS.EMBED_JS_EMBEDMANAGER_PROP_SOURCE_EDITOR,
  },
  sourceEditorShowHeader: {
    name: 'Show Header',
    content: (
      <>
        Indicate whether the Source Editor header should be shown when the source editor is embedded. The source header
        includes the title for the Source editor.
      </>
    ),
  },
  sourceEditorShowTitle: {
    name: 'Show Title',
    content: <>Indicate whether the source editor title should be visible when the source editor is embedded.</>,
  },
  sourceEditorActiveTab: {
    name: 'Active Tab',
    content: (
      <>
        The Active Tab presented to the user when the editor is loaded. Options include 'create', 'fields', 'caching',
        and 'settings'.
      </>
    ),
  },
  showCreateTab: {
    name: 'Show Source Creation Tab',
    content: <>Define visibility of the Source Creation tab.</>,
  },
  showFieldsTab: {
    name: 'Show Fields Tab',
    content: <>Define visibility of the Fields tab.</>,
  },
  showCacheTab: {
    name: 'Show Cache Tab',
    content: <>Define visibility of the Cache tab.</>,
  },
  showSettingsTab: {
    name: 'Show Global Settings Tab',
    content: <>Define visibility of the Global Settings tab.</>,
  },
  sourceEditorIpOverrides: {
    name: 'Interactivity Profile Overrides',
    content: (
      <>
        You can add interactivity override settings to your embed script to provide granular control over what your
        users can do with an embedded Composer Source editor. The settings property with parameters can be passed in
        the&nbsp;
        <code>interactivityOverridesobject</code> of the <code>createComponent</code>&nbsp;method.
      </>
    ),
    link: DOCUMENTATION_LINKS.EMBED_JS_EMBEDMANAGER_INTERACTIVITY_PROP,
  },
  sourceEditorEventListeners: {
    name: 'Event Listeners',
    content: (
      <>
        Events can be used in your JavaScript to control the behavior of an embedded source editor when specific events
        occur. The ability for your end-users to perform some of the events is controlled by the permissions granted to
        them with their Composer credentials.
      </>
    ),
    link: DOCUMENTATION_LINKS.EMBED_JS_EVENTLISTENERS_INVENTORY,
  },
  addFromConnection: {
    name: 'Add From Connection',
  },
  addFromFileUpload: {
    name: 'Add From File Upload',
  },
  selectFileUpload: {
    name: 'Select File Upload',
  },
  uploadnewFile: {
    name: 'Upload New File',
  },
  createJoins: {
    name: 'Create Joins',
  },
  filterValuesEntities: {
    name: 'Filter Values Entities',
  },
  exportSource: {
    name: 'Export Source',
  },
  copySource: {
    name: 'Copy Source',
  },
  addDerivedField: {
    name: 'Add Derived Field',
  },
  addHierarchyField: {
    name: 'Add Hierarchy Field',
  },
  addCustomMetric: {
    name: 'Add Custom Metric',
  },
  visibility: {
    name: 'Visibility',
  },
  filterValueOverrides: {
    name: 'Filter Value Overrides',
  },
  fieldCapabilities: {
    name: 'Field Capabilities',
  },
  manageTranslations: {
    name: 'Manage Translations',
  },
  setUniqueKey: {
    name: 'Set Unique Key',
  },
  dataCache: {
    name: 'Data Cache',
  },
  statisticsCache: {
    name: 'Statistics Cache',
  },
  scheduleRefresh: {
    name: 'Schedule Refresh',
  },
  fieldSettings: {
    name: 'Fields Settings',
  },
};

export const EMBEDDED_DASHBOARD_DOCUMENTATION_POPUP_ITEMS = [
  DOCUMENTATION_ITEMS.dashboardEmbedOptions,
  DOCUMENTATION_ITEMS.dashboardIpOverrides,
  DOCUMENTATION_ITEMS.ip,
  DOCUMENTATION_ITEMS.dashboardEventListeners,
  DOCUMENTATION_ITEMS.publish,
  DOCUMENTATION_ITEMS.subscribe,
  DOCUMENTATION_ITEMS.doubleDashboards,
  DOCUMENTATION_ITEMS.themes,
];

export const VISUAL_AUTHORING_DOCUMENTATION_POPUP_ITEMS = [
  DOCUMENTATION_ITEMS.visualEmbedOptions,
  DOCUMENTATION_ITEMS.visualIpOverrides,
  DOCUMENTATION_ITEMS.embedSource,
  DOCUMENTATION_ITEMS.visualEventListener,
  DOCUMENTATION_ITEMS.breadcrumbs,
  DOCUMENTATION_ITEMS.themes,
  DOCUMENTATION_ITEMS.listVisuals,
];

export const DASHBOARD_AUTHORING_DOCUMENTATION_POPUP_ITEMS = [
  DOCUMENTATION_ITEMS.dashboardEmbedOptions,
  DOCUMENTATION_ITEMS.dashboardBuilderIpOverrides,
  DOCUMENTATION_ITEMS.dashboardEventListeners,
  DOCUMENTATION_ITEMS.themes,
  DOCUMENTATION_ITEMS.listDashboards,
];

export const INVENTORY_DOCUMENTATION_POPUP_ITEMS = [
  DOCUMENTATION_ITEMS.inventoryEmbedOptions,
  DOCUMENTATION_ITEMS.inventoryEventListeners,
];

export const SOURCE_EDITOR_DOCUMENTATION_POPUP_ITEMS = [
  DOCUMENTATION_ITEMS.sourceEditorEmbedOptions,
  DOCUMENTATION_ITEMS.sourceEditorIpOverrides,
  DOCUMENTATION_ITEMS.themes,
  DOCUMENTATION_ITEMS.sourceEditorEventListeners,
];

export const EMBED_VIEWER_POPUP_ITEMS = [DOCUMENTATION_ITEMS.embedViewerEmbedOptions, DOCUMENTATION_ITEMS.Theme];

export const METRIC_SET_POPUP_ITEMS = [DOCUMENTATION_ITEMS.metricSetEmbedOptions];

export const AD_HOC_EDITOR_POPUP_ITEMS = [DOCUMENTATION_ITEMS.adHocEditorEmbedOptions, DOCUMENTATION_ITEMS.Theme];
