export const ADHOC_EDITOR_CODE = `promise.then(() => {
    // Do our work here.
    window.dundas.context.createAdhocDashboard(document.getElementById("container"), {
        "sessionId":'%sessionId%',
        "isToolbarHidden":%toolbarHidden%,
        "isToolbarAddMetricSetHidden": %toolbarAddMetricsSetHidden%,
        "isToolbarAddDashboardHidden": %toolbarAddDashboardHidden%,
        "isToolbarAddReportHidden": %toolbarAddReportHidden%,
        "isDockablesHidden ": %dockablesHidden%,
        "isOpenExistingHidden": %openExistingHidden%,
    })
    .done(() => {
        window.dundas.context.activeEmbeddedViewService.subscribe(dundas.EmbeddedViewServiceConstants.AdhocDashboardCreated + " " + dundas.EmbeddedViewServiceConstants.AdhocDashboardOpened + " " + dundas.EmbeddedViewServiceConstants.MetricSetAddedToAdhocDashboard + " " + dundas.EmbeddedViewServiceConstants.ViewAddedToAdhocDashboard, () => {
            %themesCode%
        });
    })

});`;
