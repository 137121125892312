export const INVENTORY_EVENT_LISTENERS_CODE = `

        // START: Event Listeners
        // When the event is triggered in this example, a message pop-up
        // launches.  Please read the Documentation Helper for a description
        // of other available event listeners.
        component.addEventListener("composer-inventory-loaded", (e) => {
            alert("Inventory has been loaded !!!");
        });
        /* END: Event Listeners */`;

export const INVENTORY_EVENT_LISTENERS_READY_CODE = `

        // START: Event Listeners
        // When the event is triggered in this example, a message pop-up
        // launches.  Please read the Documentation Helper for a description
        // of other available event listeners.
        component.addEventListener("composer-inventory-ready", (e) => {
            alert("Inventory is ready !!!");
        });
        /* END: Event Listeners */`;

export const INVENTORY_EVENT_LISTENERS_FAIL_CODE = `

        // START: Event Listeners
        // When the event is triggered in this example, a message pop-up
        // launches.  Please read the Documentation Helper for a description
        // of other available event listeners.
        component.addEventListener("composer-inventory-failed", (e) => {
            alert("Inventory failed !!!");
        });
        /* END: Event Listeners */`;

export const INVENTORY_EVENT_LISTENERS_DELETE_CODE = `

        // START: Event Listeners
        // When the event is triggered in this example, a message pop-up
        // launches.  Please read the Documentation Helper for a description
        // of other available event listeners.
        component.addEventListener("composer-inventory-item-deleted", (e) => {
            alert("Inventory deleted !!!");
        });
        /* END: Event Listeners */`;
