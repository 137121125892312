export const VISUAL_LISTING_HTML_CODE = `

    <!-- START: List visuals code -->
    <div class="options-container">
        <div class="header">
            <div class="header-col">Type</div>
            <div>Name</div>
        </div>
    </div>
    <!-- END: List visuals code -->
    `;

export const VISUAL_LISTING_JS_CODE = `

let initialVisualId = '';

const replaceComponent = (visualId, e) => {
    embedManagerPromise.then(embedManager => {
        const options = Array.from(document.getElementsByClassName('option'));
        if (e) {
            options.forEach(o => {
                o.classList.remove('selected');
                o.removeAttribute('disabled');
            });
            e.currentTarget.classList.add('selected');
            e.currentTarget.setAttribute('disabled', 'disabled');
        }
        createEmbedComponent(embedManager,  { ...componentConfig, visualId });
    });
  }
  
const getListOfVisuals = async () => {
    const visualsListUrl = '${process.env.REACT_APP_COMPOSER_URL}/api/visuals';
    const { access_token } = await getToken();
    const headers = {
        'Content-Type': 'application/vnd.composer.v3+json',
        'Authorization': 'Bearer ' + access_token
    }
    const response = await fetch(visualsListUrl, {headers});
    return response.json().then(({content}) => content);
}
    
const addVisualOption = (({id, type, visualName}) => {
    const optionsContainer = document.getElementsByClassName('options-container')[0];
    const visualButton = document.createElement('button');
    const visualNameDiv = document.createElement('div');
    const visualIcon = document.createElement('img');
    const nameTextNode = document.createTextNode(visualName);
    
    visualButton.classList.add('option');
    visualIcon.classList.add('visual-icon');
    visualNameDiv.classList.add('visual-name');
    
    visualNameDiv.append(nameTextNode);
    visualButton.append(visualIcon);
    visualButton.append(visualNameDiv);
    
    if (id === initialVisualId) {
        visualButton.classList.add('selected');  
        visualButton.setAttribute('disabled', 'disabled');
    }
    
    visualIcon.src = \`/icons/$\{type\}.svg\`;
    visualIcon.width = 20;
    visualIcon.height = 20;
    
    visualButton.onclick = (e) => replaceComponent(id, e);
    
    optionsContainer.append(visualButton);
})

const buildListOfVisualsUi = async () => {
    const visualsList = await getListOfVisuals();
    initialVisualId = visualsList[0].id;
    replaceComponent(initialVisualId);
    visualsList.forEach(v => addVisualOption(v));
}

buildListOfVisualsUi();
`;

export const VISUAL_LISTING_CSS_CODE = `

.options-container {
    width: 200px;
    padding-top: 28px;
    display: flex;
    flex-direction: column;
    overflow: auto;
}
  
.option, .header {
    width: 100%;
    padding: 5px 16px;
    display: flex;
    align-items: center;
}
  
.header {
    background: #f5f5f5;
    font-weight: 600;
    position: fixed;
    top: 0;
    font-size: 13px;
    color: #885a90;
}
  
.header-col {
    margin-right: 12px;
}
  
.option {
    width: 97%;
    padding: 5px 16px;
    background: #ffffff;
    border: none;
    cursor: pointer;
    margin: 3px 3px 0px 3px;
    border-radius: 5px;
}
  
.selected {
    background: #e8e8e8;
    cursor: default;
}
  
.option:not(.selected):hover {
    background: #f5f5f5;
}
  
.visual-icon {
    padding: 0 20px 0 5px;
}
  
.visual-name {
    color: #4682B4;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
`;

export const VISUAL_BUILDER_COMPONENT_CONFIG = `const componentConfig = {
    "theme":"%themeComposer%",
    "header": { 
        "showTitle": %showTitle%,
        "visible": %showHeader%,
    },%breadcrumbs%%ipOverrides%%embedSource%
}`;

export const EMBED_SOURCE_JS_CODE = `
    "source": {
        "visualId": "89200c26c2cef4b697c30664dc7bd88c", // ID of visual template that belongs to a source, used for creating a new visual.
    }`;

export const VISUAL_AUTHORING_BREADCRUMBS_CODE = `
    "breadcrumbs": { // optional configuration of the breadcrumbs
        "title": "Visuals",
        "onClick": () => {alert("Clicked !!!")},
        "href": "#",
        "target": "_blank"
    },`;
