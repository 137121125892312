import React, { useEffect, useState } from 'react';
import styles from './AdvancedEmbed.module.scss';
import SplitPane from 'react-split-pane';
import Tabs from 'src/components/Tabs/Tabs/Tabs';
import Tab from 'src/components/Tabs/Tabs/Tab';
import DocumentationHelper from '../DocumentationHelper/DocumentationHelper';
import EditModeWarningModalContent from '../EditModeWarningModalContent/EditModeWarningModalContent';
import { Prompt } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmbedReady } from 'src/shared/store/embedSelectors';

const resizerVerticalStyles = {
  background: '#cdcdcd',
  width: '2px',
  cursor: 'col-resize',
  margin: '0 5px',
  border: '6px solid transparent',
  backgroundClip: 'padding-box',
};

export default function AdvancedEmbed({
  onModalConfirm,
  onModalCancel,
  onTabSelect,
  isWarningModalShown,
  selectedTabIndex,
  advancedEmbedOptions,
  advancedEmbedCode,
  advancedEmbedDashboardPreview,
  showWarningModal,
  isCodeTouched,
  resetDefaults,
  setNavigationUrl,
  navigationUrl,
  componentType,
  productSelected,
}) {
  const [maxWidth, setMaxWidth] = useState(0);
  const isReady = useSelector(isEmbedReady);

  const setMaxSizes = () => {
    setMaxWidth(window.innerWidth - 350);
  };

  const handlePrompt = ({ pathname }) => {
    if (isCodeTouched) {
      setNavigationUrl(pathname);
      showWarningModal();
      return !!navigationUrl;
    }
    resetDefaults();
    return true;
  };

  useEffect(() => {
    setMaxSizes();
    window.addEventListener('resize', setMaxSizes);
    return window.removeEventListener('resize', setMaxSizes);
  }, []);

  return isReady ? (
    <div className={styles.root}>
      {isWarningModalShown && (
        <>
          <div className={styles.overlay}>
            <EditModeWarningModalContent
              onConfirm={onModalConfirm}
              onCancel={onModalCancel}
            ></EditModeWarningModalContent>
          </div>
        </>
      )}
      <Prompt message={handlePrompt}></Prompt>
      <SplitPane
        split="vertical"
        minSize={400}
        maxSize={maxWidth}
        defaultSize={450}
        resizerStyle={resizerVerticalStyles}
      >
        <Tabs selectedIndex={selectedTabIndex} onSelect={onTabSelect}>
          <Tab name="Embed Options">{advancedEmbedOptions}</Tab>
          <Tab name="Embed Code">{advancedEmbedCode}</Tab>
        </Tabs>
        <div className={styles.previewSection}>{advancedEmbedDashboardPreview}</div>
      </SplitPane>
      <div className={styles.documentationHelperHolder}>
        <DocumentationHelper selectedGroup={componentType} productSelected={productSelected}></DocumentationHelper>
      </div>
    </div>
  ) : (
    <></>
  );
}
