import { nanoid } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import Checkbox from 'src/components/Checkbox/Checkbox';
import { showModal } from 'src/components/ModalContainer/modalsSlice';
import InfoTooltip from '../InfoTooltip/InfoTooltip';
import styles from './EmbedOption.module.scss';
import { setEmbedDundasDefaultIds, synchronizePreviewSettings } from 'src/shared/store/embedSlice';

export default function EmbedOption({ value, fieldKey, onChange, name, content, link = null, options = [], defaultMappings }) {
  const dispatch = useDispatch();

  const docLinkClickHandler = (modalTitle, contentLink) => {
    dispatch(showModal({ name: modalTitle, id: nanoid(), contentLink }));
  };

  let selectElement;

  const handleDefaultId = (fieldKey, list, defaultFriendlyName) => {
    const defaultId = (list.find(d => d.friendlyName === defaultFriendlyName) || list[0] || {}).id || '';
    dispatch(setEmbedDundasDefaultIds({ [fieldKey]: defaultId }));
    dispatch(synchronizePreviewSettings());
  }

  if (
    name === 'Select Theme' ||
    name === 'Select Dashboard' ||
    name === 'Select Report' ||
    name === 'Select Scorecard' ||
    name === 'Select Metric Set'
  ) {
    if ((value === '' || value === undefined)) {
      const mapping = defaultMappings[fieldKey];
      if (mapping) {
        const { list, defaultFriendlyName } = mapping;
        handleDefaultId(fieldKey, list, defaultFriendlyName);
      }
    }

    selectElement = (
      <select
        className={styles.optionsSelect}
        value={value}
        onChange={(e) => onChange(fieldKey, e.target.value)}
        name={fieldKey}
        id={fieldKey}
      >
        {options.map(({ id, friendlyName }) => (
          <option key={id} value={id}>
            {friendlyName}
          </option>
        ))}
      </select>
    );
  } else {
    selectElement = (
      <select
        className={styles.optionsSelect}
        value={value}
        onChange={(e) => onChange(fieldKey, e.target.value)}
        name={fieldKey}
        id={fieldKey}
      >
        {options.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
    );
  }

  const checkboxElement = <Checkbox checked={value} onChange={(e) => onChange(fieldKey, e.target.checked)}></Checkbox>;

  return (
    <div className={styles.root}>
      <label htmlFor="theme" className={styles.title}>
        {name}
        {content && (
          <InfoTooltip>
            <div className={styles.tooltipText}>
              <h2 className={styles.tooltipHeader}>{name}</h2>
              {content}&nbsp;
              {link && (
                <span className={styles.documentationLink} onClick={() => docLinkClickHandler(name, link)}>
                  Learn&nbsp;more...
                </span>
              )}
            </div>
          </InfoTooltip>
        )}
      </label>
      {options && options.length > 0 ? selectElement : checkboxElement}
    </div>
  );
}
