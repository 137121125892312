import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setIsLoading, setIsUpdating, updateSampleTitle } from './redux/samplesSlice';
import styles from './Sample.module.scss';
import { useHistory, useParams } from 'react-router-dom';
import { getSample, updateSample } from './redux/thunks';
import { useState } from 'react';
import { TABS } from 'src/shared/constants/liveCodeTabs';
import ConnectedEmbedCode from 'src/shared/components/ConnectedEmbedCode/ConnectedEmbedCode';
import EmbedPreview from 'src/shared/components/EmbedPreview/EmbedPreview';
import SplitPane from 'react-split-pane';
import {
  setCss,
  setEditorCss,
  setEditorHtml,
  setEditorJs,
  setEditorMd,
  setHtml,
  setIsCodeRun,
  setIsCodeTouched,
  setJs,
  synchronizePreviewSettings,
} from 'src/shared/store/embedSlice';
import { useRef } from 'react';
import classnames from 'classnames';
import { useAuth } from 'src/hooks/useAuth';
import { ReactComponent as CheckmarkIcon } from 'src/static/icons/checkmark.svg';
import { ReactComponent as ForkIcon } from 'src/static/icons/fork.svg';
import { ReactComponent as SaveIcon } from 'src/static/icons/save.svg';
import { createNewSample } from '../MySamples/redux/thunks';
import { setNewSampleId } from '../MySamples/redux/mySamplesSlice';

const resizerVerticalStyles = {
  background: '#cdcdcd',
  width: '2px',
  cursor: 'col-resize',
  margin: '0 5px',
  border: '6px solid transparent',
  backgroundClip: 'padding-box',
};

export default function MySamples() {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(TABS.md);
  const [isTitleFocused, setIsTitleFocused] = useState(false);
  const [isUpdateSuccessful, setIsUpdateSuccessful] = useState(false);
  const { sample, isLoading, isUpdating } = useSelector((state) => state.sample);
  const [maxWidth, setMaxWidth] = useState(0);
  const setMaxSizes = () => {
    setMaxWidth(window.innerWidth - 350);
  };
  useEffect(() => {
    setMaxSizes();
    window.addEventListener('resize', setMaxSizes);
    return window.removeEventListener('resize', setMaxSizes);
  }, []);
  const { id } = useParams();
  const { user } = useAuth();
  const editorCode = useSelector((state) => state.embed.editorCode);
  const mySamplesData = useSelector((state) => state.samples);
  const history = useHistory();

  useEffect(() => {
    dispatch(setIsLoading(true));
    dispatch(getSample(id));
  }, [id]);

  const isLoaded = useRef(false);

  useEffect(() => {
    if (mySamplesData.newSampleId) {
      history.push(`/sample/${mySamplesData.newSampleId}`);
      dispatch(setNewSampleId(null));
    }
  }, [mySamplesData.newSampleId]);

  useEffect(() => {
    if (sample) {
      const { html, js, css, md = '' } = sample;
      dispatch(setHtml(html));
      dispatch(setJs(js));
      dispatch(setCss(css));
      dispatch(setEditorHtml(html));
      dispatch(setEditorJs(js));
      dispatch(setEditorCss(css));
      dispatch(setEditorMd(md));
      dispatch(setIsCodeTouched(true));
      dispatch(setIsCodeRun(true));
      dispatch(synchronizePreviewSettings());
    }
  }, [sample && sample.js, sample && sample.css, sample && sample.html]);

  useEffect(() => {
    if (!sample) {
      return;
    }
    setSampleTitle(sample.title);
  }, [sample]);

  const [sampleTitle, setSampleTitle] = useState(sample ? sample.title : '');
  const handleTitleBlur = useCallback(() => {
    dispatch(updateSampleTitle(sampleTitle));
    if (sample && sampleTitle === '') {
      setSampleTitle(sample.title);
    }
    setIsTitleFocused(false);
  }, [sampleTitle, sample]);

  const saveSample = useCallback(() => {
    dispatch(setIsUpdating(true));
    dispatch(updateSample());
  }, []);

  const forkSample = useCallback(() => {
    dispatch(createNewSample({ ...editorCode, title: sample.title }));
  }, [editorCode, sample && sample.title]);

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  }, []);

  useEffect(() => {
    if (!isUpdating && isLoaded.current) {
      setIsUpdateSuccessful(true);
      setTimeout(() => setIsUpdateSuccessful(false), 2000);
    }
  }, [isUpdating]);

  useEffect(() => {
    isLoaded.current = true;
  }, []);

  return (
    <div className={styles.root}>
      {isLoading ? (
        <div className={styles.spinnerHolder}>
          <div className={styles.spinner}></div>
        </div>
      ) : (
        <div>
          <div className={styles.header}>
            <input
              disabled={!user || !sample || user.id !== sample.ownerId}
              className={classnames(styles.titleInput, { [styles.focused]: isTitleFocused })}
              value={sampleTitle}
              onChange={(e) => setSampleTitle(e.target.value)}
              onBlur={handleTitleBlur}
              onFocus={() => setIsTitleFocused(true)}
              onKeyDown={handleKeyDown}
            ></input>
            {sample &&
              user &&
              (user.id === sample.ownerId ? (
                <button disabled={isUpdating || isUpdateSuccessful} className={styles.saveButton} onClick={saveSample}>
                  {isUpdating ? (
                    <div className={classnames(styles.spinner, styles.small)}></div>
                  ) : isUpdateSuccessful ? (
                    <CheckmarkIcon></CheckmarkIcon>
                  ) : (
                    <>
                      <SaveIcon></SaveIcon> Save
                    </>
                  )}
                </button>
              ) : (
                <button className={styles.saveButton} onClick={forkSample}>
                  <ForkIcon></ForkIcon> Fork
                </button>
              ))}
          </div>

          <SplitPane
            className={styles.splitPane}
            split="vertical"
            minSize={400}
            maxSize={maxWidth}
            defaultSize={450}
            resizerStyle={resizerVerticalStyles}
          >
            <ConnectedEmbedCode
              showReadme={true}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              readmeEditable={user && sample && user.id === sample.ownerId}
            ></ConnectedEmbedCode>
            <EmbedPreview></EmbedPreview>
          </SplitPane>
          <div className={styles.documentationHelperHolder}></div>
        </div>
      )}
    </div>
  );
}
