import React from 'react';
import BannerContent from 'src/shared/components/BannerContent/BannerContent';
import { ReactComponent as ApiIcon } from 'src/static/icons/api.svg';

export default function EmbedApiBanner() {
  return (
    <BannerContent icon={<ApiIcon></ApiIcon>}>
      Up-level the self-service experience for power users with embedded visual and dashboard builder that lets users
      create their own dashboards for analysis.
    </BannerContent>
  );
}
