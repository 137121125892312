import React from 'react';
import styles from './BannerContent.module.scss';

export default function BannerContent({ icon, children }) {
  return (
    <div className={styles.root}>
      <div className={styles.iconHolder}>{icon}</div>
      <div className={styles.textHolder}>{children}</div>
    </div>
  );
}
