export const DASHBOARD_LISTING_HTML_CODE = `

    <!-- START: List dashboards code -->
    <div class="options-container">
        <div class="header">
            <div>Name</div>
        </div>
    </div>
    <!-- END: List dashboards code -->
    `;

export const DASHBOARD_LISTING_JS_CODE = `

let initialDashboardId;

const replaceDashboard = (dashboardId, e) => {
    embedManagerPromise.then(embedManager => {
        const options = Array.from(document.getElementsByClassName('option'));
      
        if(e) {
            options.forEach(o => {
                o.classList.remove('selected');
                o.removeAttribute('disabled');
            });
            e.currentTarget.classList.add('selected');
            e.currentTarget.setAttribute('disabled', 'disabled');
        }
        createEmbedComponent(embedManager, { ...componentConfig, dashboardId });
    });
}
  
const getListOfDashboards = async () => {
    const dashboardsListUrl = '${process.env.REACT_APP_COMPOSER_URL}/api/dashboards';
    const { access_token } = await getToken();
    const headers = {
        'Accept': 'application/vnd.composer.v3+json',
        'Content-Type': 'application/vnd.composer.v3+json',
        'Authorization': 'Bearer ' + access_token
    }
    const response = await fetch(dashboardsListUrl, {headers});
    return response.json().then(({content}) => content);
}
  
const addDashboardOption = (({id, name}) => {
    const optionsContainer = document.getElementsByClassName('options-container')[0];
    const dashboardButton = document.createElement('button');
    const dashboardNameDiv = document.createElement('div');
    const nameTextNode = document.createTextNode(name);
    
    dashboardButton.classList.add('option');
    dashboardNameDiv.classList.add('dashboard-name');
    
    dashboardNameDiv.append(nameTextNode);
    dashboardButton.append(dashboardNameDiv);
    
	if (id === initialDashboardId) {
    	dashboardButton.classList.add('selected');  
        dashboardButton.setAttribute('disabled', 'disabled');
    }
    
    dashboardButton.onclick = (e) => replaceDashboard(id, e);
    optionsContainer.append(dashboardButton);
})
  
const buildListOfDashboardsUi = async () => {
    const dashboardsList = await getListOfDashboards();
    initialDashboardId = dashboardsList[0].id;
    replaceDashboard(initialDashboardId);
    dashboardsList.forEach(v => addDashboardOption(v));
}
  
buildListOfDashboardsUi();
`;

export const DASHBOARDS_LISTING_CSS_CODE = `

.options-container {
    width: 200px;
    padding-top: 28px;
    display: flex;
    flex-direction: column;
    overflow: auto;
}
  
.option, .header {
    width: 100%;
    padding: 5px 16px;
    display: flex;
    align-items: center;
}
  
.header {
    background: #f5f5f5;
    font-weight: 600;
    position: fixed;
    top: 0;
    font-size: 13px;
    color: #885a90;
}
  
.header-col {
    margin-right: 12px;
}
  
.option {
    width: 97%;
    padding: 5px 16px;
    background: #ffffff;
    border: none;
    cursor: pointer;
    margin: 3px 3px 0px 3px;
    border-radius: 5px;
}
  
.selected {
    background: #e8e8e8;
    cursor: default;
}
  
.option:not(.selected):hover {
    background: #f5f5f5;
}

.dashboard-name {
    color: #4682B4;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
`;

export const DASHBOARD_BUILDER_COMPONENT_CONFIG = `const componentConfig = {
    "theme":"%themeComposer%",%ipOverrides%
    "editor": { "placement": "%editorPlacement%" },
    "header": { 
        "showTitle": %showTitle%,
    }
}`;
