const commonInteractivitySettings = [
  { key: 'addNew', defaultValue: false },
  { key: 'import', defaultValue: false },
  { key: 'multiSelect', defaultValue: false },
  { key: 'export', defaultValue: false },
  { key: 'filter', defaultValue: false },
  { key: 'delete', defaultValue: false },
  { key: 'permissions', defaultValue: false },
  { key: 'favorites', defaultValue: false },
  { key: 'description', defaultvalue: false },
  { key: 'tags', defaultValue: false },
];
export const OPTIONS = {
  dashboard: {
    basicOptions: [
      { key: 'ip', defaultValue: 'interactive' },
      { key: 'dashboardIpOverrides', defaultValue: true },
    ],
    advancedOptions: [],
    interactivityOptions: {
      Dashboard: [
        { key: 'refresh', defaultValue: false },
        { key: 'changeLayout', defaultValue: false },
        { key: 'rename', defaultValue: false },
        { key: 'dashboardInteractions', defaultValue: false },
        { key: 'addToFavourites', defaultValue: false },
        { key: 'exportPngPdf', defaultValue: false },
        { key: 'dashboardLinks', defaultValue: false },
        { key: 'exportConfiguration', defaultValue: false },
        { key: 'addVisuals', defaultValue: false },
        { key: 'delete', defaultValue: false },
        { key: 'save', defaultValue: false },
        { key: 'filter', defaultValue: false },
        { key: 'manageTags', defaultValue: false },
        { key: 'manageAlerts', defaultValue: false },
        { key: 'shareDashboard', defaultValue: false },
        { key: 'scheduleReports', defaultValue: false },
        { key: 'textSnippet', defaultValue: false },
        { key: 'filterSnippet', defaultValue: false },
        { key: 'widgetSettings', defaultValue: false },
        { key: 'comments', defaultValue: false },
      ],
      Visuals: [
        { key: 'actionsAction', defaultValue: false },
        { key: 'rulers', defaultValue: false },
        { key: 'zoomAction', defaultValue: false },
        { key: 'filterAction', defaultValue: false },
        { key: 'colors', defaultValue: false },
        { key: 'metrics', defaultValue: false },
        { key: 'actions', defaultValue: false },
        { key: 'trendAction', defaultValue: false },
        { key: 'visualStyle', defaultValue: false },
        { key: 'keysetAction', defaultValue: false },
        { key: 'keyset', defaultValue: false },
        { key: 'copy', defaultValue: false },
        { key: 'export', defaultValue: false },
        { key: 'timebarPanel', defaultValue: false },
        { key: 'detailsAction', defaultValue: false },
        { key: 'maximize', defaultValue: false },
        { key: 'linkAction', defaultValue: false },
        { key: 'remove', defaultValue: false },
        { key: 'grouping', defaultValue: false },
        { key: 'rename', defaultValue: false },
        { key: 'sort', defaultValue: false },
        { key: 'timebarField', defaultValue: false },
        { key: 'saveAs', defaultValue: false },
        { key: 'conditionalFormatting', defaultValue: false },
        { key: 'info', defaultValue: false },
      ],
    },
  },
  'visual-builder': {
    basicOptions: [{ key: 'visualIpOverrides', defaultValue: true }],
    advancedOptions: [],
    interactivityOptions: {
      Visuals: [
        { key: 'actionsAction', defaultValue: false },
        { key: 'rulers', defaultValue: false },
        { key: 'zoomAction', defaultValue: false },
        { key: 'filterAction', defaultValue: false },
        { key: 'colors', defaultValue: false },
        { key: 'metrics', defaultValue: false },
        { key: 'actions', defaultValue: false },
        { key: 'trendAction', defaultValue: false },
        { key: 'visualStyle', defaultValue: false },
        { key: 'keysetAction', defaultValue: false },
        { key: 'keyset', defaultValue: false },
        { key: 'copy', defaultValue: false },
        { key: 'settings', defaultValue: false },
        { key: 'export', defaultValue: false },
        { key: 'timebarPanel', defaultValue: false },
        { key: 'detailsAction', defaultValue: false },
        { key: 'maximize', defaultValue: false },
        { key: 'linkAction', defaultValue: false },
        { key: 'remove', defaultValue: false },
        { key: 'grouping', defaultValue: false },
        { key: 'rename', defaultValue: false },
        { key: 'sort', defaultValue: false },
        { key: 'timebarField', defaultValue: false },
        { key: 'manageTags', defaultValue: false },
        { key: 'save', defaultValue: false },
        { key: 'conditionalFormatting', defaultValue: false },
        { key: 'info', defaultValue: false },
      ],
    },
  },
  'dashboard-builder': {
    basicOptions: [{ key: 'dashboardBuilderIpOverrides', defaultValue: true }],
    advancedOptions: [],
    interactivityOptions: {
      Dashboard: [
        { key: 'refresh', defaultValue: false },
        { key: 'changeLayout', defaultValue: false },
        { key: 'rename', defaultValue: false },
        { key: 'shareFilterSets', defaultValue: false },
        { key: 'dashboardInteractions', defaultValue: false },
        { key: 'addToFavourites', defaultValue: false },
        { key: 'exportPngPdf', defaultValue: false },
        { key: 'dashboardLinks', defaultValue: false },
        { key: 'exportConfiguration', defaultValue: false },
        { key: 'addVisuals', defaultValue: false },
        { key: 'delete', defaultValue: false },
        { key: 'save', defaultValue: false },
        { key: 'filter', defaultValue: false },
        { key: 'manageTags', defaultValue: false },
        { key: 'manageAlerts', defaultValue: false },
        { key: 'shareDashboard', defaultValue: false },
        { key: 'scheduleReports', defaultValue: false },
        { key: 'textSnippet', defaultValue: false },
        { key: 'filterSnippet', defaultValue: false },
        { key: 'widgetSettings', defaultValue: false },
        { key: 'comments', defaultValue: false },
      ],
      Visuals: [
        { key: 'actionsAction', defaultValue: false },
        { key: 'rulers', defaultValue: false },
        { key: 'zoomAction', defaultValue: false },
        { key: 'filterAction', defaultValue: false },
        { key: 'colors', defaultValue: false },
        { key: 'metrics', defaultValue: false },
        { key: 'actions', defaultValue: false },
        { key: 'trendAction', defaultValue: false },
        { key: 'visualStyle', defaultValue: false },
        { key: 'keysetAction', defaultValue: false },
        { key: 'keyset', defaultValue: false },
        { key: 'copy', defaultValue: false },
        { key: 'settings', defaultValue: false },
        { key: 'export', defaultValue: false },
        { key: 'timebarPanel', defaultValue: false },
        { key: 'detailsAction', defaultValue: false },
        { key: 'maximize', defaultValue: false },
        { key: 'linkAction', defaultValue: false },
        { key: 'filter', defaultValue: false },
        { key: 'remove', defaultValue: false },
        { key: 'grouping', defaultValue: false },
        { key: 'rename', defaultValue: false },
        { key: 'sort', defaultValue: false },
        { key: 'timebarField', defaultValue: false },
        { key: 'save', defaultValue: false },
        { key: 'saveAs', defaultValue: false },
        { key: 'conditionalFormatting', defaultValue: false },
        { key: 'info', defaultValue: false },
      ],
    },
  },
  inventory: {
    basicOptions: [
      { key: 'inventoryItem', defaultValue: 'dashboard' },
      { key: 'inventoryIpOverrides', defaultValue: true },
    ],
    advancedOptions: [],
    interactivityOptions: {
      DashboardLibrary: [
        ...commonInteractivitySettings,
        { key: 'schedule', defaultValue: false },
        { key: 'share', defaultValue: false },
      ],
      VisualLibrary: [...commonInteractivitySettings],
      SourceLibrary: [
        ...commonInteractivitySettings,
        { key: 'rowSecurity', defaultValue: false },
        { key: 'columnSecurity', defaultValue: false },
        { key: 'clearCache', defaultValue: false },
        { key: 'availableVisualTypes', defaultValue: false },
      ],
    },
  },
  'source-editor': {
    basicOptions: [{ key: 'sourceEditorIpOverrides', defaultValue: true }],
    advancedOptions: [],
    interactivityOptions: {
      Create: [
        { key: 'addFromConnection', defaultValue: true },
        { key: 'addFromFileUpload', defaultValue: true },
        { key: 'selectFileUpload', defaultValue: true },
        { key: 'uploadnewFile', defaultValue: true },
        { key: 'createJoins', defaultValue: true },
        { key: 'filterValuesEntities', defaultValue: true },
        { key: 'exportSource', defaultValue: true },
        { key: 'tags', defaultValue: true },
        { key: 'copySource', defaultValue: true },
      ],
      Fields: [
        { key: 'addDerivedField', defaultValue: true },
        { key: 'addHierarchyField', defaultValue: true },
        { key: 'addCustomMetric', defaultValue: true },
        { key: 'visibility', defaultValue: true },
        { key: 'settings', defaultValue: true },
        { key: 'delete', defaultValue: true },
        { key: 'filterValueOverrides', defaultValue: true },
        { key: 'fieldCapabilities', defaultValue: true },
        { key: 'manageTranslations', defaultValue: true },
        { key: 'setUniqueKey', defaultValue: true },
      ],
      Caching: [
        { key: 'dataCache', defaultValue: true },
        { key: 'statisticsCache', defaultValue: true },
        { key: 'scheduleRefresh', defaultValue: true },
        { key: 'fieldSettings', defaultValue: true },
      ],
    },
  },
};
