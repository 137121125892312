import { createSlice } from '@reduxjs/toolkit';
import {
  COMMON_EMBED_HTML_CODE,
  COMMON_EMBED_JS_CODE,
  COMMON_EMBED_CSS_CODE,
} from 'src/shared/constants/liveCodeScripts/commonCode';
import {
  applySettingsToJsCode,
  applySettingsToHtmlCode,
  applySettingsToCssCode,
} from 'src/shared/utils/codeBuilderHelpers';
import { EMBED_COMPONENT_TYPES, NAME_OF_DEFAULT_DASHBOARD } from '../constants/embedComponentTypes';
import { getDefaultSettings } from 'src/helpers/embedOptionHelpers';
import { getFirstDashboardId } from './embedSelectors';
const initialState = {
  embedComponentType: null,
  dashboardId: null,
  originId: null,
  code: {
    html: applySettingsToHtmlCode(
      COMMON_EMBED_HTML_CODE,
      getDefaultSettings('interactive', EMBED_COMPONENT_TYPES.dashboard)
    ),
    js: applySettingsToJsCode(
      COMMON_EMBED_JS_CODE,
      getDefaultSettings('interactive', EMBED_COMPONENT_TYPES.dashboard),
      EMBED_COMPONENT_TYPES.dashboard
    ),
    css: applySettingsToCssCode(
      COMMON_EMBED_CSS_CODE,
      getDefaultSettings('interactive', EMBED_COMPONENT_TYPES.dashboard)
    ),
  },
  editorCode: {
    html: '',
    js: '',
    css: '',
    md: '',
  },
  settings: getDefaultSettings('interactive', EMBED_COMPONENT_TYPES.dashboard),
  previewSettings: getDefaultSettings('interactive', EMBED_COMPONENT_TYPES.dashboard),
  isCodeTouched: false,
  isCodeRun: false,
  isReady: false,
  dashboardListComposer: [],
  sourceList: [],
  dashboardList: [],
  reportList: [],
  scorecardList: [],
  metricSetList: [],
  themesList: [],
  defaultUserSessionId: null,
  sidebarExpanded: false,
};

export const dashboardAuthoringSlice = createSlice({
  name: 'embed',
  initialState,
  reducers: {
    setHtml(state, action) {
      state.code.html = action.payload;
    },
    setJs(state, action) {
      state.code.js = action.payload;
    },
    setCss(state, action) {
      state.code.css = action.payload;
    },
    setEditorHtml(state, action) {
      state.editorCode.html = action.payload;
    },
    setEditorJs(state, action) {
      state.editorCode.js = action.payload;
    },
    setEditorMd(state, action) {
      state.editorCode.md = action.payload;
    },
    setEditorCss(state, action) {
      state.editorCode.css = action.payload;
    },
    setIsCodeTouched(state, action) {
      state.isCodeTouched = action.payload;
    },
    setIsCodeRun(state, action) {
      state.isCodeRun = action.payload;
    },
    setEmbedSetting(state, { payload }) {
      state.settings[payload.name] = payload.value;
    },
    setEmbedComponentType(state, { payload }) {
      state.embedComponentType = payload;
      const code = {
        html: applySettingsToHtmlCode(COMMON_EMBED_HTML_CODE, state.settings),
        js: applySettingsToJsCode(
          COMMON_EMBED_JS_CODE,
          state.settings,
          state.embedComponentType,
          state.dashboardId,
          state.originId,
          getFirstDashboardId({ embed: state })
        ),
        css: applySettingsToCssCode(COMMON_EMBED_CSS_CODE, state.settings),
      };
      state.editorCode = code;
      state.code = code;
    },
    setEmbedDashboardId(state, { payload }) {
      state.dashboardId = payload;
      const code = {
        html: applySettingsToHtmlCode(COMMON_EMBED_HTML_CODE, state.settings),
        js: applySettingsToJsCode(
          COMMON_EMBED_JS_CODE,
          state.settings,
          state.embedComponentType,
          state.dashboardId,
          state.originId,
          getFirstDashboardId({ embed: state })
        ),
        css: applySettingsToCssCode(COMMON_EMBED_CSS_CODE, state.settings),
      };
      state.editorCode = code;
      state.code = code;
    },
    setEmbedDundasDefaultIds(state, { payload }) {
      state.settings.dashboardId = payload.dashboardId;
      state.settings.reportId = payload.reportId;
      state.settings.scorecardId = payload.scorecardId;
      state.settings.metricSetId = payload.metricSetId;
      const code = {
        html: applySettingsToHtmlCode(COMMON_EMBED_HTML_CODE, state.settings),
        js: applySettingsToJsCode(COMMON_EMBED_JS_CODE, state.settings, state.embedComponentType),
        css: applySettingsToCssCode(COMMON_EMBED_CSS_CODE, state.settings),
      };
      state.editorCode = code;
      state.code = code;
    },
    setEmbedOriginId(state, { payload }) {
      state.originId = payload;
      const code = {
        html: applySettingsToHtmlCode(COMMON_EMBED_HTML_CODE, state.settings),
        js: applySettingsToJsCode(
          COMMON_EMBED_JS_CODE,
          state.settings,
          state.embedComponentType,
          state.dashboardId,
          state.originId,
          getFirstDashboardId({ embed: state })
        ),
        css: applySettingsToCssCode(COMMON_EMBED_CSS_CODE, state.settings),
      };
      state.editorCode = code;
      state.code = code;
    },
    synchronizePreviewSettings(state) {
      state.previewSettings = state.settings;
    },
    setEmbedSettings(state, { payload }) {
      const updatedSettings = { ...state.settings, ...payload };
      state.settings = updatedSettings;
      const code = {
        html: applySettingsToHtmlCode(COMMON_EMBED_HTML_CODE, updatedSettings),
        js: applySettingsToJsCode(
          COMMON_EMBED_JS_CODE,
          updatedSettings,
          state.embedComponentType,
          state.dashboardId,
          state.originId,
          getFirstDashboardId({ embed: state })
        ),
        css: applySettingsToCssCode(COMMON_EMBED_CSS_CODE, updatedSettings),
      };
      state.editorCode = code;
      state.code = code;
    },
    setIsEmbedReady(state, { payload }) {
      state.isReady = payload;
    },
    setDashboardList(state, { payload }) {
      state.dashboardList = payload;
    },
    setReportList(state, { payload }) {
      state.reportList = payload;
    },
    setScorecardList(state, { payload }) {
      state.scorecardList = payload;
    },
    setThemesList(state, { payload }) {
      state.themesList = payload;
    },
    setDashboardListComposer(state, { payload }) {
      state.dashboardListComposer = payload;
    },
    setSourceList(state, { payload }) {
      state.sourceList = payload;
    },
    setEmbedSourceId(state, { payload }) {
      state.sourceId = payload;
      const code = {
        html: applySettingsToHtmlCode(COMMON_EMBED_HTML_CODE, state.settings),
        js: applySettingsToJsCode(
          COMMON_EMBED_JS_CODE,
          state.settings,
          state.embedComponentType,
          state.dashboardId,
          state.originId,
          getFirstDashboardId({ embed: state }),
          state.sourceId
        ),
        css: applySettingsToCssCode(COMMON_EMBED_CSS_CODE, state.settings),
      };
      state.editorCode = code;
      state.code = code;
    },
    setMetricSetList(state, { payload }) {
      state.metricSetList = payload;
    },

    setDefaultUserSessionId(state, { payload }) {
      state.defaultUserSessionId = payload;
    },
    setSidebarExpanded(state, { payload }) {
      state.sidebarExpanded = payload;
    },
    resetDefaults(state) {
      const defaultDashboard = state.dashboardListComposer.find((d) => d.name === NAME_OF_DEFAULT_DASHBOARD);
      return {
        ...initialState,
        defaultUserSessionId: state.defaultUserSessionId,
        dashboardList: state.dashboardList,
        dashboardListComposer: state.dashboardListComposer,
        sourceList: state.sourceList,
        isReady: state.isReady,
        dashboardId: defaultDashboard
          ? defaultDashboard.id
          : state.dashboardListComposer.length
          ? state.dashboardListComposer[0].id
          : '',
        originId: defaultDashboard
          ? defaultDashboard.originId
          : state.dashboardListComposer.length
          ? state.dashboardListComposer[0].originId
          : '',
        reportList: state.reportList,
        scorecardList: state.scorecardList,
        themesList: state.themesList,
        metricSetList: state.metricSetList,
      };
    },
  },
});

export const {
  setHtml,
  setJs,
  setCss,
  setIsCodeTouched,
  setEmbedSetting,
  setEmbedSettings,
  setEditorHtml,
  setEditorJs,
  setEditorCss,
  setEditorMd,
  setEmbedComponentType,
  setEmbedDashboardId,
  setEmbedOriginId,
  synchronizePreviewSettings,
  setIsCodeRun,
  setIsEmbedReady,
  setDashboardList,
  setReportList,
  setScorecardList,
  resetDefaults,
  setDefaultUserSessionId,
  setThemesList,
  setMetricSetList,
  setDashboardListComposer,
  setSourceList,
  setEmbedSourceId,
  setEmbedDundasDefaultIds,
  setSidebarExpanded,
} = dashboardAuthoringSlice.actions;

export default dashboardAuthoringSlice.reducer;
