export const SOURCE_EDITOR_COMPONENT_CONFIG = `const componentConfig = {
    //Enable source Id when you have access to paticular source.
    "sourceId": "%sourceId%",
    "theme": "%themeComposer%",
    "activeTab": "%sourceEditorActiveTab%",
    "tabs": {
        "create": {
            "visible": %showCreateTab%,
            "connections": {
                "ids": [
                    "60e5c61007e17767d072f6ca",
                ],
                "defaultId": "60e5c61007e17767d072f6ca",
            }
        },
        "fields": {
            "visible": %showFieldsTab%,
        },
        "caching": {
            "visible": %showCacheTab%,
        },
        "settings": {
            "visible": %showSettingsTab%,
        },
    },
    "header": { 
        "showTitle": %showTitle%,
        "visible": %showHeader%,
    },%breadcrumbs%%ipOverrides%
}`;

export const SOURCE_EDITOR_EVENT_LISTENERS_CODE = `

        // START: Event Listeners
        // When the event is triggered in this example, a message pop-up
        // launches.  Please read the Documentation Helper for a description
        // of other available event listeners.
        component.addEventListener("composer-source-editor-ready", (e) => {
            alert("Source Editor has been loaded !!!");
        });
        /* END: Event Listeners */`;
