import React, { createRef, useEffect, useRef, useState } from 'react';
import styles from './Tabs.module.scss';
import classNames from 'classnames';

const defaultSliderParameters = {
  width: 0,
  left: 0,
};

export default function Tabs({ children, selectedIndex, onSelect }) {
  const adjustedSelectedIndex = selectedIndex > children.length - 1 ? 0 : selectedIndex;
  const displayedTab = adjustedSelectedIndex > children.length - 1 ? children[0] : children[adjustedSelectedIndex];
  const [buttonRefs, setButtonRefs] = useState([]);
  const [sliderParameters, setSliderParameters] = useState(defaultSliderParameters);
  const containerRef = useRef(null);

  useEffect(() => {
    setButtonRefs((elRefs) => children.map((_, i) => elRefs[i] || createRef()));
  }, [children]);

  useEffect(() => {
    if (buttonRefs[adjustedSelectedIndex] && containerRef.current) {
      const currentTabButton = buttonRefs[adjustedSelectedIndex].current;
      const { width, left } = currentTabButton.getBoundingClientRect();
      const { left: containerLeft } = containerRef.current.getBoundingClientRect();
      setSliderParameters({ width, left: left - containerLeft });
    }
  }, [adjustedSelectedIndex, buttonRefs]);

  return (
    <div className={styles.root} ref={containerRef}>
      <div className={styles.tabHeaders}>
        {children.map((tab, i) => (
          <button
            className={classNames(styles.tabDefault, {
              [styles.active]: displayedTab.props.name === tab.props.name,
            })}
            disabled={tab.props.disabled}
            onClick={() => onSelect(i)}
            key={i}
            ref={buttonRefs[i]}
          >
            {tab.props.name}
          </button>
        ))}
        <div className={styles.slider} style={{ left: sliderParameters.left, width: sliderParameters.width }}></div>
      </div>
      <div className={styles.tabContent}>{displayedTab}</div>
    </div>
  );
}
