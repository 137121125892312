export const OPTIONS = {
  dashboard: {
    basicOptions: [{ key: 'themeComposer', defaultValue: '__platform__' }],
    advancedOptions: [],
  },
  'visual-builder': {
    basicOptions: [{ key: 'themeComposer', defaultValue: '__platform__' }],
    advancedOptions: [],
  },
  'dashboard-builder': {
    basicOptions: [{ key: 'themeComposer', defaultValue: '__platform__' }],
    advancedOptions: [],
  },
  'source-editor': {
    basicOptions: [{ key: 'themeComposer', defaultValue: '__platform__' }],
    advancedOptions: [],
  },
  inventory: {
    basicOptions: [
      { key: 'themeComposer', defaultValue: '__platform__' },
      { key: 'inventoryItem', defaultValue: 'dashboard' },
    ],
    advancedOptions: [],
  },
};
