import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../Modal/Modal';
import { hideModal } from './modalsSlice';

export default function ModalContainer() {
  const modals = useSelector((state) => state.modals.modals);
  const dispatch = useDispatch();
  return modals.map(({ name, contentLink, id }) => (
    <Modal key={id} name={name} onCloseClick={() => dispatch(hideModal(id))} contentLink={contentLink}></Modal>
  ));
}
