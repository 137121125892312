import React from 'react';
import BannerContent from 'src/shared/components/BannerContent/BannerContent';
import { ReactComponent as EventListenersIcon } from 'src/static/icons/headphones.svg';

export default function EventListenersBanner() {
  return (
    <BannerContent icon={<EventListenersIcon></EventListenersIcon>}>
      Composer’s framework of event listeners enables two-way communication between your application content and the
      embedded analytics for a consistent experience.
    </BannerContent>
  );
}
