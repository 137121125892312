import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  COMMON_NAV_MENU_ITEMS,
  VDD_NAV_MENU_ITEMS,
  MDR_NAV_MENU_ITEMS,
} from 'src/shared/constants/navigationConstants';

const BANNER_DELAY_MS = 3000;
const NAV_MENU_ITEMS = [...COMMON_NAV_MENU_ITEMS, ...VDD_NAV_MENU_ITEMS, ...MDR_NAV_MENU_ITEMS];

const useNotificationBanners = () => {
  const history = useHistory();
  const [currentBanner, setCurrentBanner] = useState(null);
  const [isBannerShown, setIsBannerShown] = useState(false);
  const timeoutRef = useRef(null);

  const hideBanner = () => {
    setIsBannerShown(false);
    window.localStorage.setItem(history.location.pathname, 'false');
  };

  const handleLocationChange = (pathname) => {
    setIsBannerShown(false);
    clearTimeout(timeoutRef.current);
    const timeout = setTimeout(() => {
      setIsBannerShown(getIsBannerAvailable(pathname));
      const bannerInfo = NAV_MENU_ITEMS.find(({ path }) => path === pathname);
      if (!bannerInfo) {
        return;
      }
      const { bannerComponent, bannerText } = bannerInfo;
      setCurrentBanner({ bannerComponent, bannerText });
    }, BANNER_DELAY_MS);
    timeoutRef.current = timeout;
  };

  const getIsBannerAvailable = (key) =>
    !window.localStorage.getItem(key) &&
    NAV_MENU_ITEMS.some(({ path, bannerComponent }) => path === key && !!bannerComponent);

  useEffect(() => {
    handleLocationChange(history.location.pathname);
    history.listen((location) => {
      handleLocationChange(location.pathname);
    });
  }, []);

  return { currentBanner, isBannerShown, hideBanner };
};

export default useNotificationBanners;
