import React from 'react';
import PropTypes from 'prop-types';
import styles from './Tab.module.scss';

export default function Tab({ children }) {
  return <div className={styles.root}>{children}</div>;
}

Tab.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
};
