import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './EmbedPreview.module.scss';
import { getJsCode, getHtmlCode, getCssCode } from '../../store/embedSelectors';

const getSrcDoc = (html, js, css) =>
  `<style>${css}</style>${html}<body style="margin: 0;"}><script>${js}</script><body>`;

export default function EmbedPreview() {
  const html = useSelector(getHtmlCode);
  const js = useSelector(getJsCode);
  const css = useSelector(getCssCode);

  const [srcDoc, setSrcDoc] = useState(getSrcDoc(html, js));
  const [isIframeMouseBlocked, setisIframeMouseBlocked] = useState(false);

  const handleMouseDown = () => setisIframeMouseBlocked(true);
  const handleMouseUp = () => setisIframeMouseBlocked(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  useEffect(() => {
    setSrcDoc(getSrcDoc(html, js, css));
  }, [html, js, css]);

  return (
    <div className={styles.root}>
      <h2 className={styles.title}>Preview</h2>
      <iframe
        title="embed"
        className={classNames(styles.iframe, { [styles.disableMouseEvents]: isIframeMouseBlocked })}
        srcDoc={srcDoc}
      ></iframe>
    </div>
  );
}
