import classNames from 'classnames';
import React from 'react';
import styles from './NotificationBanner.module.scss';

export default function NotificationBanner({ headerText, children, isVisible, onClose }) {
  return (
    <div className={classNames(styles.root, { [styles.hidden]: !isVisible })}>
      <div className={styles.header}>
        <span className={styles.headerText}>{headerText}</span>
        <span onClick={onClose} className={styles.dismissText}>
          Dismiss
        </span>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
}
