import React from 'react';
import styles from './Checkbox.module.scss';

export default function Checkbox({ checked = false, onChange = null }) {
  return (
    <label className={styles.switch}>
      <input type="checkbox" id="checkbox" checked={checked} onChange={onChange} />
      <div className={styles.slider}></div>
    </label>
  );
}
