import styles from './ReachOut.module.scss';

export default function ReachOut() {
  return (
    <div className={styles.root}>
      <h2 className={styles.title}>Want to learn more about the product?</h2>
      <div className={styles.contentHolder}>
        <div className={styles.contentLeft}>
          <p className={styles.text}>Get a Demo or Pricing</p>
          <div className={styles.shareButtonsContainer}>
            <button
              className={styles.copyLinkBtton}
              onClick={() => {
                window.location.href = 'https://insightsoftware.com/demo/logi-symphony';
              }}
            >
              Get a Demo
            </button>
            <button
              className={styles.copyLinkBtton}
              onClick={() => {
                window.location.href = 'https://insightsoftware.com/request-a-price';
              }}
            >
              Get Pricing
            </button>
          </div>
        </div>
        {/* <div className={styles.contentRight}>
                    <img src={ShareImage} className={styles.shareImg}></img>
                </div> */}
      </div>
    </div>
  );
}
