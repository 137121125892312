export const DASHBOARD_EVENT_LISTENER_VISUAL_BUILDER_CHANGE_CODE = `

        // START: Event Listeners
        // When the event is triggered in this example, a message pop-up
        // launches.  Please read the Documentation Helper for a description
        // of other available event listeners.
        component.addEventListener("composer-visual-builder-changed", (e) => {
            alert("Visual Builder Changed !!!");
        });
        /* END: Event Listeners */`;

export const DASHBOARD_EVENT_LISTENER_VISUAL_BUILDER_FAILED_CODE = `

        // START: Event Listeners
        // When the event is triggered in this example, a message pop-up
        // launches.  Please read the Documentation Helper for a description
        // of other available event listeners.
        component.addEventListener("composer-visual-builder-failed", (e) => {
            alert("Visual Builder Failed !!!");
        });
        /* END: Event Listeners */`;

export const DASHBOARD_EVENT_LISTENER_VISUAL_BUILDER_LOAD_CODE = `

        // START: Event Listeners
        // When the event is triggered in this example, a message pop-up
        // launches.  Please read the Documentation Helper for a description
        // of other available event listeners.
        component.addEventListener("composer-visual-builder-loaded", (e) => {
            alert("Visual Authoring Loaded !!!");
        });
        /* END: Event Listeners */`;

export const DASHBOARD_EVENT_LISTENER_VISUAL_BUILDER_READY_CODE = `

        // START: Event Listeners
        // When the event is triggered in this example, a message pop-up
        // launches.  Please read the Documentation Helper for a description
        // of other available event listeners.
        component.addEventListener("composer-visual-builder-ready", (e) => {
            alert("Visual Authoring Ready !!!");
        });
        /* END: Event Listeners */`;
