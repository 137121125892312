import React, { memo } from 'react';
import styles from './LayoutHeader.module.scss';

const LayoutHeaderComponent = () => {
  return (
    <div className={styles.root}>
      <h2 className={styles.subHeading}>Developer Community</h2>
      <h1 className={styles.heading}>Create. Save. Share.</h1>
      <p className={styles.text}>
        Welcome to your Developer Community. Browse, edit, and test public custom code samples live in Composer
        Playground. Current Composer customer? Create, save, and share your code samples across your organization. Make
        changes live that update at save, or fork your code to try a new approach.
      </p>
    </div>
  );
};

export const LayoutHeader = memo(LayoutHeaderComponent);
