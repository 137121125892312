export const EMBED_VIEWER_DASHBOARD_JS_CODE = `promise.then(() => {
  // Do our work here.
    window.dundas.context.embedView(document.getElementById("container"), "%embedDashboardId%", {
        "sessionId": '%sessionId%',
        "viewType": dundas.entities.ViewType.DASHBOARD,
        "embeddedViewServiceName": "DashboardEmbeddedViewService",
    })
    .done(() => {
    %themesCode%
    });
})`;

export const EMBED_VIEWER_SCORECARD_JS_CODE = `promise.then(() => {
  // Do our work here.
    window.dundas.context.embedView(document.getElementById("container"), "%embedScorecardId%", {
        "sessionId": '%sessionId%',
        "viewType": dundas.entities.ViewType.SCORECARD,
        "embeddedViewServiceName": "ScorecardEmbeddedViewService",
    })
    .done(() => {
      %themesCode%
    })
})`;

export const EMBED_VIEWER_REPORT_JS_CODE = `promise.then(() => {
  // Do our work here.
    window.dundas.context.embedView(document.getElementById("container"), "%embedReportId%", {
        "sessionId": '%sessionId%',
        "viewType": dundas.entities.ViewType.REPORT,
        "embeddedViewServiceName": "ReportEmbeddedViewService",
    })
    .done(() => {
      %themesCode%
    })
})`;
