import {
  loginPlaygroundUserAPI,
  getCurrentPlaygroundUserAPI,
  logoutPlaygroundUserAPI,
  signInUser,
  getUserData,
} from 'src/api/user';

const { createAsyncThunk } = require('@reduxjs/toolkit');

export const signIn = createAsyncThunk('user/signIn', async ({ email, password }) => {
  const response = await signInUser({ email, password });
  return response.data;
});

export const getUser = createAsyncThunk('user/getUserData', async () => {
  const response = await getUserData();
  return response.data;
});

export const loginPlaygroundUser = createAsyncThunk('user/loginPlaygroundUser', async (formData) => {
  await loginPlaygroundUserAPI(formData);
});

export const logoutPlaygroundUser = createAsyncThunk('user/logoutPlaygroundUser', async () => {
  await logoutPlaygroundUserAPI();
  if (location.pathname === '/new-account') {
    location.pathname = '/';
  } else {
    let params = new URLSearchParams(location.search);
    params.delete('dashboardId');
    history.replaceState(null, '', location.pathname + '?' + params);
    location.reload();
  }
});

export const getCurrentPlaygroundUser = createAsyncThunk('user/getCurrentPlaygroundUser', async () => {
  return await getCurrentPlaygroundUserAPI();
});
