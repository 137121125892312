import React from 'react';
import BannerContent from 'src/shared/components/BannerContent/BannerContent';
import { ReactComponent as FilterIcon } from 'src/static/icons/selective.svg';

export default function CrossVisualFilteringBanner() {
  return (
    <BannerContent icon={<FilterIcon></FilterIcon>}>
      Bring more flexibility to data exploration and ad hoc analysis with advanced filtering options for embedded
      dashboards.
    </BannerContent>
  );
}
