export const OPTIONS = {
  'visual-builder': {
    basicOptions: [{ key: 'listVisuals', defaultValue: true }],
    advancedOptions: [],
  },
  'dashboard-builder': {
    basicOptions: [{ key: 'listDashboards', defaultValue: true }],
    advancedOptions: [],
  },
};
