import React, { useCallback, useState } from 'react';
import styles from './EmbedOptions.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import {
  setEmbedComponentType,
  setEmbedSetting,
  setEmbedSettings,
  synchronizePreviewSettings,
  setEmbedDashboardId,
  setEmbedOriginId,
  setEmbedSourceId,
} from 'src/shared/store/embedSlice';
import EmbedOption from 'src/shared/components/EmbedOption/EmbedOption';
import { EMBED_OPTIONS } from 'src/shared/constants/embedOptionConstatns';
import Panel from 'src/shared/components/Panel/Panel';
import Tabs from 'src/components/Tabs/Tabs/Tabs';
import Tab from 'src/components/Tabs/Tabs/Tab';
import { getDefaultSettings } from 'src/helpers/embedOptionHelpers';
import {
  getDashboardList,
  getEmbedComponentType,
  getPreviewSettings,
  getThemesList,
  getReportList,
  getScorecardList,
  getMetricSetList,
  getDashboardListComposer,
  getEmbedDashboardId,
  getEmbedSourceId,
  getSourceList,
} from 'src/shared/store/embedSelectors';

const dundasComponentOptions = [
  { label: 'Embed Viewer', value: 'embed-viewer' },
  { label: 'Metric Set', value: 'metric-set' },
  { label: 'Ad-Hoc Editor', value: 'adhoc-editor' },
];

const composerComponentOptions = [
  { label: 'Dashboard', value: 'dashboard' },
  { label: 'Visual Builder', value: 'visual-builder' },
  { label: 'Dashboard Builder', value: 'dashboard-builder' },
  { label: 'Inventory', value: 'inventory' },
  { label: 'Source Editor', value: 'source-editor' },
];
function EmbedOptions({
  basicOptions,
  advancedOptions,
  componentTypes,
  interactivityOptions = null,
  eventListenerOptions = null,
  defaultOptions,
  productSelected,
}) {
  const settings = useSelector((state) => state.embed.settings);
  const [tabIndex, setTabIndex] = useState(0);
  const embedComponentType = useSelector(getEmbedComponentType);
  const currentSettings = useSelector(getPreviewSettings);
  const themes = useSelector(getThemesList);
  const dashboards = useSelector(getDashboardListComposer);
  const dashboardId = useSelector(getEmbedDashboardId);
  const sources = useSelector(getSourceList);
  const sourceId = useSelector(getEmbedSourceId);
  const dashboardList = useSelector(getDashboardList);
  const reportList = useSelector(getReportList);
  const scorecardList = useSelector(getScorecardList);
  const metricSetList = useSelector(getMetricSetList);
  const componentOptions = productSelected === 'Composer' ? composerComponentOptions : dundasComponentOptions;
  const dispatch = useDispatch();
  const handleFieldChange = useCallback((name, value) => dispatch(setEmbedSetting({ name, value })), [setEmbedSetting]);
  const filteredComponentOptions = componentOptions.filter(({ value }) => componentTypes.some((ac) => ac === value));

  const getViewTypeList = (ViewType) => {
    const typeToList = {
      dashboard: dashboardList,
      report: reportList,
      scorecard: scorecardList,
    };

    return typeToList[ViewType] || [];
  };

  const defaultMappings = {
    dashboardId: { list: dashboardList, defaultFriendlyName: 'Summary' },
    reportId: { list: reportList, defaultFriendlyName: 'Monthly Report - R&C style' },
    scorecardId: { list: scorecardList, defaultFriendlyName: 'Wine Sales Scorecard' },
    metricSetId: { list: metricSetList, defaultFriendlyName: 'Manufacturer Product Distribution' },
  };
  return (
    <div className={styles.root}>
      <Panel>
        <ol>
          {filteredComponentOptions.length > 1 && (
            <div className={styles.panelContent}>
              <li>Select Component</li>
              <div className={styles.row}>
                <select
                  value={embedComponentType}
                  onChange={(e) => {
                    dispatch(setEmbedComponentType(e.target.value));
                  }}
                  className={styles.componentTypeSelect}
                >
                  {filteredComponentOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {embedComponentType === 'dashboard' && (
                  <select
                    value={dashboardId}
                    className={styles.dashboardSelect}
                    onChange={(e) => {
                      const selectedOption = e.target.options[e.target.selectedIndex];
                      const selectedOriginId = selectedOption.getAttribute('data-origin-id');

                      dispatch(setEmbedDashboardId(e.target.value));
                      dispatch(setEmbedOriginId(selectedOriginId));
                    }}
                  >
                    {dashboards.map(({ id, name, originId }) => (
                      <option key={id} value={id} data-origin-id={originId}>
                        {name}
                      </option>
                    ))}
                  </select>
                )}
                {embedComponentType === 'source-editor' && (
                  <select
                    value={sourceId}
                    className={styles.dashboardSelect}
                    onChange={(e) => {
                      dispatch(setEmbedSourceId(e.target.value));
                    }}
                  >
                    {sources.map(({ sourceId, sourceName }) => (
                      <option key={sourceId} value={sourceId}>
                        {sourceName}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>
          )}
          <div className={styles.panelContent}>
            {productSelected !== 'Composer' && embedComponentType !== 'metric-set' && <li>General Options</li>}
            {(embedComponentType === 'embed-viewer' || embedComponentType === 'adhoc-editor') && (
              <EmbedOption
                value={settings[EMBED_OPTIONS['Theme'].fieldKey]}
                onChange={handleFieldChange}
                options={themes}
                {...EMBED_OPTIONS['Theme']}
                defaultMappings={defaultMappings}
              ></EmbedOption>
            )}
            {productSelected === 'Dundas' ? <div className={styles.panelContent} /> : ''}
            {productSelected === 'Composer' ? <li>General Options</li> : <li>Options</li>}
            {basicOptions.map(({ key }) => (
              <EmbedOption
                key={key}
                onChange={handleFieldChange}
                value={settings[EMBED_OPTIONS[key].fieldKey]}
                {...EMBED_OPTIONS[key]}
                defaultMappings={defaultMappings}
              ></EmbedOption>
            ))}
            {embedComponentType === 'embed-viewer' && (
              <EmbedOption
                value={settings[EMBED_OPTIONS[currentSettings.ViewType].fieldKey]}
                onChange={handleFieldChange}
                options={getViewTypeList(currentSettings.ViewType)}
                {...EMBED_OPTIONS[currentSettings.ViewType]}
                defaultMappings={defaultMappings}
              ></EmbedOption>
            )}
            {embedComponentType === 'metric-set' && (
              <EmbedOption
                value={settings[EMBED_OPTIONS['MetricSet'].fieldKey]}
                onChange={handleFieldChange}
                options={metricSetList}
                {...EMBED_OPTIONS['MetricSet']}
                defaultMappings={defaultMappings}
              ></EmbedOption>
            )}
            {interactivityOptions && (
              <Tabs selectedIndex={tabIndex} onSelect={setTabIndex}>
                {Object.entries(interactivityOptions).map(([componentType, options]) => (
                  <Tab key={componentType} name={componentType}>
                    <div className={styles.interactivityOptions}>
                      {options.map(({ key }) => (
                        <EmbedOption
                          key={key}
                          onChange={handleFieldChange}
                          value={settings[EMBED_OPTIONS[key].fieldKey]}
                          {...EMBED_OPTIONS[key]}
                        ></EmbedOption>
                      ))}
                    </div>
                  </Tab>
                ))}
              </Tabs>
            )}
            {eventListenerOptions && (
              <Tabs selectedIndex={tabIndex} onSelect={setTabIndex}>
                {Object.entries(eventListenerOptions).map(([componentType, options]) => (
                  <Tab key={componentType} name={componentType}>
                    <div className={styles.eventListenerOptions}>
                      {options.map(({ key }) => (
                        <EmbedOption
                          key={key}
                          onChange={handleFieldChange}
                          value={settings[EMBED_OPTIONS[key].fieldKey]}
                          {...EMBED_OPTIONS[key]}
                        ></EmbedOption>
                      ))}
                    </div>
                  </Tab>
                ))}
              </Tabs>
            )}
          </div>
          {advancedOptions.length > 0 && dashboards.length > 0 && (
            <div className={styles.panelContent}>
              <li>Advanced options</li>
              {advancedOptions.map(({ key }) => (
                <EmbedOption
                  key={key}
                  onChange={handleFieldChange}
                  value={settings[EMBED_OPTIONS[key].fieldKey]}
                  {...EMBED_OPTIONS[key]}
                ></EmbedOption>
              ))}
            </div>
          )}
        </ol>
      </Panel>
      <div className={styles.bottomControls}>
        <button
          className={styles.resetBtn}
          onClick={() =>
            dispatch(setEmbedSettings({ ...getDefaultSettings(settings.ip, embedComponentType), ...defaultOptions }))
          }
        >
          Reset to Defaults
        </button>
        <button className={styles.applyBtn} onClick={() => dispatch(synchronizePreviewSettings())}>
          Apply Settings
        </button>
      </div>
    </div>
  );
}

export default React.memo(EmbedOptions, (prevPros, nextProps) => {
  return (
    isEqual(prevPros.basicOptions, nextProps.basicOptions) &&
    isEqual(prevPros.advancedOptions, nextProps.advancedOptions) &&
    isEqual(prevPros.componentTypes, nextProps.componentTypes) &&
    isEqual(prevPros.interactivityOptions, nextProps.interactivityOptions) &&
    isEqual(prevPros.eventListenerOptions, nextProps.eventListenerOptions)
  );
});
