import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  setEditorHtml,
  setEditorJs,
  setEditorCss,
  setHtml,
  setIsCodeRun,
  setIsCodeTouched,
  setJs,
  setCss,
  setEditorMd,
} from 'src/shared/store/embedSlice';
import EmbedCode from '../EmbedCode/EmbedCode';

export default function ConnectedEmbedCode({ selectedFile, setSelectedFile, showReadme, readmeEditable = false }) {
  const htmlCode = useSelector((state) => state.embed.editorCode.html);
  const jsCode = useSelector((state) => state.embed.editorCode.js);
  const cssCode = useSelector((state) => state.embed.editorCode.css);
  const mdCode = useSelector((state) => state.embed.editorCode.md);
  const dispatch = useDispatch();

  return (
    <EmbedCode
      onRunClick={() => {
        dispatch(setJs(jsCode));
        dispatch(setHtml(htmlCode));
        dispatch(setCss(cssCode));
        dispatch(setIsCodeRun(true));
      }}
      onHtmlCodeChange={(value) => {
        dispatch(setIsCodeTouched(true));
        dispatch(setEditorHtml(value));
      }}
      onCssCodeChange={(value) => {
        dispatch(setIsCodeTouched(true));
        dispatch(setEditorCss(value));
      }}
      onJsCodeChange={(value) => {
        dispatch(setIsCodeTouched(true));
        dispatch(setEditorJs(value));
      }}
      onMdCodeChange={(value) => {
        dispatch(setEditorMd(value));
      }}
      selectedFile={selectedFile}
      setSelectedFile={setSelectedFile}
      htmlCode={htmlCode}
      jsCode={jsCode}
      cssCode={cssCode}
      mdCode={mdCode}
      showReadme={showReadme}
      readmeEditable={readmeEditable}
    ></EmbedCode>
  );
}
