import React, { useCallback, useEffect, useRef, useState } from 'react';
import Help from 'src/pages/Help/Help';
import styles from './Modal.module.scss';

export default function Modal({ name, contentLink, onCloseClick }) {
  const [position, setPosition] = useState([0, 0]);
  const [isDragged, setIsDragged] = useState(false);
  const [mouseDragOffset, setMouseDragOffset] = useState([0, 0]);

  const rootRef = useRef(null);

  useEffect(() => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const rootHeight = rootRef.current.offsetHeight;
    const { width: rootWidth } = rootRef.current.getBoundingClientRect();

    setPosition([(width - rootWidth) / 2, (height - rootHeight) / 3]);
  }, []);

  const handleMouseMove = useCallback(
    (e) => {
      setPosition([e.clientX - mouseDragOffset[0], Math.max(e.clientY - mouseDragOffset[1], 0)]);
    },
    [mouseDragOffset]
  );

  const handleMouseUp = useCallback(() => setIsDragged(false), []);

  useEffect(() => {
    if (isDragged) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragged, handleMouseMove, handleMouseUp]);

  return (
    <div ref={rootRef} className={styles.root} style={{ left: position[0], top: position[1] }}>
      <div className={styles.header}>
        <div
          className={styles.dragArea}
          onMouseDown={(e) => {
            const { x, y } = e.target.getBoundingClientRect();
            const offsetX = e.clientX - x;
            const offsetY = e.clientY - y;
            setMouseDragOffset([offsetX, offsetY]);
            setIsDragged(true);
          }}
        >
          <div className={styles.title}>{name}</div>
        </div>
        <div className={styles.close} onClick={onCloseClick}>
          X
        </div>
      </div>
      <div className={styles.content}>
        <Help link={contentLink}></Help>
      </div>
    </div>
  );
}
