import React, { memo } from 'react';
import { LayoutHeader } from './components/LayoutHeader/LayoutHeader';
import { PublicSamples } from './components/PublicSamples/PublicSamples';
import styles from './SamplesLayout.module.scss';

const SamplesLayoutComponent = ({ children }) => {
  return (
    <div className={styles.root}>
      <div className={styles.headerHolder}>
        <LayoutHeader></LayoutHeader>
      </div>
      <div className={styles.row}>
        <div className={styles.publicSamplesHolder}>
          <PublicSamples></PublicSamples>
        </div>
        <div className={styles.contentHolder}>{children}</div>
      </div>
    </div>
  );
};

export const SamplesLayout = memo(SamplesLayoutComponent);
