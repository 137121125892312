import httpClient from './httpClient';

export const signInUser = ({ email, password }) => httpClient.post('/signin', { email, password });
export const getUserData = () => httpClient.get(process.env.REACT_APP_API_USER_URL);

export const loginPlaygroundUserAPI = (formData) =>
  fetch(`${process.env.REACT_APP_API_LOGIN_URL}`, { method: 'POST', body: formData }).then(async (res) => {
    if (res.status !== 200) {
      throw new Error(await res.json());
    }
  });
export const logoutPlaygroundUserAPI = () => fetch(`${process.env.REACT_APP_API_LOGOUT_URL}`);
export const getCurrentPlaygroundUserAPI = () =>
  fetch(process.env.REACT_APP_API_USER_URL).then(async (res) => {
    if (res.status === 200) {
      return res.json();
    } else {
      throw new Error(await res.json());
    }
  });

export const createAccount = (data) =>
  fetch(`${process.env.REACT_APP_API_ACCOUNTS_URL}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(async (res) => {
    if (res.status !== 200) {
      const data = await res.json();
      throw data;
    }
  });
