export const DASHBOARD_COMPONENT_CONFIG_JS_CODE = `const componentConfig = {
    "originId": "%originId%",
    "dashboardId": "%dashboardId%",
    "interactivityProfileName":"%ip%",%ipOverrides%
    "theme":"%themeComposer%",
    "editor": { "placement": "%editorPlacement%" },
    "header": { 
        "showActions": %showActions%,
        "showTitle": %showTitle%,
        "visible": %showHeader%
    }
}`;

export const DOUBLE_DASHBOARDS_FIRST_DASHBOARD_CONFIG_JS_CODE = `const componentConfig = {
    "originId": "%originId%",
    "dashboardId": "%dashboardId%",
    "interactivityProfileName":"%ip%",%ipOverrides%
    "theme":"%themeComposer%",
    "editor": { "placement": "%editorPlacement%" },
    "header": { 
        "showActions": %showActions%,
        "showTitle": %showTitle%,
        "visible": %showHeader%
    }
}

const componentConfig2 = {
    "originId": "%originId%",
    "dashboardId": "%dashboardId2%",
    "interactivityProfileName":"%ip%",%ipOverrides%
    "theme":"%themeComposer%",
    "editor": { "placement": "%editorPlacement%" },
    "header": {
        "showActions": %showActions%,
        "showTitle": %showTitle%,
        "visible": %showHeader%
    }
}`;

export const CREATE_SECOND_DASHBOARD_JS_CODE = `
    createEmbedComponent(embedManager, componentConfig2, 'widget-holder-2');`;

export const SECOND_DASHBOARD_HTML_CODE = `
    <div
        class="widget-holder"
        id="widget-holder-2"
    ></div>`;
