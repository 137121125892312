export const OPTIONS = {
  'embed-viewer': {
    basicOptions: [{ key: 'ViewType', defaultValue: 'dashboard' }],
    advancedOptions: [],
  },
  'metric-set': {
    basicOptions: [],
    advancedOptions: [],
  },
  'adhoc-editor': {
    basicOptions: [
      { key: 'toolbarHidden', defaultValue: false },
      { key: 'toolbarAddMetricsSetHidden', defaultValue: false },
      { key: 'toolbarAddDashboardHidden', defaultValue: false },
      { key: 'toolbarAddReportHidden', defaultValue: false },
      { key: 'dockablesHidden', defaultValue: false },
      { key: 'openExistingHidden', defaultValue: false },
    ],
    advancedOptions: [],
  },
};
