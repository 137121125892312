import React from 'react';
import EmbedPage from 'src/shared/components/EmbedPage/EmbedPage';
import { OPTIONS } from './constants/crossVisualFilteringOptions';

export default function CrossVisualFiltering({ productSelected, defaultDashboardName }) {
  return (
    <EmbedPage
      options={OPTIONS}
      productSelected={productSelected}
      defaultDashboardName={defaultDashboardName}
    ></EmbedPage>
  );
}
