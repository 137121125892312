import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAccessToken, getRefreshToken } from 'src/helpers/auth';
import { setIsLoading } from 'src/redux/userSlice';
import { getUser } from '../redux/thunks';

export const useAuth = () => {
  const dispatch = useDispatch();
  const { user, isLoading } = useSelector((state) => state.user);

  useEffect(() => {
    if (!user) {
      const accessToken = getAccessToken();
      const refreshToken = getRefreshToken();
      if (!accessToken && !refreshToken) {
        return;
      }
      dispatch(setIsLoading(true));
      dispatch(getUser());
    }
  }, [user]);

  return { user, isUserLoading: isLoading };
};
