import { createContext, useEffect } from 'react';
import {
  setDashboardList,
  setDefaultUserSessionId,
  setMetricSetList,
  setReportList,
  setScorecardList,
  setThemesList,
} from './shared/store/embedSlice';
import { useDispatch } from 'react-redux';
export const DundasContext = createContext({});

export const DundasProvider = ({ children }) => {
  const dispatch = useDispatch();
  const fetchContent = async (type) => { const response = await fetch(`${process.env.REACT_APP_API_DUNDAS_CONTENT}?type=${type}`); return await response.json(); };

  useEffect(() => {
    (async () => {
      const loginResponse = await fetch(process.env.REACT_APP_API_DUNDAS_LOGIN_URL, {
        method: 'GET',
        credentials: 'same-origin',
      });
      const loginData = await loginResponse.json();
      const sessionId = loginData.sessionId;
      dispatch(setDefaultUserSessionId(sessionId));

      const [dashboardData, themeData, reportData, scorecardData, metricSetData] = await Promise.all([fetchContent('Dashboard'), fetchContent('Theme'), fetchContent('Report'), fetchContent('Scorecard'), fetchContent('MetricSet')]);
      themeData.unshift({ 'friendlyName': 'Default', 'id': '' });

      dispatch(setDashboardList(dashboardData));
      dispatch(setThemesList(themeData));
      dispatch(setReportList(reportData));
      dispatch(setScorecardList(scorecardData));
      dispatch(setMetricSetList(metricSetData));
    })();
  }, []);

  return <DundasContext.Provider value={{}}>{children}</DundasContext.Provider>;
};
