import React from 'react';
import BannerContent from 'src/shared/components/BannerContent/BannerContent';
import { ReactComponent as CodingIcon } from 'src/static/icons/coding.svg';

export default function EmbedOptionsBanner() {
  return (
    <BannerContent icon={<CodingIcon></CodingIcon>}>
      With granular control over the analytics experience with embed options, themes and dashboard interactivity. Edit
      and copy the embed code to add embed content to your application.
    </BannerContent>
  );
}
