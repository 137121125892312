import {
  HomeIcon,
  EmbedIcon,
  EventListenersIcon,
  FiltersIcon,
  SamplesIcon,
  UIThemeIcon,
  UXInteractivityIcon,
  APIIcon,
  CodeBlockIcon,
  MDRIcon,
  VDDIcon,
} from 'src/components/RemixIcons/generated/remixIcons';
import InteractivityBanner from 'src/components/Banners/InteractivityBanner/InteractivityBanner';
import EmbedOptionsBanner from 'src/components/Banners/EmbedOptionsBanner/EmbedOptionsBanner';
import ThemesBanner from 'src/components/Banners/ThemesBanner/ThemesBanner';
import CrossVisualFilteringBanner from 'src/components/Banners/CrossVisualFilteringBanner/CrossVisualFilteringBanner';
import EmbedApiBanner from 'src/components/Banners/EmbedApiBanner/EmbedApiBanner';
import EventListenersBanner from 'src/components/Banners/EventListenersBanner/EventListenersBanner';

export var COMMON_NAV_MENU_ITEMS = [
  {
    path: '/',
    name: 'Home',
    pageTitle: 'Welcome',
    headerTitle: 'Symphony Playground',
    category: 'Home',
    icon: <HomeIcon></HomeIcon>,
  },
  {
    path: '/managed/embed-options',
    name: 'Managed Dashboards & Reports',
    pageTitle: 'Embed',
    headerTitle: 'Managed Dashboards',
    category: 'Home',
    icon: <MDRIcon></MDRIcon>,
    bannerComponent: <EmbedOptionsBanner></EmbedOptionsBanner>,
    bannerText: 'You Have Ultimate Control',
  },
  {
    path: '/discovery/embed-options',
    name: 'Visual data discovery',
    pageTitle: 'Embed',
    headerTitle: 'Visual Data Discovery',
    category: 'Home',
    icon: <VDDIcon></VDDIcon>,
    bannerComponent: <EmbedOptionsBanner></EmbedOptionsBanner>,
    bannerText: 'You Have Ultimate Control',
  },
  {
    path: '/my-samples',
    name: 'My Collection',
    category: 'Samples',
    pageTitle: 'My Collection',
    headerTitle: 'Samples',
    icon: <SamplesIcon></SamplesIcon>,
  },
];

export const VDD_NAV_MENU_ITEMS = [
  {
    path: '/',
    name: 'Home',
    pageTitle: 'Home',
    headerTitle: 'Symphony Playground',
    category: 'Home',
    icon: <HomeIcon></HomeIcon>,
  },
  {
    path: '/discovery/embed-options',
    name: 'Embed Options',
    pageTitle: 'Embed',
    headerTitle: 'Visual Data Discovery',
    category: 'Embed',
    icon: <EmbedIcon></EmbedIcon>,
    bannerComponent: <EmbedOptionsBanner></EmbedOptionsBanner>,
    bannerText: 'You Have Ultimate Control',
  },
  {
    path: '/discovery/ux-interactivity',
    params: '?dashboardIpOverrides=true&embedComponentType=dashboard',
    name: 'UX Interactivity',
    pageTitle: 'Interactivity',
    headerTitle: 'Visual Data Discovery',
    category: 'UI/UX customization',
    icon: <UXInteractivityIcon></UXInteractivityIcon>,
    bannerComponent: <InteractivityBanner></InteractivityBanner>,
    bannerText: 'Self-service for Any Users',
  },
  {
    path: '/discovery/ui-themes',
    name: 'UI Theme',
    pageTitle: 'Themes',
    headerTitle: 'Visual Data Discovery',
    category: 'UI/UX customization',
    icon: <UIThemeIcon></UIThemeIcon>,
    bannerComponent: <ThemesBanner></ThemesBanner>,
    bannerText: 'Limitless Customization',
  },
  {
    path: '/discovery/cross-visual-filtering',
    params: '?crossVisualFiltersPublish=true&embedComponentType=dashboard',
    name: 'Cross-Visual Filtering',
    pageTitle: 'Cross-Visual Filtering',
    headerTitle: 'Visual Data Discovery',
    category: 'Advanced features',
    icon: <FiltersIcon></FiltersIcon>,
    bannerComponent: <CrossVisualFilteringBanner></CrossVisualFilteringBanner>,
    bannerText: 'Faster Time to Insights',
  },
  {
    path: '/discovery/embed-api',
    params: '?embedComponentType=visual-builder&listVisuals=true',
    name: 'Embed API',
    pageTitle: 'Embed API',
    headerTitle: 'Visual Data Discovery',
    category: 'Advanced features',
    icon: <APIIcon></APIIcon>,
    bannerComponent: <EmbedApiBanner></EmbedApiBanner>,
    bannerText: 'Power to the Power User',
  },
  {
    path: '/discovery/event-listeners',
    name: 'Event Listeners',
    params: '?embedComponentType=dashboard&eventListeners=true',
    pageTitle: 'Event Listeners',
    headerTitle: 'Visual Data Discovery',
    category: 'Advanced features',
    icon: <EventListenersIcon></EventListenersIcon>,
    bannerComponent: <EventListenersBanner></EventListenersBanner>,
    bannerText: 'A Cohesive Analytics Experience',
  },
];

export const MDR_NAV_MENU_ITEMS = [
  {
    path: '/',
    name: 'Home',
    pageTitle: 'Home',
    headerTitle: 'Symphony Playground',
    category: 'Home',
    icon: <HomeIcon></HomeIcon>,
  },
  {
    path: '/managed/embed-options',
    name: 'Embed Options',
    pageTitle: 'Embed',
    headerTitle: 'Managed Dashboards',
    category: 'Embed',
    icon: <CodeBlockIcon></CodeBlockIcon>,
    bannerComponent: <EmbedOptionsBanner></EmbedOptionsBanner>,
    bannerText: 'You Have Ultimate Control',
  },
];
