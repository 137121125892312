import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setIsLoading, setNewSampleId, setSamplePublicValueLocal, setSamples } from './redux/mySamplesSlice';
import { getMySamples, createNewSample, deleteSample, setSamplePublicValue } from './redux/thunks';
import { useHistory } from 'react-router-dom';
import Button from 'src/components/Button/Button';
import styles from './MySamples.module.scss';
import { useAuth } from 'src/hooks/useAuth';
import { SamplesLayout } from 'src/components/SamplesLayout/SamplesLayout';
import SamplesList from 'src/components/SamplesList/SamplesList';

export default function MySamples() {
  const dispatch = useDispatch();
  const mySamplesData = useSelector((state) => state.samples);
  const { user, isUserLoading } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (user) {
      dispatch(setIsLoading(true));
      dispatch(getMySamples());
    }
  }, [user]);

  useEffect(() => {
    if (mySamplesData.newSampleId) {
      history.push(`/sample/${mySamplesData.newSampleId}`);
      dispatch(setNewSampleId(null));
    }
  }, [mySamplesData.newSampleId]);

  const createSample = useCallback(() => {
    dispatch(createNewSample({}));
  }, []);

  const onDeleteSampleClick = useCallback(
    (id) => {
      dispatch(setSamples(mySamplesData.samples.filter((s) => s.id !== id)));
      dispatch(deleteSample(id));
    },
    [mySamplesData.samples]
  );

  const onPublicChange = useCallback(
    (sampleId, isPublic) => {
      dispatch(setSamplePublicValue({ sampleId, isPublic }));
      dispatch(setSamplePublicValueLocal({ sampleId, isPublic }));
    },
    [mySamplesData.samples]
  );

  return (
    <SamplesLayout>
      <div className={styles.root}>
        {/* <h1 className={styles.heading}>My Collection</h1> */}
        {user ? (
          <>
            {mySamplesData.isLoading ? (
              <div className={styles.spinner}></div>
            ) : (
              <SamplesList
                onPublicChange={onPublicChange}
                enablePublicControls={user.isAdmin}
                samples={mySamplesData.samples}
                onDeleteSampleClick={onDeleteSampleClick}
              ></SamplesList>
            )}
            <Button className={styles.createNewSampleButton} onClick={createSample}>
              Create new sample
            </Button>
          </>
        ) : isUserLoading ? (
          <div className={styles.spinner}></div>
        ) : null}
      </div>
    </SamplesLayout>
  );
}
