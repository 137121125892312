import React from 'react';
import BannerContent from 'src/shared/components/BannerContent/BannerContent';
import { ReactComponent as InteractivityIcon } from 'src/static/icons/interactivity.svg';

export default function InteractivityBanner() {
  return (
    <BannerContent icon={<InteractivityIcon></InteractivityIcon>}>
      Configure a personalized self-service experience to match the skill level of your end-users.
    </BannerContent>
  );
}
