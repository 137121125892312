import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import styles from './HeaderActions.module.scss';
import SocialShare from '../SocialShare/SocialShare';
import ReachOut from '../ReachOut/ReachOut';

export default function HeaderActions() {
  const [isSharePopoverOpen, setIsSharePopoverOpen] = useState(false);
  const [isReachOutPopoverOpen, setIsReachOutPopoverOpen] = useState(false);
  const rootRef = useRef(null);

  const documentClickHandler = (e) => {
    if (rootRef.current.contains(e.target)) {
      return;
    }
    setIsSharePopoverOpen(false);
    setIsReachOutPopoverOpen(false);
  };

  function scrollToSection(sectionId) {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  
      // Delay the focus on the input element
      setTimeout(() => {
        const inputElement = section.querySelector('input[type="email"]');
        if (inputElement) {
          // Set focus to the input element after a small delay
          inputElement.focus();
        }
      }, 500); // Adjust the delay duration as needed (500ms in this example)
    }
  }

  useEffect(() => {
    window.document.addEventListener('click', documentClickHandler);
    return () => {
      window.document.removeEventListener('click', documentClickHandler);
    };
  }, []);

  const openPopover = useCallback(
    (e) => {
      setIsSharePopoverOpen(true);
      setIsReachOutPopoverOpen(false);
      e.preventDefault();
      e.stopPropagation();
    },
    [setIsSharePopoverOpen]
  );

  const closePopover = useCallback(() => {
    setIsSharePopoverOpen(false);
  }, [setIsSharePopoverOpen]);

  const openReachOutPopover = useCallback(
    (e) => {
      setIsSharePopoverOpen(false);
      setIsReachOutPopoverOpen(true);
      e.preventDefault();
      e.stopPropagation();
    },
    [setIsReachOutPopoverOpen]
  );

  const closeReachOutPopover = useCallback(() => {
    setIsReachOutPopoverOpen(false);
  }, [setIsReachOutPopoverOpen]);

  const reachOutToUsClickHandler = (e) => {
    if (location.pathname !== '/') {
      openReachOutPopover(e); // Call the function to open the Reach Out popover
    } else {
      scrollToSection('reachOutToUs');
    }
  };

  return (
    <div className={styles.root} ref={rootRef}>
    <button className={classNames(styles.button, styles.shareButton)} onClick={(e) => openPopover(e)}>
      Share
    </button>
    <button className={classNames(styles.button, styles.scheduleCallButton)} onClick={reachOutToUsClickHandler}>
      Reach out to us
    </button>
    {isSharePopoverOpen && (
      <div className={styles.sharePopoverHolder}>
        <div className={styles.closePopoverButton} onClick={closePopover}>
          x
        </div>
        {/* This SocialShare is for the Share button */}
        <SocialShare></SocialShare>
      </div>
    )}
    {isReachOutPopoverOpen && (
      <div className={styles.sharePopoverHolder}>
        <div className={styles.closePopoverButton} onClick={closeReachOutPopover}>
          x
        </div>
        {/* You might need a separate component or modify this one for Reach Out */}
        <ReachOut></ReachOut>
      </div>
    )}
  </div>
);
}