import { apiUrl } from '../config/api';
import { clearAccessToken, clearRefreshToken, getAccessToken, getRefreshToken, setAccessToken } from '../helpers/auth';
import axios from 'axios';

const httpClient = axios.create({
  baseURL: apiUrl,
  headers: { Accept: 'Application/Json' },
});
httpClient.interceptors.request.use((config) => {
  const accessToken = getAccessToken();
  const newConfig = { ...config, headers: { ...config.headers, Authorization: `Bearer ${accessToken}` } };
  return newConfig;
});

const logout = () => {
  clearAccessToken();
  clearRefreshToken();
};

const refreshAccessToken = async () => {
  let accessToken = null;
  const refreshToken = getRefreshToken();
  if (!refreshToken) {
    logout();

    return;
  }
  try {
    const { data } = await axios.post(`${apiUrl}/refresh-token`, { refreshToken });
    accessToken = data.accessToken;
  } catch {
    logout();

    return;
  }
  if (!accessToken) {
    logout();

    return;
  }
  return accessToken;
};

httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if ((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) {
      originalRequest._retry = true;
      const accessToken = await refreshAccessToken();
      if (accessToken) {
        setAccessToken(accessToken);
      }
      return httpClient(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default httpClient;
