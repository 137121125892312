import { createSlice } from '@reduxjs/toolkit';
import { getCurrentPlaygroundUser, loginPlaygroundUser, logoutPlaygroundUser } from './thunks';

const initialState = {
  user: null,
  isLoading: false,
  isFailed: false,
  isLoggedIn: false,
};

export const slice = createSlice({
  name: 'playgroundUser',
  initialState,
  reducers: {
    setIsLoading(state, { payload }) {
      state.isLoading = payload;
    },
    setIsFailed(state, { payload }) {
      state.isFailed = payload;
    },
    setIsLoggedIn(state, { payload }) {
      state.isLoggedIn = payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(loginPlaygroundUser.fulfilled, (state, {}) => {
        state.isLoading = false;
        state.isLoggedIn = true;
      })
      .addCase(loginPlaygroundUser.rejected, (state) => {
        state.isLoading = false;
        state.isFailed = true;
        location.reload();
      })
      .addCase(getCurrentPlaygroundUser.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.isLoading = false;
        state.isLoggedIn = true;
      })
      .addCase(getCurrentPlaygroundUser.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(logoutPlaygroundUser.fulfilled, (state) => {
        state.isLoggedIn = false;
        state.user = null;
      }),
});

export const { setIsLoading, setIsFailed, setIsLoggedIn } = slice.actions;

export default slice.reducer;
