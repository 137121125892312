import { createSlice } from '@reduxjs/toolkit';
import { createNewSample, getMySamples } from './thunks';

const initialState = {
  samples: [],
  isLoading: false,
  newSampleId: '',
};

export const slice = createSlice({
  name: 'mySamples',
  initialState,
  reducers: {
    setSamples(state, { payload }) {
      state.samples = payload;
    },
    setIsLoading(state, { payload }) {
      state.isLoading = payload;
    },
    setNewSampleId(state, { payload }) {
      state.newSampleId = payload;
    },
    setSamplePublicValueLocal(state, { payload }) {
      const sample = state.samples.find(({ id }) => id === payload.sampleId);
      if (!sample) {
        return;
      }
      sample.isPublic = payload.isPublic;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getMySamples.fulfilled, (state, { payload }) => {
        state.samples = payload;
        state.isLoading = false;
      })
      .addCase(createNewSample.fulfilled, (state, { payload }) => {
        state.newSampleId = payload.id;
      }),
});

export const { setSamples, setIsLoading, setNewSampleId, setSamplePublicValueLocal } = slice.actions;

export default slice.reducer;
