import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { TABS } from '../constants/liveCodeTabs';

const useQueryParamEmbedSettings = ({
  settings,
  selectedTabIndex,
  selectedFile,
  embedComponentType,
  dashboardId,
  setSelectedTabIndex,
  setSelectedFile,
  refreshSettingsAndCodeValues,
  defaultSettings,
  embedComponentTypeOptions,
}) => {
  const history = useHistory();
  useEffect(() => {
    const path = history.location.pathname;
    const options = Object.entries(settings).reduce(
      (res, [k, v]) => (defaultSettings[k] !== v ? { ...res, [k]: v } : res),
      {}
    );
    const defaultDashboardId = options.dashboardId;
    if (selectedTabIndex > 0) {
      options.modeTabIndex = selectedTabIndex;
    }
    if (selectedFile.name !== TABS.html.name) {
      options.selectedFileName = selectedFile.name;
    }
    options.embedComponentType = embedComponentType;
    if (embedComponentType === 'dashboard') {
      options.dashboardId = dashboardId;
    } else {
      options.dashboardId = defaultDashboardId;
    }
    const newSearch = queryString.stringify(options);
    const isQueryEmpty = newSearch.length === 0;
    window.history.replaceState(null, '', isQueryEmpty ? path : `${path}?${newSearch}`);
  }, [selectedTabIndex, settings, selectedFile, embedComponentType, dashboardId]);

  useEffect(() => {
    const settings = queryString.parse(history.location.search);
    const parsedSettings = Object.entries(settings).reduce(
      (res, [k, v]) => ({ ...res, [k]: v === 'false' || v === 'true' ? v === 'true' : v }),
      {}
    );

    if (
      settings.modeTabIndex &&
      !Number.isNaN(settings.modeTabIndex) &&
      +settings.modeTabIndex <= 1 &&
      +settings.modeTabIndex >= 0
    ) {
      setSelectedTabIndex(+settings.modeTabIndex);
    }
    if (settings.selectedFileName) {
      const fileFromParams = Object.values(TABS).find(({ name }) => name === settings.selectedFileName);
      if (fileFromParams) {
        setSelectedFile(fileFromParams);
      }
    }
    const componentType = embedComponentTypeOptions.some((o) => o === settings.embedComponentType)
      ? settings.embedComponentType
      : embedComponentTypeOptions[0];
    refreshSettingsAndCodeValues(parsedSettings, componentType, settings.dashboardId);
  }, []);
};

export default useQueryParamEmbedSettings;
