import { createSlice } from '@reduxjs/toolkit';
import { getPublicSamples } from './thunks';

const initialState = {
  publicSamples: [],
  isLoading: false,
  isFailed: false,
};

export const slice = createSlice({
  name: 'publicSamples',
  initialState,
  reducers: {
    setIsLoading(state, { payload }) {
      state.isLoading = payload;
    },
    setIsFailed(state, { payload }) {
      state.isFailed = payload;
    },
  },
  extraReducers: (builder) =>
    builder.addCase(getPublicSamples.fulfilled, (state, { payload }) => {
      state.publicSamples = payload.data;
      state.isLoading = false;
    }),
});

export const { setIsLoading, setIsFailed } = slice.actions;

export default slice.reducer;
