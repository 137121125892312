import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { persistor, store } from './store';
import { Provider } from 'react-redux';
import { DundasProvider } from './dundasContext';
import { PersistGate } from 'redux-persist/integration/react';

(async () => {
  await window.setupDundasEmbedded(`${process.env.REACT_APP_DUNDAS_INSTANCE}`);
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <base href="/" />
        <PersistGate loading={null} persistor={persistor}>
          <DundasProvider>
            <App />
          </DundasProvider>
        </PersistGate>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
})();
