import React from 'react';
import { Link } from 'react-router-dom';

import styles from './Button.module.scss';
import classNames from 'classnames';

export default function Button({ onClick = () => {}, children, className = null, to = null }) {
  return to ? (
    <Link to={to} className={classNames(styles.root, className)}>
      {children}
    </Link>
  ) : (
    <button onClick={onClick} className={classNames(styles.root, className)}>
      {children}
    </button>
  );
}
