export const INVENTORY_CONFIG = `const componentConfig = {
    "inventoryItem": "%inventoryItem%",
    "theme":"%themeComposer%",%componentsConfig%%ipOverrides%
}`;

export const COMPONENTS_CONFIG = `
    "components": {%customizeActionButtons%%customizeSearchControllers%%customizeFilterButtons%%customizeInventoryTable%
}`;

export const CUSTOMIZE_ACTION_BUTTONS = `
        "actions": {
            "visible": true,
            "showDefaults": true,

            "order": [
                    "import-dashboard",
                    "add-new-dashboard",
                    "custom"
                ],
                "visibility": [
                    "import-dashboard",
                    "add-new-dashboard",
                    "custom"
                ],
            
            "definitions": [
                {
                    "id": "custom",
                    "label": "Custom Button",
                    "intent": "primary",
                    "onClick": () => alert('Custom button clicked!')
                    /*
                    // link example
                    "onClick": {
                        "type": "link",
                        "href": "https://dashboards.company.com/create",
                        "target": "_blank"
                    }
                    */
                }
            ]
        },`;

export const CUSTOMIZE_SEARCH_CONTROLLERS = `
        "search": {
            "visible": true,
            "defaultValue": "",
            "placeholder": "My Search"
        },`;

export const CUSTOMIZE_FILTER_BUTTONS = `
        "filters":{
            "visible": true,
            "showDefaults": true,

            "order": [
                    "all",
                    "favorites",
                    "myitems",
                    "shared",
                    "custom"
                ],
                "visibility": [
                    "all",
                    "favorites",
                    "myitems",
                    "shared",
                    "custom"
                ],
            
            "definitions": [
                {
                    "id": "custom",
                    "icon": {
                        "src": "https://cdn-icons-png.flaticon.com/512/742/742751.png"
                    },
                    "label": "test",
                    "htmlTitle": "htmlTitle", // tooltip //
                    "predicate": (item, idx, array) => {
                    return item.favorite === true;
                    } // function query
                }
            ]
        },`;

export const CUSTOMIZE_INVENTORY_TABLE = `
        "table": {
            "defaultSort": {
                "colId": "name", // ["favorite", "name", "dataSource", "author", "dateModified", "permissions", "schedule", "actions"]
                "sortDirection": "asc"
            },
            
            "columns": {
                "showDefaults": true,

                "order": [
                        "favorite",
                        "name",
                        "newColumn",
                        "dataSource",
                        "author",
                        "dateModified",
                        "permissions",
                        "schedule",
                        "actions"
                    ],
                    "visibility": [
                        "favorite",
                        "name",
                        "newColumn",
                        "dataSource",
                        "author",
                        "dateModified",
                        "permissions",
                        "schedule",
                        "actions"
                    ],
           
                
                "definitions": [
                    {
                        "colId": "newColumn", //case on ID was wrong on docs
                        "field": "Custom",
                        "headerName": "Custom",
                        "minWidth" : 100,
                        "maxWidth" : 100,  
                        "sortable": true,
                        "comparator": "", // function
                        "cellRendererParams": { 
                            "onClick": () => alert(\`You clicked on \${inventoryItemId} in a custom column!\`),
                            "color": "limegreen",
                            // "href": "www.dashboards/\${inventoryItemId}",
                            // "target": "_blank"
                        },
                        "cellRenderer": ({ color }) => \`<p style="color: \${color}" onclick="alertCell()">LINK</p>\`,
                    },
                    {
                        "colId": "name",
                        "cellRendererParams": {
                            "onClick": {
                                "type": "embed",
                                "parentElement": "#dashboard-holder",
                                "componentParams": {
                                    // optional component params
                                }
                            }
                        }
                    }
                ]
            }
        },`;

export const CUSTOMIZE_ACTION_BUTTONS_HELPERS = `
const alertCell = () => alert('You clicked on a custom column!');

const closeEmbed = () => {
    document.getElementById('dashboard-holder').innerHTML = '';
}
`;

export const CUSTOMIZE_ACTION_BUTTONS_HTML_CODE = `
    <div
        class="dashboard-holder"
        id="dashboard-holder"
    ></div>
    <button class="close-btn" onclick="closeEmbed()">close dashboard</button>
`;

export const CUSTOMIZE_ACTION_BUTTONS_CSS_CODE = `
.dashboard-holder:not(:empty) {
    position: relative;
    flex-grow: 1;
}
.close-btn {
    display: none;
}
.dashboard-holder:not(:empty)~.close-btn {
    display: block;
}
`;
