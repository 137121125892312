export const TABS = {
  md: {
    name: 'about',
  },
  html: {
    name: 'index.html',
  },
  js: {
    name: 'embed.js',
  },
  css: {
    name: 'styles.css',
  },
};
