import { useHistory } from 'react-router';
import { Tooltip, Button, svgIconClasses, buttonClasses, alpha } from '@mui/material';
import { styled } from '@mui/material/styles';

const SidebarButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ active, theme }) => ({
  overflow: 'hidden',
  whiteSpace: 'normal',
  boxSizing: 'border-box',
  color: '#FFFFFF',
  height: '45px',
  fontSize: '2px',
  width: '100%',
  minWidth: '0',
  borderRadius: '0',
  padding: '0',
  justifyContent: 'flex-start',
  border: '1px solid transparent',
  fontFamily:
    "font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;",

  '&&:hover': {
    color: '#FFFFFF',
    backgroundColor: alpha('#001737', 0.2),
  },
  '&&:active': {
    backgroundColor: alpha('#001737', 0.4),
  },
  [`&.${buttonClasses.focusVisible}`]: {
    outline: 'none',
    boxShadow: 'none',
    backgroundColor: alpha('#001737', 0.4),
    border: `1px solid ${theme.palette.common.white}`,
  },
  [`& .${buttonClasses.startIcon}`]: {
    marginLeft: '0',
    width: '58px',
    height: '58px',
    display: 'flex',
    flexShrink: '0',
    alignItems: 'center',
    justifyContent: 'center',
    [`& .${svgIconClasses.root}`]: {
      fontSize: '24px',
    },
  },
  backgroundColor: active ? alpha('#001737', 0.4) : undefined,
}));

export const RouteButton = ({ title, expanded, isActive, onClick, path, params = '', ...rest }) => {
  const history = useHistory();
  return (
    <Tooltip
      placement="right"
      title={title}
      arrow
      disableFocusListener={expanded}
      disableHoverListener={expanded}
      disableTouchListener={expanded}
    >
      <SidebarButton
        onClick={() => {
          if (history.location.pathname === path) {
            return;
          }
          onClick(path + params);
        }}
        active={isActive}
        {...rest}
      />
    </Tooltip>
  );
};
