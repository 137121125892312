import React, { useState } from 'react';
import { ReactComponent as DeleteIcon } from 'src/static/icons/delete.svg';
import PropTypes from 'prop-types';
import styles from './SamplesList.module.scss';
import Checkbox from '../Checkbox/Checkbox';
import { Link } from 'react-router-dom';
import Tabs from 'src/components/Tabs/Tabs/Tabs';
import Tab from 'src/components/Tabs/Tabs/Tab';

export default function SamplesList({ mdrSamples, vddSamples, onDeleteSampleClick, enablePublicControls, onPublicChange }) {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  return (
    <>
      {mdrSamples.length > 0 && vddSamples.length > 0 ? (
        <Tabs selectedIndex={selectedTabIndex} onSelect={setSelectedTabIndex}>
          <Tab name="Manage Dashboards and Reports">
            <div className={styles.list}>
              {renderSamples(mdrSamples, onDeleteSampleClick, enablePublicControls, onPublicChange)}
            </div>
          </Tab>
          <Tab name="Visual Data Discovery">
            <div className={styles.list}>
              {renderSamples(vddSamples, onDeleteSampleClick, enablePublicControls, onPublicChange)}
            </div>
          </Tab>
        </Tabs>
      ) : (
        <>
          {(mdrSamples.length > 0 || vddSamples.length > 0) &&
            <h3>{mdrSamples.length > 0 ? 'Manage Dashboards and Reports Samples' : 'Visual Data Discovery Samples'}</h3>
          }
          <div className={styles.list}>
            {renderSamples(vddSamples, onDeleteSampleClick, enablePublicControls, onPublicChange)}
          </div>
        </>
      )
      }
    </>
  );
}

function renderSamples(samples, onDeleteSampleClick, enablePublicControls, onPublicChange) {
  return samples.map(({ id, title, isPublic }) => (
    <div className={styles.listItem} key={id}>
      <Link className={styles.link} to={`/sample/${id}`}>
        {title}
      </Link>
      {enablePublicControls && (
        <Checkbox onChange={(e) => onPublicChange(id, e.target.checked)} checked={isPublic}></Checkbox>
      )}
      {onDeleteSampleClick && (
        <button onClick={() => onDeleteSampleClick(id)} className={styles.deleteButton}>
          <DeleteIcon className={styles.deleteIcon}></DeleteIcon>
        </button>
      )}
    </div>
  ));
}

SamplesList.propTypes = {
  mdrSamples: PropTypes.array,
  vddSamples: PropTypes.array,
  onDeleteSampleClick: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
  enablePublicControls: PropTypes.oneOfType([PropTypes.bool, PropTypes.any]),
  onPublicChange: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
};

