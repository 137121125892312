export const DASHBOARD_EVENT_LISTENER_VISUAL_ADDED_CODE = `

        // START: Event Listeners
        // When the event is triggered in this example, a message pop-up
        // launches.  Please read the Documentation Helper for a description
        // of other available event listeners.
        component.addEventListener("composer-dashboard-widget-added", (e) => {
            alert("Visual added to dashboard !!!");
        });
        /* END: Event Listeners */`;

export const DASHBOARD_EVENT_LISTENER_VISUAL_REMOVED_CODE = `

        // START: Event Listeners
        // When the event is triggered in this example, a message pop-up
        // launches.  Please read the Documentation Helper for a description
        // of other available event listeners.
        component.addEventListener("composer-dashboard-widget-removed", (e) => {
            alert("Visual removed from dashboard !!!");
        });
        /* END: Event Listeners */`;

export const DASHBOARD_EVENT_LISTENER_VISUAL_FAIL_CODE = `

        // START: Event Listeners
        // When the event is triggered in this example, a message pop-up
        // launches.  Please read the Documentation Helper for a description
        // of other available event listeners.
        component.addEventListener("composer-visual-failed", (e) => {
            alert("Visual load failed !!!");
        });
        /* END: Event Listeners */`;

export const DASHBOARD_EVENT_LISTENER_VISUAL_LOAD_CODE = `

        // START: Event Listeners
        // When the event is triggered in this example, a message pop-up
        // launches.  Please read the Documentation Helper for a description
        // of other available event listeners.
        component.addEventListener("composer-visual-loaded", (e) => {
            alert("Visual is loaded !!!");
        });
        /* END: Event Listeners */`;

export const DASHBOARD_EVENT_LISTENER_VISUAL_RENDERED_CODE = `

        // START: Event Listeners
        // When the event is triggered in this example, a message pop-up
        // launches.  Please read the Documentation Helper for a description
        // of other available event listeners.
        component.addEventListener("composer-visual-rendered", (e) => {
            alert("Visual rendered !!!");
        });
        /* END: Event Listeners */`;

export const DASHBOARD_EVENT_LISTENER_VISUAL_SAVE_CODE = `

        // START: Event Listeners
        // When the event is triggered in this example, a message pop-up
        // launches.  Please read the Documentation Helper for a description
        // of other available event listeners.
        component.addEventListener("composer-visual-saved", (e) => {
            alert("Visual authoring saved !!!");
        });
        /* END: Event Listeners */`;

export const VISUAL_EVENT_LISTENERS_CODE = `

        // START: Event Listeners
        // When the event is triggered in this example, a message pop-up
        // launches.  Please read the Documentation Helper for a description
        // of other available event listeners.
        component.addEventListener("composer-visual-builder-loaded", (e) => {
            alert("Visual has been loaded !!!");
        });
        /* END: Event Listeners */`;
