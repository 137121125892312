export const OPTIONS = {
  dashboard: {
    basicOptions: [
      { key: 'themeComposer', defaultValue: '__platform__' },
      { key: 'ip', defaultValue: 'interactive' },
      { key: 'editorPlacement', defaultValue: 'modals' },
      { key: 'showHeader', defaultValue: false },
      { key: 'showActions', defaultValue: false },
      { key: 'dashboardShowTitle', defaultValue: false },
    ],
    advancedOptions: [{ key: 'doubleDashboards', defaultValue: false }],
  },
  'visual-builder': {
    basicOptions: [
      { key: 'themeComposer', defaultValue: '__platform__' },
      { key: 'visualShowHeader', defaultValue: true },
      { key: 'visualShowActions', defaultValue: true },
      { key: 'visualShowTitle', defaultValue: false },
      { key: 'breadcrumbs', defaultValue: false },
    ],
    advancedOptions: [
      // { key: 'embedSource', defaultValue: false }
    ],
  },
  'dashboard-builder': {
    basicOptions: [
      { key: 'themeComposer', defaultValue: '__platform__' },
      { key: 'editorPlacement', defaultValue: 'modals' },
      { key: 'dashboardShowTitle', defaultValue: false },
      { key: 'dashboardLite', defaultValue: false },
    ],
    advancedOptions: [],
  },
  inventory: {
    basicOptions: [
      { key: 'themeComposer', defaultValue: '__platform__' },
      { key: 'inventoryItem', defaultValue: 'dashboard' },
    ],
    advancedOptions: [
      { key: 'customizeActionButtons', defaultValue: false },
      { key: 'customizeSearchControllers', defaultValue: false },
      { key: 'customizeFilterButtons', defaultValue: false },
      { key: 'customizeInventoryTable', defaultValue: false },
    ],
  },
  'source-editor': {
    basicOptions: [
      { key: 'themeComposer', defaultValue: '__platform__' },
      { key: 'sourceEditorShowHeader', defaultValue: true },
      { key: 'sourceEditorShowTitle', defaultValue: false },
      { key: 'sourceEditorActiveTab', defaultValue: 'create' },
    ],
    advancedOptions: [
      { key: 'showCreateTab', defaultValue: true },
      { key: 'showFieldsTab', defaultValue: true },
      { key: 'showCacheTab', defaultValue: true },
      { key: 'showSettingsTab', defaultValue: true },
    ],
  },
};
