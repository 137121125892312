import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SamplesList from 'src/components/SamplesList/SamplesList';
import { getPublicSamples } from 'src/samplesRedux/thunks';
import styles from './PublicSamples.module.scss';

const PublicSamplesComponent = () => {
  const samples = useSelector((state) => state.publicSamples.publicSamples);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPublicSamples());
  }, []);
  return (
    <div className={styles.root}>
      <h1 className={styles.heading}>Public Code Samples</h1>
      <p className={styles.text}>
        These tested, vetted samples work with the latest version of Composer. These advanced samples are rooted in
        customer-driven solutions; see how they fit your needs.
      </p>
      <SamplesList vddSamples={samples} mdrSamples=""></SamplesList>
    </div>
  );
};

export const PublicSamples = memo(PublicSamplesComponent);
