import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';
import Layout from './components/Layout/Layout';
import MainEmbedOptions from './pages/MainEmbedOptions/MainEmbedOptions';
import UxInteractivity from './pages/UxInteractivity/UxInteractivity';
import UiThemes from './pages/UiThemes/UiThemes';
import CrossVisualFiltering from './pages/CrossVisualFiltering/CrossVisualFiltering';
import EmbedApi from './pages/EmbedApi/EmbedApi';
import EventListeners from './pages/EventListeners/EventListeners';
import DundasMainEmbedOptions from './pages/DundasMainEmbedOptions/DundasMainEmbedOptions';
import NewAccount from './pages/NewAccount/NewAccount';
import Landing from './pages/Landing/Landing';
import MySamples from './pages/MySamples/MySamples';
import Sample from './pages/Sample/Sample';
import { NAME_OF_DEFAULT_FILTER_DASHBOARD } from './shared/constants/embedComponentTypes';
export default function App() {
  const historyInstance = createBrowserHistory();

  return (
    <Router history={historyInstance}>
      <Layout>
        <Switch>
          <Route exact path="/">
            <Landing></Landing>
          </Route>
          <Route path="/managed/embed-options">
            <DundasMainEmbedOptions productSelected="Dundas"></DundasMainEmbedOptions>
          </Route>
          <Route path="/discovery/embed-options">
            <MainEmbedOptions productSelected="Composer"></MainEmbedOptions>
          </Route>
          <Route path="/discovery/ux-interactivity">
            <UxInteractivity productSelected="Composer"></UxInteractivity>
          </Route>
          <Route path="/discovery/ui-themes">
            <UiThemes productSelected="Composer"></UiThemes>
          </Route>
          <Route path="/discovery/cross-visual-filtering">
            <CrossVisualFiltering
              productSelected="Composer"
              defaultDashboardName={NAME_OF_DEFAULT_FILTER_DASHBOARD}
            ></CrossVisualFiltering>
          </Route>
          <Route path="/discovery/embed-api">
            <EmbedApi productSelected="Composer"></EmbedApi>
          </Route>
          <Route path="/discovery/event-listeners">
            <EventListeners productSelected="Composer"></EventListeners>
          </Route>
          <Route path="/my-samples">
            <MySamples />
          </Route>
          <Route path="/sample/:id">
            <Sample />
          </Route>
          <Route path="/new-account">
            <NewAccount />
          </Route>
        </Switch>
      </Layout>
    </Router>
  );
}
