import { createSelector } from 'reselect';
import {
  COMMON_EMBED_HTML_CODE,
  COMMON_EMBED_JS_CODE,
  COMMON_EMBED_CSS_CODE,
} from 'src/shared/constants/liveCodeScripts/commonCode';
import { applySettingsToCssCode, applySettingsToHtmlCode, applySettingsToJsCode } from '../utils/codeBuilderHelpers';
export const getPreviewSettings = (state) => state.embed.previewSettings;
const getIsCodeTouched = (state) => state.embed.isCodeTouched;
export const getEmbedComponentType = (state) => state.embed.embedComponentType;
const jsCode = (state) => state.embed.code.js;
const cssCode = (state) => state.embed.code.css;
const htmlCode = (state) => state.embed.code.html;
const isCodeRun = (state) => state.embed.isCodeRun;
export const isEmbedReady = (state) => state.embed.isReady;
export const getDashboardList = (state) => state.embed.dashboardList;
export const getReportList = (state) => state.embed.reportList;
export const getScorecardList = (state) => state.embed.scorecardList;
export const getThemesList = (state) => state.embed.themesList;
export const getMetricSetList = (state) => state.embed.metricSetList;
export const getEmbedDashboardId = (state) => state.embed.dashboardId;
export const getEmbedOriginId = (state) => state.embed.originId;
export const getDefaultUserSessionId = (state) => state.embed.defaultUserSessionId;
export const getPlaygroundUser = (state) => state.playgroundUser.user;
export const getDashboardListComposer = (state) => state.embed.dashboardListComposer;
export const getFirstDashboardId = (state) =>
  state.embed.dashboardListComposer && state.embed.dashboardListComposer.length
    ? state.embed.dashboardListComposer[1].id
    : undefined;
export const getEmbedSourceId = (state) => state.embed.sourceId;
export const getSourceList = (state) => state.embed.sourceList;
export const getSidebarExpanded = (state) => state.embed.sidebarExpanded;
export const getJsCode = createSelector(
  [
    getPreviewSettings,
    getEmbedComponentType,
    getIsCodeTouched,
    isCodeRun,
    jsCode,
    getEmbedDashboardId,
    getEmbedOriginId,
    getFirstDashboardId,
    getEmbedSourceId,
    getDefaultUserSessionId,
  ],
  (
    previewSettings,
    embedComponentType,
    isCodeTouched,
    isCodeRun,
    jsCode,
    embedDashboardId,
    embedOriginId,
    firstDashboardId,
    sourceId,
    defaultUserSessionId
  ) => {
    return isCodeTouched && isCodeRun
      ? jsCode
      : applySettingsToJsCode(
          COMMON_EMBED_JS_CODE,
          previewSettings,
          embedComponentType,
          embedDashboardId,
          embedOriginId,
          firstDashboardId,
          sourceId,
          defaultUserSessionId
        );
  }
);

export const getHtmlCode = createSelector(
  [getPreviewSettings, getIsCodeTouched, isCodeRun, htmlCode, getEmbedComponentType],
  (previewSettings, isCodeTouched, isCodeRun, htmlCode, embedComponentType) => {
    return isCodeTouched && isCodeRun
      ? htmlCode
      : applySettingsToHtmlCode(COMMON_EMBED_HTML_CODE, previewSettings, embedComponentType);
  }
);

export const getCssCode = createSelector(
  [getPreviewSettings, getIsCodeTouched, isCodeRun, cssCode, getEmbedComponentType],
  (previewSettings, isCodeTouched, isCodeRun, cssCode, embedComponentType) => {
    return isCodeTouched && isCodeRun
      ? cssCode
      : applySettingsToCssCode(COMMON_EMBED_CSS_CODE, previewSettings, embedComponentType);
  }
);
